import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import NewAddress from "../common/NewAddress";
import FormControl from "@material-ui/core/FormControl";
import { appContext } from "../../App";

import AddPhotos from "../common/AddPhotos";
import AddDocument from "../common/AddDocument";
import validateOrganization from "./validateOrganization";
import noValidate from "./noValidate";
import useFormValidation from "../../hooks/useFormValidation";
import {
  addOrganization,
  createOrgBankAccount,
  updateOrganizationStatus,
  updateOrgBankAccountInformation,
} from "../../utils/api";
import toastr from "toastr";
import "./Organization.scss";
import DescriptionIcon from "@material-ui/icons/Description";
import CloseIcon from "@material-ui/icons/Close";
import { TextareaAutosize } from "@material-ui/core";
import OrgBankDetails from "../paymentGateway/OrgBankDetails";
import { checkUrlWithHttp, SUPPORTED_COUNTRIES } from "../../utils/utils";
import styles from "./Organization.module.scss";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "65vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "60%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  orgTitle: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  orgType: {
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",

    "&:hover": {
      background: "var(--tal_primary)",
    },
  },

  dob: {
    maxWidth: "50%",
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,
    border: "1px solid #ccc",
    "& div": {
      paddingLeft: 15,
      "&:before": {
        borderBottom: "none",
      },
    },
    "& label": {
      paddingLeft: 10,
      position: "absolute",
      top: "-9",
      background: "#fff",
      left: 4,
    },

    "& button": {
      outline: "none",
    },
    "& input": {
      border: "none",
    },
  },
}));

const Organization = (props) => {
  const classes = useStyles();
  const { authUser, orgList, setOrgList, region } = useContext(appContext);
  const initialState = {
    name: "",
    type: "",
    location: "",
    website: "",
    role: "",
    isCSRWing: "",
    isProfileVerified: "",
    date: "",
    email: "",
  };
  const [id, setId] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [step, setStep] = useState(1);
  const [address, setAddress] = useState(null);
  const [tempAddress, setTempAddress] = useState(null);
  const [showingAddPhotoModal, setShowingAddPhotoModal] = useState(false);
  const [showingAddDocumentModal, setShowingAddDocumentModal] = useState(false);
  const [defaultImageUrl, setDefaultImageUrl] = useState("");
  const [addressError, setAddressError] = useState("");
  const [tempBankValues, setTempBankValues] = useState("");
  const [bankValues, setBankValues] = useState("");
  const [bankError, setBankError] = useState(null);
  const [error, setError] = useState(null);
  const [accountId, setAccountId] = useState("");

  const updateDefaultImageUrl = (newImageUrl) => {
    setDefaultImageUrl(newImageUrl);
    setOrgList(
      orgList.map((org) => {
        if (org && org.orgId && org.orgId._id === id) {
          org.orgId.defaultImageUrl = newImageUrl;
        }
        return org;
      })
    );
  };

  useEffect(() => {
    if (defaultImageUrl) {
      updateDefaultImageUrl(defaultImageUrl);
    }
  }, [defaultImageUrl]);

  const handleChangeAddress = (newAddress) => {
    setTempAddress(newAddress);
  };

  const handleChangeBankValues = (newValues) => {
    setTempBankValues(newValues);
  };

  const { orgDetails } = props;

  const handleCloseModal = () => {
    props.onCloseModal();
    if (props.editOrg || (!props.editOrg && !orgDetails)) {
      setValues(initialState);
      setErrors("");
      setAddressError("");
      setBankValues("");
      setBankError("");
      setAccountId("");
      setAddress("");
      setStep(1);
      setEditMode(false);
      setId("");
      setDefaultImageUrl("");
    }
  };

  const wholeValidateOrganization = () => {
    const errors = validateOrganization(values);
    if (
      !tempAddress ||
      !tempAddress.line1 ||
      !tempAddress.line2 ||
      !tempAddress.country ||
      !tempAddress.state ||
      !tempAddress.city ||
      !tempAddress.zip_code
    ) {
      setAddressError("Please provide the full address of the Organization.");
    }
    return errors;
  };

  useEffect(() => {
    if (orgDetails && orgDetails.orgAddress) {
      setAddress({
        line1: orgDetails.orgAddress.hasOwnProperty("line1")
          ? orgDetails.orgAddress.line1
          : "",
        line2: orgDetails.orgAddress.hasOwnProperty("line2")
          ? orgDetails.orgAddress.line2
          : "",
        city: orgDetails.orgAddress.hasOwnProperty("city")
          ? orgDetails.orgAddress.city
          : "",
        state: orgDetails.orgAddress.hasOwnProperty("state")
          ? orgDetails.orgAddress.state
          : "",
        zip_code: orgDetails.orgAddress.hasOwnProperty("zip_code")
          ? orgDetails.orgAddress.zip_code
          : "",
        country: orgDetails.orgAddress.hasOwnProperty("country")
          ? orgDetails.orgAddress.country
          : "",
      });
    }
    if (orgDetails) {
      setId(orgDetails._id);
      setDefaultImageUrl(orgDetails.defaultImageUrl);
      setEditMode(true);
    }
    if (orgDetails && orgDetails._id) {
      setValues((currentValues) => {
        return {
          ...currentValues,
          name: orgDetails.orgName ? orgDetails.orgName : "",
          type: orgDetails.orgType ? orgDetails.orgType : "",
          description: orgDetails.description ? orgDetails.description : "",
          email: orgDetails.orgEmail ? orgDetails.orgEmail : "",
          website: orgDetails.websiteUrl
            ? checkUrlWithHttp(orgDetails.websiteUrl)
            : "",
          isCSRWing: orgDetails.hasCsrWing ? orgDetails.hasCsrWing : false,
          isProfileVerified:
            orgDetails.isProfileVerified == true
              ? true
              : orgDetails.isProfileVerified == false
              ? false
              : "",
          isTopOrg: orgDetails.isTopOrg ? orgDetails.isTopOrg : false,
        };
      });
    }
    if (
      orgDetails &&
      orgDetails.orgAccountsInfo &&
      orgDetails.orgAccountsInfo.length > 0
    ) {
      setBankValues({
        accountNumber: orgDetails.orgAccountsInfo[0].accountNumber,
        routingNumber: orgDetails.orgAccountsInfo[0].routingNumber,
        country: orgDetails.orgAccountsInfo[0].country,
        accountHolderName: orgDetails.orgAccountsInfo[0].accountHolderName,
        accountHolderType: orgDetails.orgAccountsInfo[0].accountHolderType,
        isDefault: orgDetails.orgAccountsInfo[0].isDefault,
        label: orgDetails.orgAccountsInfo[0].label,
        currency:
          orgDetails.orgAccountsInfo[0].country === "IN" ? "inr" : "usd",
      });
      setAccountId(orgDetails.orgAccountsInfo[0].uniqueId);
    } else {
      setBankValues("");
      setAccountId("");
    }
  }, [orgDetails, orgDetails && orgDetails.defaultImageUrl]);

  const handleAddOrg = () => {
    const finalValues = {};
    finalValues.userId = authUser.unique_id;
    finalValues.orgName = values.name;
    finalValues.orgType = values.type;
    finalValues.description = values.description;
    finalValues.orgEmail = values.email;
    finalValues.websiteUrl = checkUrlWithHttp(values.website);
    finalValues.defaultImageUrl = defaultImageUrl;
    finalValues.hasCsrWing = values.isCSRWing;
    props.fromAdmin &&
      (finalValues.isProfileVerified = values.isProfileVerified);
    finalValues.isTopOrg = values.isTopOrg;
    if (
      tempAddress &&
      (tempAddress.line1 || tempAddress.line2) &&
      tempAddress.city &&
      tempAddress.state &&
      tempAddress.country &&
      tempAddress.zip_code
    ) {
      setAddress(tempAddress);
      finalValues.orgAddress = {
        line1: tempAddress.line1,
        line2: tempAddress.line2,
        city: tempAddress.city,
        state: tempAddress.state,
        country: tempAddress.country,
        zip_code: tempAddress.zip_code,
      };
      setAddressError("");
    } else {
      setAddressError("Please provide the full address of the Organization.");
      return;
    }
    if (editMode && orgDetails && orgDetails._id && tempBankValues) {
      let newData = {};
      if (
        tempBankValues &&
        tempBankValues.routingNumber &&
        tempBankValues.accountNumber &&
        tempBankValues.country &&
        tempBankValues.accountHolderName &&
        tempBankValues.accountHolderType &&
        tempBankValues.label
      ) {
        newData = {
          country: tempBankValues.country,
          routingNumber: tempBankValues.routingNumber,
          accountNumber: tempBankValues.accountNumber,
          accountHolderName: tempBankValues.accountHolderName,
          accountHolderType: tempBankValues.accountHolderType,
          label: tempBankValues.label,
          currency: tempBankValues.country === "IN" ? "inr" : "usd",
        };
        setBankError("");
      } else {
        setBankError("Please enter all bank account details");
        return;
      }
      if (accountId && newData) {
        updateOrgBankAccountInformation(accountId, id, newData)
          .then((response) => {
            if (response.data.statusCode === 200) {
              setStep(1);
              toastr.success(response.data.message);
            }
          })
          .catch((error) => {
            setError(error.message);
          });
      } else {
        createOrgBankAccount(newData, id)
          .then((response) => {
            if (response.data.statusCode === 200) {
              setError("");
              setStep(1);
              setAccountId(response.data.data.uniqueId);
              toastr.success(response.data.message);
            } else {
              toastr.error(response.data.message);
              setError(response.data.message);
            }
          })
          .catch((error) => {
            setError(error.data.message);
          });
      }
    }
    if (editMode && orgDetails && orgDetails._id) {
      updateOrganizationStatus(orgDetails._id, finalValues).then((resp) => {
        if (resp.status === 200) {
          const orgData = resp.data.data.data;
          toastr.success(resp.data.message);
          props.onSavingOrg(orgDetails._id);
          setOrgList(
            orgList.map((org) => {
              if (org && org.orgId && org.orgId._id === orgDetails._id) {
                org.orgId.orgName = orgData.orgName;
                org.orgId.websiteUrl = orgData.websiteUrl;
                org.orgId.orgType = orgData.orgType;
              }
              return org;
            })
          );
          props.onCloseModal();
          setStep(1);
          setValues(initialState);
          setDefaultImageUrl("");
          setAddress("");
          setBankValues("");
          setId("");
          setAccountId("");
        }
      });
    } else if (step === 1 && !editMode) {
      if (id) {
        updateOrganizationStatus(id, finalValues).then((resp) => {
          if (resp.status === 200) {
            setStep(2);
          }
        });
      } else {
        addOrganization(finalValues)
          .then((resp) => {
            if (resp.statusCode === 200) {
              const org = resp.data;
              setStep(2);
              setId(resp.data.orgId);
              setOrgList((currentOrgs) => {
                return [
                  ...currentOrgs,
                  { ...org, orgId: { _id: resp.data.orgId, ...finalValues } },
                ];
              });
            }
          })
          .catch((error) => {
            if (error.response.status === 409) {
              toastr.error(
                "That organization name is already taken. Please use a different name"
              );
            } else {
              toastr.error(error.response.data.message);
            }
          });
      }
    } else if (step === 2 && !editMode) {
      let bankAccountData = {};
      if (
        tempBankValues &&
        tempBankValues.routingNumber &&
        tempBankValues.accountNumber &&
        tempBankValues.country &&
        tempBankValues.accountHolderName &&
        tempBankValues.accountHolderType &&
        tempBankValues.label
      ) {
        setBankValues(tempBankValues);
        bankAccountData = {
          country: tempBankValues.country,
          routingNumber: tempBankValues.routingNumber,
          accountNumber: tempBankValues.accountNumber,
          accountHolderName: tempBankValues.accountHolderName,
          accountHolderType: tempBankValues.accountHolderType,
          label: tempBankValues.label,
          currency: tempBankValues.country === "IN" ? "inr" : "usd",
          isDefault: true,
        };
        setBankError("");
      } else {
        setBankError("Please enter all bank account details");
        return;
      }
      if (!accountId && bankAccountData) {
        createOrgBankAccount(bankAccountData, id)
          .then((response) => {
            if (response.data.statusCode === 200) {
              setError("");
              setStep(3);
              setAccountId(response.data.data.uniqueId);
              toastr.success(response.data.message);
            } else {
              toastr.error(response.data.message);
              setError(response.data.message);
            }
          })
          .catch((error) => {
            setError(error.data.message);
          });
      } else if (accountId && bankAccountData) {
        updateOrgBankAccountInformation(accountId, id, bankAccountData)
          .then((response) => {
            if (response.data.statusCode === 200) {
              setStep(3);
              toastr.success(response.data.message);
            }
          })
          .catch((error) => {
            setError(error.message);
          });
      } else {
        console.log("error");
      }
    } else if (step === 3 && !editMode) {
      const valuesToSave = {};
      valuesToSave.defaultImageUrl = defaultImageUrl;
      updateOrganizationStatus(id, valuesToSave).then((resp) => {
        if (resp.status === 200) {
          setOrgList(
            orgList.map((org) => {
              if (org && org.orgId && org.orgId._id === id) {
                org.orgId.defaultImageUrl = defaultImageUrl;
              }
              return org;
            })
          );
          // setOrgList((currentOrgs) => {
          //   return [...currentOrgs, { orgId: resp.data.data }];
          // });
          toastr.success("Organization is created successfully");
          props.onSavingOrg(id);
          props.onCloseModal();
          setStep(1);
          setValues(initialState);
          setDefaultImageUrl("");
          setAddress("");
          setBankValues("");
          setId("");
          setAccountId("");
        }
      });
    }
  };
  const handleSkip = () => {
    setBankError("");
    setStep(step + 1);
  };

  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, wholeValidateOrganization, handleAddOrg);

  const onSavingDocument = (message) => {
    toastr.success(message);
  };

  const onCloseAddDocument = () => {
    setShowingAddDocumentModal(false);
  };

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openOrgModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openOrgModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.orgTitle}>Organization Information</h1>

              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                  width: "30px",
                  height: "30px",
                  display: "grid",
                  placeContent: "center",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={handleCloseModal} />
              </span>
            </div>
            <div className="plasma">
              {(editMode || (step === 1 && !editMode)) && (
                <form noValidate autoComplete="off">
                  <div style={{ position: "relative" }}>
                    <TextField
                      type="text"
                      required
                      //  className={`input-field request-title`}
                      className={
                        styles.plasma_input +
                        " " +
                        `input-field request-amount ${
                          errors && errors.name
                            ? styles.bg_red
                            : values.name
                            ? styles.bg_green
                            : styles.bg_normal
                        }`
                      }
                      variant="outlined"
                      placeholder="Organization name *"
                      name="name"
                      value={values.name}
                      onChange={changeHandler}
                      inputProps={{
                        maxLength: 50,
                      }}
                    />

                    <span
                      className="title-length"
                      style={{ top: 10, padding: "0 4px" }}
                    >
                      {" "}
                      {values.name ? values.name.length : 0}/50
                    </span>
                  </div>
                  {errors && errors.name && (
                    <div className={styles.custom_error}>{errors.name}</div>
                  )}

                  <FormControl
                    error={errors && errors.type}
                    variant="outlined"
                    className={styles.blood_grp}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Type *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.type}
                      onChange={changeHandler}
                      variant="outlined"
                      // className={`input-field request-amount `}
                      className={`input-field request-amount ${
                        errors && errors.type
                          ? styles.bg_red
                          : values.type
                          ? styles.bg_green
                          : styles.bg_normal
                      }`}
                      name="type"
                      label="Type"
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={"Organization"}>Organization</MenuItem>
                      <MenuItem value={"Corporate"}>Corporate</MenuItem>
                      <MenuItem value={"Educational Institution"}>
                        Educational Institution
                      </MenuItem>
                      <MenuItem value={"Non Profit Organization"}>
                        Non Profit Organization
                      </MenuItem>
                    </Select>
                  </FormControl>
                  {errors && errors.type && (
                    <div className={styles.custom_error}>{errors.type}</div>
                  )}
                  <div style={{ position: "relative" }}>
                    <TextareaAutosize
                      name="description"
                      variant="outlined"
                      multiline
                      rows="4"
                      style={{
                        padding: "25px 10px 10px 10px",
                        border: "1px solid #ccc",
                      }}
                      className={
                        styles.plasma_input +
                        " " +
                        `input-field request-title ${
                          errors && errors.description
                            ? styles.bg_red
                            : values.description
                            ? styles.bg_green
                            : styles.bg_normal
                        }`
                      }
                      placeholder="Enter Description of your organization *"
                      value={values.description}
                      onChange={changeHandler}
                      maxLength={1000}
                    />
                    <span
                      className="title-length"
                      style={{ top: 10, padding: "0 4px" }}
                    >
                      {" "}
                      {values.description ? values.description.length : 0}
                      /1000
                    </span>
                  </div>
                  {errors && errors.description && (
                    <div className={styles.custom_error}>
                      {errors.description}
                    </div>
                  )}
                  <NewAddress
                    value={address}
                    onChange={handleChangeAddress}
                    error={addressError}
                    supportedCountries={SUPPORTED_COUNTRIES}
                  />
                  {addressError && (
                    <div className={styles.custom_error}>{addressError}</div>
                  )}
                  <TextField
                    type="text"
                    className={`input-field request-title`}
                    variant="outlined"
                    label="Organization website (Optional)"
                    name="website"
                    value={values.website}
                    onChange={changeHandler}
                  />
                  <FormControl
                    error={errors && errors.isCSRWing}
                    variant="outlined"
                    className={styles.blood_grp}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Does your company has CSR wing? *
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.isCSRWing}
                      onChange={changeHandler}
                      variant="outlined"
                      // className="input-field request-amount"
                      className={`input-field request-amount ${
                        errors && errors.isCSRWing
                          ? styles.bg_red
                          : values.isCSRWing
                          ? styles.bg_green
                          : styles.bg_normal
                      }`}
                      name="isCSRWing"
                      label="Does your company has CSR wing?"
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  <FormControl
                    error={errors && errors.isCSRWing}
                    variant="outlined"
                    className={styles.blood_grp}
                  >
                    <InputLabel id="demo-simple-select-outlined-label">
                      Top Organization?
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      value={values.isTopOrg}
                      onChange={changeHandler}
                      variant="outlined"
                      // className="input-field request-amount"
                      className={`input-field request-amount ${
                        errors && errors.isTopOrg
                          ? styles.bg_red
                          : values.isTopOrg
                          ? styles.bg_green
                          : styles.bg_normal
                      }`}
                      name="isTopOrg"
                      label="Top Organization?"
                      style={{ width: "100%" }}
                    >
                      <MenuItem value={true}>Yes</MenuItem>
                      <MenuItem value={false}>No</MenuItem>
                    </Select>
                  </FormControl>
                  {errors && errors.isCSRWing && (
                    <div className={styles.custom_error}>
                      {errors.isCSRWing}
                    </div>
                  )}
                  <TextField
                    type="text"
                    className={`input-field request-title ${
                      errors && errors.email
                        ? "bg-red"
                        : values.email
                        ? "bg-green"
                        : "bg-normal"
                    }`}
                    variant="outlined"
                    label="Official Email Address (Optional)"
                    name="email"
                    value={values.email}
                    onChange={changeHandler}
                  />
                  {errors && errors.email && (
                    <div className={styles.custom_error}>{errors.email}</div>
                  )}
                </form>
              )}
              {(editMode || (step === 2 && !editMode)) && (
                <div>
                  <div>
                    <p style={{ fontWeight: "600" }}>
                      Add bank account details{" "}
                    </p>
                    <OrgBankDetails
                      value={bankValues}
                      onChange={handleChangeBankValues}
                    />
                    {bankError && (
                      <div
                        className={styles.custom_error}
                        style={{ textAlign: "center", marginTop: "8px" }}
                      >
                        {bankError}
                      </div>
                    )}
                    {error && (
                      <div className={styles.custom_error}>{error}</div>
                    )}
                  </div>
                </div>
              )}
              {(editMode || (step === 3 && !editMode)) && (
                <div>
                  <p style={{ fontWeight: "600", marginTop: "10px" }}>
                    Organization Logo
                  </p>
                  <img
                    src={
                      defaultImageUrl
                        ? defaultImageUrl
                        : "/images/default-req-img.gif"
                    }
                    alt="default"
                    onClick={() =>
                      props.setShowProfilePhoto
                        ? props.setShowProfilePhoto(true)
                        : setShowingAddPhotoModal(true)
                    }
                    style={{
                      width: "250px",
                      cursor: "pointer",
                      background: "white",
                      padding: "5px",
                      boxShadow: "0 0 3px #a5a5a5",
                      display: "block",
                    }}
                  />

                  <Button
                    className="document-btn"
                    onClick={() => setShowingAddDocumentModal(true)}
                    style={{
                      font: "500 12px Roboto",
                      padding: "20px 30px",
                      margin: "10px 0",
                      background: "transparent",
                      color: "#283E4A",
                      border: "1px solid #283E4A",
                    }}
                  >
                    <DescriptionIcon style={{ fontSize: 14, marginRight: 6 }} />{" "}
                    Upload supporting document(s)
                  </Button>
                </div>
              )}
              {props.fromAdmin && (
                <FormControl
                  error={errors && errors.isProfileVerified}
                  variant="outlined"
                  className={styles.blood_grp}
                >
                  <InputLabel id="demo-simple-select-outlined-label">
                    Is Organization Verified? *
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={values.isProfileVerified}
                    onChange={changeHandler}
                    variant="outlined"
                    // className="input-field request-amount"
                    className={`input-field request-amount ${
                      errors && errors.isProfileVerified
                        ? styles.bg_red
                        : values.isProfileVerified
                        ? styles.bg_green
                        : styles.bg_normal
                    }`}
                    name="isProfileVerified"
                    label="Is Organization Verified?"
                    style={{ width: "100%" }}
                  >
                    <MenuItem value={true}>Yes</MenuItem>
                    <MenuItem value={false}>No</MenuItem>
                  </Select>
                  {errors && errors.isProfileVerified && (
                    <div className={styles.custom_error}>
                      {errors.isProfileVerified}
                    </div>
                  )}
                </FormControl>
              )}
              <div className={step === 3 ? " " : "org_skip"}>
                <span className={step === 3 ? " " : "org_skip"}>
                  {(step === 2 || step === 3) && !editMode && (
                    <Button
                      variant="contained"
                      style={{ marginRight: "20px", marginTop: "20px" }}
                      onClick={() => setStep(step - 1)}
                      className="boxshadow_unset"
                    >
                      Back
                    </Button>
                  )}
                  {step === 2 && !editMode && (
                    <Button
                      className={classes.saveBtn}
                      style={{ marginTop: "20px" }}
                      onClick={handleSkip}
                    >
                      skip
                    </Button>
                  )}
                </span>
                <Button
                  className={classes.saveBtn}
                  style={{ marginTop: "20px" }}
                  onClick={submitHandler}
                >
                  {(step === 1 || step === 2) && !editMode
                    ? "Save and Continue ..."
                    : "Done"}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <AddPhotos
        title="Add Images"
        isOpen={showingAddPhotoModal}
        onClose={() => setShowingAddPhotoModal(false)}
        uid={id}
        collection="Org"
        folder="logos"
        profilephotoURL={defaultImageUrl}
        onProfilephotoURLChange={updateDefaultImageUrl}
        canEdit={true}
      />
      <AddDocument
        title="Add Supporting Documents"
        isOpen={showingAddDocumentModal}
        onClose={onCloseAddDocument}
        uid={id}
        collection="Org"
        onSavingDocument={onSavingDocument}
        canEdit={true}
      />
    </div>
  );
};

export default withRouter(Organization);
