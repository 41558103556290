import React from "react";
import { Box, Container, Grid, Button} from "@material-ui/core"
import csstyle from "./home.module.scss";
import "./home2.scss";
import { ArrowForward } from "@material-ui/icons";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slick-slider.scss";

class  HomeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.sliderRef = React.createRef();
    this.state = {
      currentSlide: 0,
    };
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeyPress);
  }

  handleKeyPress = (e) => {
    const { currentSlide } = this.state;

    if (e.key === 'ArrowUp') {
      this.sliderRef.current.slickGoTo(currentSlide - 1);
    } else if (e.key === 'ArrowDown') {
      this.sliderRef.current.slickGoTo(currentSlide + 1);
    }
  };

  afterSlideChange = (currentSlide) => {
    this.setState({ currentSlide });
    const { slidesToShow, slidesToScroll, lazyLoad } = this.props;
    const slides = this.sliderRef.current.innerSlider.state.slideCount;

    if (lazyLoad) {
      // Calculate the index of the next slide to be displayed
      const nextSlide = currentSlide + slidesToShow + slidesToScroll;

      // Load the next set of slides if they're not already loaded
      for (let i = nextSlide; i < nextSlide + slidesToScroll; i++) {
        if (i < slides) {
          this.sliderRef.current.slickLoad(i);
        }
      }
    }
  };

  render() {
    const { slidesToShow, slidesToScroll, lazyLoad, children } = this.props;

    const settings = {
     
      lazyLoad,
      afterChange: this.afterSlideChange,
      // Other Slick Slider settings here

    arrows: false,
    dots: true,
    fade: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };


  return (
    <>
     {/*slider*/}
     
     <Grid className={csstyle._slider_box}>
    <Container className={csstyle.topfull_width}>
    
    <Slider {...settings} className="home_main_slider" ref={this.sliderRef}>
    {children}
            
        </Slider>
    
    </Container>
    </Grid>
    
    {/*slider*/}
    </>
  );
}
}
export default HomeSlider;