import React, {useState}  from "react";
import { withRouter } from "react-router-dom";
import csstyle from "./NewDonationRequestCard.module.scss";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Button, Grid } from "@mui/material";
import LinearProgress from '@material-ui/core/LinearProgress';
import { ArrowForward, Info, Schedule, Share } from "@material-ui/icons";
import UseAnimations from 'react-useanimations';
import thumbUp from 'react-useanimations/lib/thumbUp';
import heart from 'react-useanimations/lib/heart';
const BorderLinearProgress = withStyles((theme) => ({
    root: {
      height: 10,
      borderRadius: 5,
    },
    colorPrimary: {
      backgroundColor: theme.palette.grey[theme.palette.type === 'light' ? 200 : 700],
    },
    bar: {
      borderRadius: 5,
      backgroundColor: '#20A12D',
    },
  }))(LinearProgress);
  const style = {
    height: 300,
  };
  
const NewDonationRequestCard = (props) => {
    const [bstrokeColor, setStrokeColor] = useState('#000'); // Initial stroke color is black
  const handleClick = (likeColor) => {
    // Change the stroke color to a new value when the icon is clicked
    const newColor = likeColor; // Generate a random color
    setStrokeColor(newColor);
  };
 return (
  <>
    <Grid container>
        <Grid md={4} sm={6} xs={12}>
            <Box className={csstyle._dona_box}>
                <Box className={csstyle._dona_img}>
                    <img src="./images/card-1.jpg" alt=""/>
                    <div className={csstyle.ribbon_2} >TAX Benefit <Info/> </div>
                    <Box className={csstyle._dona_heart}>
                    <UseAnimations animation={heart} fillColor="#FF273A" onClick={() => bstrokeColor == "#FF273A" ?handleClick("#000") : handleClick("#FF273A")}
        strokeColor={bstrokeColor}/>
                    </Box>
                </Box>
                <Box className={csstyle._dona_txt}>
                <Box  className={csstyle._dona_title}>
                    <h4>Help me raise funds for imporve education 2021</h4>
                    <p>By Anil Kumar Murapaka <img src="./images/green-tick.svg"/></p>
                </Box>
                <Box className={csstyle._dona_cur}>
                    <p><span>₹ 2,505</span> raised of <span>₹ 500,000</span></p>
                    <p>30%</p>
                </Box>
                <Box>
                <BorderLinearProgress variant="determinate" value={50} />
                </Box>
                <Box className={csstyle._dona_icons}>
                    <Box>
                    <UseAnimations animation={thumbUp} /> <span>5 </span>
                    </Box>
                    <Box>
                    
<svg width="18" height="18" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00207 0C3.58332 0 0.00207401 2.90937 0.00207401 6.5C0.00207401 7.9875 0.623949 9.35 1.6552 10.4469C1.18957 11.6781 0.220824 12.7219 0.205199 12.7344C-0.00105099 12.9531 -0.057301 13.2719 0.061449 13.5469C0.180199 13.8219 0.452074 14 0.752074 14C2.67395 14 4.18957 13.1969 5.09895 12.5531C6.00207 12.8375 6.97707 13 8.00207 13C12.4208 13 16.0021 10.0906 16.0021 6.5C16.0021 2.90937 12.4208 0 8.00207 0ZM8.00207 11.5C7.1677 11.5 6.3427 11.3719 5.55207 11.1219L4.8427 10.8969L4.23332 11.3281C3.78645 11.6438 3.17395 11.9969 2.43645 12.2344C2.66457 11.8562 2.88645 11.4312 3.05832 10.9781L3.38957 10.1L2.74582 9.41875C2.1802 8.81563 1.50207 7.81875 1.50207 6.5C1.50207 3.74375 4.4177 1.5 8.00207 1.5C11.5864 1.5 14.5021 3.74375 14.5021 6.5C14.5021 9.25625 11.5864 11.5 8.00207 11.5Z" fill="#4C4C4C"/>
</svg> &nbsp;
 <span> 5 </span>
                    </Box>
                    <Box>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13 14" fill="none">
  <path d="M9.625 8.75C9.00681 8.75 8.43863 8.9638 7.99011 9.32134L5.18776 7.56987C5.27075 7.19448 5.27075 6.80549 5.18776 6.4301L7.99011 4.67862C8.43863 5.0362 9.00681 5.25 9.625 5.25C11.0747 5.25 12.25 4.07474 12.25 2.625C12.25 1.17526 11.0747 0 9.625 0C8.17526 0 7 1.17526 7 2.625C7 2.82073 7.0216 3.01137 7.06223 3.19487L4.25988 4.94635C3.81136 4.5888 3.24319 4.375 2.625 4.375C1.17526 4.375 0 5.55026 0 7C0 8.44974 1.17526 9.625 2.625 9.625C3.24319 9.625 3.81136 9.4112 4.25988 9.05365L7.06223 10.8051C7.02081 10.9923 6.99994 11.1833 7 11.375C7 12.8247 8.17526 14 9.625 14C11.0747 14 12.25 12.8247 12.25 11.375C12.25 9.92526 11.0747 8.75 9.625 8.75Z" fill="#4C4C4C"/>
</svg> &nbsp; 
<span>5 </span>
                    </Box>
                    <Box> <Schedule/> &nbsp;<span> 23 days left</span></Box>
                </Box>
                <Box className={csstyle._dona_btn}>
                    <Button>
                        Donate Now  {" "}<ArrowForward/>
                    </Button>
                </Box>
                </Box>
            </Box>
        </Grid>
        <Grid md={4} sm={6} xs={12}>
            <Box className={csstyle._dona_box}>
                <Box className={csstyle._dona_img}>
                    <img src="./images/card-1.jpg" alt=""/>
                    <div className={csstyle.ribbon_2} >TAX Benefit <Info/> </div>
                    <Box className={csstyle._dona_heart}>
                    <UseAnimations animation={heart} fillColor="#FF273A" onClick={() => bstrokeColor == "#FF273A" ?handleClick("#000") : handleClick("#FF273A")}
        strokeColor={bstrokeColor}/>
                    </Box>
                </Box>
                <Box className={csstyle._dona_txt}>
                <Box  className={csstyle._dona_title}>
                    <h4>Help me raise funds for imporve education 2021</h4>
                    <p>By Anil Kumar Murapaka <img src="./images/green-tick.svg"/></p>
                </Box>
                <Box className={csstyle._dona_cur}>
                    <p><span>₹ 2,505</span> raised of <span>₹ 500,000</span></p>
                    <p>30%</p>
                </Box>
                <Box>
                <BorderLinearProgress variant="determinate" value={50} />
                </Box>
                <Box className={csstyle._dona_icons}>
                    <Box>
                    <UseAnimations animation={thumbUp} /> <span>5 </span>
                    </Box>
                    <Box>
                    
<svg width="18" height="18" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00207 0C3.58332 0 0.00207401 2.90937 0.00207401 6.5C0.00207401 7.9875 0.623949 9.35 1.6552 10.4469C1.18957 11.6781 0.220824 12.7219 0.205199 12.7344C-0.00105099 12.9531 -0.057301 13.2719 0.061449 13.5469C0.180199 13.8219 0.452074 14 0.752074 14C2.67395 14 4.18957 13.1969 5.09895 12.5531C6.00207 12.8375 6.97707 13 8.00207 13C12.4208 13 16.0021 10.0906 16.0021 6.5C16.0021 2.90937 12.4208 0 8.00207 0ZM8.00207 11.5C7.1677 11.5 6.3427 11.3719 5.55207 11.1219L4.8427 10.8969L4.23332 11.3281C3.78645 11.6438 3.17395 11.9969 2.43645 12.2344C2.66457 11.8562 2.88645 11.4312 3.05832 10.9781L3.38957 10.1L2.74582 9.41875C2.1802 8.81563 1.50207 7.81875 1.50207 6.5C1.50207 3.74375 4.4177 1.5 8.00207 1.5C11.5864 1.5 14.5021 3.74375 14.5021 6.5C14.5021 9.25625 11.5864 11.5 8.00207 11.5Z" fill="#4C4C4C"/>
</svg> &nbsp;
 <span> 5 </span>
                    </Box>
                    <Box>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13 14" fill="none">
  <path d="M9.625 8.75C9.00681 8.75 8.43863 8.9638 7.99011 9.32134L5.18776 7.56987C5.27075 7.19448 5.27075 6.80549 5.18776 6.4301L7.99011 4.67862C8.43863 5.0362 9.00681 5.25 9.625 5.25C11.0747 5.25 12.25 4.07474 12.25 2.625C12.25 1.17526 11.0747 0 9.625 0C8.17526 0 7 1.17526 7 2.625C7 2.82073 7.0216 3.01137 7.06223 3.19487L4.25988 4.94635C3.81136 4.5888 3.24319 4.375 2.625 4.375C1.17526 4.375 0 5.55026 0 7C0 8.44974 1.17526 9.625 2.625 9.625C3.24319 9.625 3.81136 9.4112 4.25988 9.05365L7.06223 10.8051C7.02081 10.9923 6.99994 11.1833 7 11.375C7 12.8247 8.17526 14 9.625 14C11.0747 14 12.25 12.8247 12.25 11.375C12.25 9.92526 11.0747 8.75 9.625 8.75Z" fill="#4C4C4C"/>
</svg> &nbsp; 
<span>5 </span>
                    </Box>
                    <Box> <Schedule/> &nbsp;<span> 23 days left</span></Box>
                </Box>
                <Box className={csstyle._dona_btn}>
                    <Button>
                        Donate Now  {" "}<ArrowForward/>
                    </Button>
                </Box>
                </Box>
            </Box>
        </Grid>
        <Grid md={4} sm={6} xs={12}>
            <Box className={csstyle._dona_box}>
                <Box className={csstyle._dona_img}>
                    <img src="./images/card-1.jpg" alt=""/>
                    <div className={csstyle.ribbon_2} >TAX Benefit <Info/> </div>
                    <Box className={csstyle._dona_heart}>
                    <UseAnimations animation={heart} fillColor="#FF273A" onClick={() => bstrokeColor == "#FF273A" ?handleClick("#000") : handleClick("#FF273A")}
        strokeColor={bstrokeColor}/>
                    </Box>
                </Box>
                <Box className={csstyle._dona_txt}>
                <Box  className={csstyle._dona_title}>
                    <h4>Help me raise funds for imporve education 2021</h4>
                    <p>By Anil Kumar Murapaka <img src="./images/green-tick.svg"/></p>
                </Box>
                <Box className={csstyle._dona_cur}>
                    <p><span>₹ 2,505</span> raised of <span>₹ 500,000</span></p>
                    <p>30%</p>
                </Box>
                <Box>
                <BorderLinearProgress variant="determinate" value={50} />
                </Box>
                <Box className={csstyle._dona_icons}>
                    <Box>
                    <UseAnimations animation={thumbUp} /> <span>5 </span>
                    </Box>
                    <Box>
                    
<svg width="18" height="18" viewBox="0 0 17 14" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00207 0C3.58332 0 0.00207401 2.90937 0.00207401 6.5C0.00207401 7.9875 0.623949 9.35 1.6552 10.4469C1.18957 11.6781 0.220824 12.7219 0.205199 12.7344C-0.00105099 12.9531 -0.057301 13.2719 0.061449 13.5469C0.180199 13.8219 0.452074 14 0.752074 14C2.67395 14 4.18957 13.1969 5.09895 12.5531C6.00207 12.8375 6.97707 13 8.00207 13C12.4208 13 16.0021 10.0906 16.0021 6.5C16.0021 2.90937 12.4208 0 8.00207 0ZM8.00207 11.5C7.1677 11.5 6.3427 11.3719 5.55207 11.1219L4.8427 10.8969L4.23332 11.3281C3.78645 11.6438 3.17395 11.9969 2.43645 12.2344C2.66457 11.8562 2.88645 11.4312 3.05832 10.9781L3.38957 10.1L2.74582 9.41875C2.1802 8.81563 1.50207 7.81875 1.50207 6.5C1.50207 3.74375 4.4177 1.5 8.00207 1.5C11.5864 1.5 14.5021 3.74375 14.5021 6.5C14.5021 9.25625 11.5864 11.5 8.00207 11.5Z" fill="#4C4C4C"/>
</svg> &nbsp;
 <span> 5 </span>
                    </Box>
                    <Box>
                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 13 14" fill="none">
  <path d="M9.625 8.75C9.00681 8.75 8.43863 8.9638 7.99011 9.32134L5.18776 7.56987C5.27075 7.19448 5.27075 6.80549 5.18776 6.4301L7.99011 4.67862C8.43863 5.0362 9.00681 5.25 9.625 5.25C11.0747 5.25 12.25 4.07474 12.25 2.625C12.25 1.17526 11.0747 0 9.625 0C8.17526 0 7 1.17526 7 2.625C7 2.82073 7.0216 3.01137 7.06223 3.19487L4.25988 4.94635C3.81136 4.5888 3.24319 4.375 2.625 4.375C1.17526 4.375 0 5.55026 0 7C0 8.44974 1.17526 9.625 2.625 9.625C3.24319 9.625 3.81136 9.4112 4.25988 9.05365L7.06223 10.8051C7.02081 10.9923 6.99994 11.1833 7 11.375C7 12.8247 8.17526 14 9.625 14C11.0747 14 12.25 12.8247 12.25 11.375C12.25 9.92526 11.0747 8.75 9.625 8.75Z" fill="#4C4C4C"/>
</svg> &nbsp; 
<span>5 </span>
                    </Box>
                    <Box> <Schedule/> &nbsp;<span> 23 days left</span></Box>
                </Box>
                <Box className={csstyle._dona_btn}>
                    <Button>
                        Donate Now  {" "}<ArrowForward/>
                    </Button>
                </Box>
                </Box>
            </Box>
        </Grid>
    </Grid>
  </>
 );
};
export default withRouter(NewDonationRequestCard);