import validator from "validator";
const validateContact = (values) => {
  let errors = {};

  if (!values.firstName) {
    errors.firstName = "Please enter first name";
  }
  if (values.email) {
    if (values.email && !validator.isEmail(values.email)) {
      errors.email = "Please enter valid email";
    }
  } else {
    errors.email = "Please enter email";
  }

  return errors;
};
export default validateContact;
