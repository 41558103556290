import React, { useContext, BrowserRouter as Router, Switch,
    Route, } from "react";
  import { withRouter } from "react-router-dom";
  import Grid from "@material-ui/core/Grid";
  import Container from "@material-ui/core/Container";
  import csstyle from "./Footer.module.scss";
  import "./Footer.scss";
  import { Box, Button, Divider, Link, MenuItem, MenuList, TextField } from "@material-ui/core";
  import { ArrowForward } from "@material-ui/icons";
  
  const NewFooter = (props) => {
    const handleFaqPage = () => {
      props.history.push("/users/help-desk");
    };
    return (
      <div className={csstyle.footer_container}>      
        <Container>
        <Grid container className={csstyle.app_links} >
        <Grid item xs={12} sm={12} md={8}>
        <h4>Download our App Now</h4>
        <p>Download the app to manage your projects, keep track of the progress and complete tasks without procastinating. Stay on track and complete on time!</p>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
        <h4>Get the App</h4>
        <Box>
        <img src="../images/google-play.svg"/>
        <img src="../images/apple-store.svg"/>
        </Box>
        </Grid>
        </Grid>
          <Grid container className={csstyle.foo_links}>
          
          <Grid item xs={12} sm={6} md={3}>
                <img src="../images/talgiving-logo-black.svg"/>
                <p>Touch-A-Life Foundation is a registered 501(c)(3) non-profit organization founded on the principle that helping others is good for society. We leverage technologies such as blockchain, AI, social media and mobile applications to connect those in need, with those willing to give.</p>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MenuList>
              <MenuItem>
              <Link>About us</Link>
              </MenuItem>
              <MenuItem>
              <Link>About Talgiving</Link>
              </MenuItem>
              <MenuItem>
              <Link>Leadership</Link>
              </MenuItem>
              <MenuItem>
              <Link >How it works</Link>
              </MenuItem>
              <MenuItem>
              <Link>Success stories</Link>
              </MenuItem>
            </MenuList>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MenuList>
              <MenuItem>
              <Link>Support</Link>
              </MenuItem>
              <MenuItem>
              <Link>FAQ</Link>
              </MenuItem>
              <MenuItem>
              <Link>Contact</Link>
              </MenuItem>
              <MenuItem>
              <Link >Privacy Policy</Link>
              </MenuItem>
              <MenuItem>
              <Link>Terms And Conditions</Link>
              </MenuItem>
            </MenuList>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <MenuList>
              <MenuItem>
              <Link>Resources</Link>
              </MenuItem>
              <MenuItem>
              <Link>Blogs</Link>
              </MenuItem>
              <MenuItem>
              <Link>Tutorials</Link>
              </MenuItem>
              <MenuItem>
              <Link >Press</Link>
              </MenuItem>
              
            </MenuList>
          </Grid>
          </Grid>
          <Divider/>
          <Grid container className={csstyle._subscribe}>
          <Grid item xs={12} sm={7} >
          <h2>Subscribe to <br/>our newsletter!</h2>
          <p>Stay informed, empower change together.</p>
          </Grid>
          <Grid item xs={12} sm={5} >
            <Box className={csstyle._letter_sub}>
            <TextField variant="outlined" className={csstyle._letter_sub_input} placeholder="Enter your email"/>
            <ArrowForward/>
            </Box>
          </Grid>
          </Grid>
          <Divider/>
          <Grid container className={csstyle.foo_copy}>
  
          <Grid item xs={12} sm={6} >
            
              <Button>
                <img src="../images/facebook-i.svg" alt=""/>
              </Button>
              <Button>
                <img src="../images/twitter-i.svg" alt=""/>
              </Button>
              <Button>
                <img src="../images/youtube-i.svg" alt=""/>
              </Button>
              <Button>
                <img src="../images/insta-i.svg" alt=""/>
              </Button>
           
          </Grid>
          <Grid item xs={12} sm={6} >
          <p>© 2011-2022 Copyright TALGiving. All Rights Reserved</p>
        </Grid>
          </Grid>
        </Container>
      </div>
    );
  };
  
  export default withRouter(NewFooter);
  