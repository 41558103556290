import React, { useContext, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
import Typography from "@material-ui/core/Typography";
import { getDonationRequest } from "../../utils/api";
import { appContext } from "../../App";

const useStyles = makeStyles({
  root: {
    padding: 10,
    "& img": {
      backgroundColor: "rgb(246, 247, 251)",
      objectFit: "contain",
    },
  },
});

export default function DonationRequestDetailsCard(props) {
  const classes = useStyles();
  const [donationRequest, setDonationRequest] = useState({});
  const { donationDetails } = useContext(appContext);

  useEffect(() => {
    donationDetails &&
      donationDetails.donationRequestId &&
      getDonationRequest(donationDetails.donationRequestId)
        .then((resp) => {
          setDonationRequest(resp);
        })
        .catch((e) => {
          console.log(e.message);
        });
  }, [donationDetails]);
  return (
    <>
      <Card className={classes.root}>
        <CardContent style={{ padding: "0" }}>
          <CardMedia
            component="img"
            alt="Contemplative Reptile"
            height="140"
            image={
              donationRequest.defaultImageUrl
                ? donationRequest.defaultImageUrl
                : "/images/tal-logo.jpg"
            }
            title="Contemplative Reptile"
          />
          <CardContent style={{ padding: "10px 0" }} className="dntinfo">
            <Typography gutterBottom variant="h4" component="h4">
              {donationRequest && donationRequest.title
                ? donationRequest.title
                : donationDetails &&
                  donationDetails.donationFor &&
                  donationDetails.donationFor == "tal"
                ? "Touch-A-Life Foundation"
                : ""}
            </Typography>
            <table className="billing_tbt">
              {
                <tr>
                  <td>
                    {donationDetails &&
                    donationDetails.donationFor &&
                    donationDetails.donationFor == "tal"
                      ? ""
                      : "Fundraiser : "}
                  </td>
                  <td>
                    {donationRequest.orgId
                      ? donationRequest &&
                        donationRequest.orgId &&
                        donationRequest.orgId.orgName
                      : donationRequest.user_info &&
                        donationRequest.user_info.name &&
                        donationRequest.user_info.name.first_name +
                          " " +
                          donationRequest.user_info.name.last_name}
                  </td>
                </tr>
              }
              {donationDetails && donationDetails.paymentMode ? (
                <>
                  <tr>
                    <td> You are donating :</td>
                    <td>
                      {donationDetails.currencySymbol}
                      {donationDetails.donatedAmount}
                    </td>
                  </tr>
                  <tr>
                    <td>Transaction charges :</td>
                    <td>
                      {donationDetails.currencySymbol}
                      {donationDetails.processingFees}
                    </td>
                  </tr>
                  <tr>
                    <td>Total:</td>
                    <td>
                      {donationDetails.currencySymbol}
                      {donationDetails &&
                        !isNaN(donationDetails.totalAmount) &&
                        donationDetails.totalAmount}
                    </td>
                  </tr>
                </>
              ) : (
                <tr>
                  <td> You are donating :</td>
                  <td>
                    {donationDetails.currencySymbol}
                    {donationDetails.originalAmount}
                  </td>
                </tr>
              )}
            </table>
          </CardContent>
        </CardContent>
      </Card>
      <Card style={{ marginTop: "15px", textAlign: "left" }}>
        <CardContent>
          For any queries please contact
          <br />
          <a href="mailto:admin@touchalife.org" style={{ color: "#007bff" }}>
            admin@touchalife.org
          </a>
        </CardContent>
      </Card>
    </>
  );
}
