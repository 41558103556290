import React, { useEffect, useState, useContext } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Checkbox from "@material-ui/core/Checkbox";

import { appContext } from "../../App";

import "../Organization/Organization.scss";

import CloseIcon from "@material-ui/icons/Close";

import {
  getMyContactGroups,
  deleteContactGroup,
  addContactGroup,
  updateContactGroup,
  getContactsByGroupId,
  assignContactsToGroup,
  unAssignContactsToGroup,
} from "../../utils/api";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    width: "50%",
    overflowX: "auto",
    height: "auto",
    maxHeight: "80vh",
    boxShadow: theme.shadows[5],

    borderRadius: "4px",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "100%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  title: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",
    "&:hover": {
      background: "#F44234",
    },
  },
  deleteBtn: {
    marginLeft: "50px",
  },
  btnCntr: {
    width: "40%",
    // padding: "20px",
    margin: "auto",
  },
  contactListCntr: {
    // width: "60%",
    margin: "auto",
    overflowX: "auto",
    height: "auto",
    maxHeight: "300px",

    "& div": {
      "&:hover": {
        backgroundColor: "rgb(246, 247, 251)",
      },
    },
  },
}));

const ContactGroupModal = (props) => {
  const otherGroupId = "none";
  const { authUser, contacts, contactGroups, setContactGroups } =
    useContext(appContext);
  const classes = useStyles();

  const [checked, setChecked] = React.useState([0]);
  const [groupName, setGroupName] = useState("");

  const [groupNameError, setGroupNameError] = useState("");
  const [checkedError, setCheckedError] = useState("");
  const [prevContacts, setPrevContacts] = useState([]);

  const handleToggle = (value) => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleAddEditGroup = async () => {
    if (!groupName) {
      setGroupNameError("Please enter group name");
      return;
    }
    if (contacts && contacts.length > 0 && checked.length <= 0) {
      setCheckedError("Please select any contact");
      return;
    }
    else {
      setGroupNameError("");
      setCheckedError("");
    }
    let data = null;
    const contactGroupsCopy = [...contactGroups];
    try {
      if (props.currentContactGroup) {
        data = await updateContactGroup(
          authUser.unique_id,
          props.currentContactGroup._id,
          { name: groupName, userId: authUser.unique_id }
        );
        const index = contactGroupsCopy.findIndex(
          (item) => item._id === props.currentContactGroup._id
        );
        contactGroupsCopy[index] = data.data;
      } else {
        data = await addContactGroup(authUser.unique_id, {
          name: groupName,
          userId: authUser.unique_id,
        });
        contactGroupsCopy.push(data.data);
      }
      setContactGroups(contactGroupsCopy);
      if (checked.length || prevContacts.length) {
        let newContacts = [];
        let deleteContacts = [];
        if (prevContacts.length) {
          prevContacts.forEach((item) => {
            if (
              checked.findIndex((sl) => sl._id === item.contactId._id) === -1
            ) {
              deleteContacts.push(item.contactId._id);
            }
          });
          newContacts = checked.filter(
            (item) =>
              prevContacts.findIndex((p) => p.contactId._id === item._id) === -1
          );
        } else {
          newContacts = checked;
        }
        if (deleteContacts.length && newContacts.length) {
          await Promise.all([
            unAssignContactsToGroup(
              authUser.unique_id,
              data.data._id,
              deleteContacts
            ),
            assignContactsToGroup(
              authUser.unique_id,
              data.data._id,
              newContacts.map((item) => item._id)
            ),
          ]);
        } else {
          if (deleteContacts.length) {
            await unAssignContactsToGroup(
              authUser.unique_id,
              data.data._id,
              deleteContacts
            );
          }

          if (newContacts.length) {
            await assignContactsToGroup(
              authUser.unique_id,
              data.data._id,
              newContacts.map((item) => item._id)
            );
          }
        }

        const groups = await getMyContactGroups(authUser.unique_id);
        setContactGroups(groups);
      }

      toastr.success(data.message);
      props.onContactGroupSave();
    } catch (error) {
      if (error.message === "Request failed with status code 409")
        toastr.error("Group name already exits");
    }
  };

  useEffect(() => {
    if (props.currentContactGroup) {
      setGroupName(props.currentContactGroup.name);
      getContactsByGroupId(
        authUser.unique_id,
        props.currentContactGroup._id
      ).then((data) => {
        setPrevContacts(data);
        const prevContactList = [];
        data.forEach((item) => {
          const contact = contacts.find((c) => c._id === item.contactId._id);
          prevContactList.push(contact);
        });
        setChecked(prevContactList);
      });
    } else {
      setGroupName("");
      setPrevContacts([]);
      setChecked([]);
    }
  }, [props.openModal]);

  const isOtherGroup = () => {
    let retVal = false;
    if (
      props.currentContactGroup &&
      props.currentContactGroup._id.toLowerCase() === otherGroupId
    )
      retVal = true;

    return retVal;
  };

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={props.onModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div className={classes.paper}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.title}>{`${props.currentContactGroup ? "Edit" : "Add"
                } Contact Group`}</h1>

              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={props.onModalClose} />
              </span>
            </div>
            <div className={classes.plasma}>
              <TextField
                type="text"
                required
                className={`input-field request-title`}
                variant="outlined"
                label="Group Name"
                name="contactGroup"
                value={groupName}
                disabled={isOtherGroup()}
                onChange={(e) => setGroupName(e.target.value)}
              />
              {groupNameError && (
                <div className="custom-error">{groupNameError}</div>
              )}
              <div className={classes.contactListCntr}>
                {contacts && contacts.length > 0 ? (
                  <List className={classes.root}>
                    {contacts.map((contact) => {
                      if (isOtherGroup() && checked.indexOf(contact) === -1)
                        return null;

                      const labelId = `checkbox-list-label-${contact._id}`;
                      return isOtherGroup() ? (
                        <ListItem
                          key={contact._id}
                          role={undefined}
                          dense
                          button
                          style={{
                            borderBottom: "1px solid rgb(246, 247, 251)",
                          }}
                        >
                          {!isOtherGroup() && (
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(contact) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            id={labelId}
                            primary={`${contact.firstName} ${contact.lastName}`}
                            secondary={contact.email || contact.phoneNumber}
                          />
                        </ListItem>
                      ) : (
                        <ListItem
                          key={contact._id}
                          role={undefined}
                          dense
                          button
                          onClick={handleToggle(contact)}
                          style={{
                            borderBottom: "1px solid rgb(246, 247, 251)",
                          }}
                        >
                          {!isOtherGroup() && (
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                checked={checked.indexOf(contact) !== -1}
                                tabIndex={-1}
                                disableRipple
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            </ListItemIcon>
                          )}
                          <ListItemText
                            id={labelId}
                            primary={`${contact.firstName} ${contact.lastName}`}
                            secondary={contact.email || contact.phoneNumber}
                          />
                        </ListItem>
                      );
                    })}
                  </List>
                ) : (
                  <div
                    style={{
                      fontSize: "12px",
                      padding: "10px 0",
                      textAlign: "center",
                    }}
                  >
                    No Contacts
                  </div>
                )}
                {checkedError && (
                  <div className="custom-error">{checkedError}</div>
                )}
              </div>
              {!isOtherGroup() && (
                <div className={classes.btnCntr}>
                  <Button
                    className={classes.saveBtn}
                    onClick={handleAddEditGroup}
                  >
                    {props.currentContact ? "Update" : "Save"}
                  </Button>
                </div>
              )}
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ContactGroupModal;
