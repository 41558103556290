import React from "react";
import NewTopbar from "../Topbar/NewTopbar";
import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  InputBase,
  withStyles,
} from "@material-ui/core";
import {
  ArrowForward,
  Info,
  Schedule,
  SearchRounded,
} from "@material-ui/icons";
import cssStyle from "./NewSearchFilters.module.scss";
import { FiFilter } from "react-icons/fi";
import NewFooter from "../footer/NewFooter";
import UseAnimations from "react-useanimations";
import heart from "react-useanimations/lib/heart";
import LinearProgress from "@material-ui/core/LinearProgress";
import { useState } from "react";
import thumbUp from "react-useanimations/lib/thumbUp";
import NewDonationRequestCard from "../donationRequest/NewDonationRequestCard";
import NewFilters from "./NewFilters";




const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#20A12D",
  },
}))(LinearProgress);
const style = {
  height: 300,
};



const NewSearchFilters = (props) => {
  const [bstrokeColor, setStrokeColor] = useState("#000"); // Initial stroke color is black
  const [drawerstate, setDrawerstate] = useState(false);


  const handleViewProfile = (state, isopen, ) => {
    setDrawerstate({ ...state, right: isopen });
    
  };
  const hanldeCloseDrawer = (state, isopen) => {
    setDrawerstate({ ...state, right: isopen });
  };



  const handleClick = (likeColor) => {
    // Change the stroke color to a new value when the icon is clicked
    const newColor = likeColor; // Generate a random color
    setStrokeColor(newColor);
  };

  return (
    <>
      <NewTopbar />
      <Container
        className={cssStyle.contain_head}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Box className={cssStyle.btn_Export}>
              <SearchRounded
                style={{ margin: "18 8px",width:"24px" ,height: "24px", color: "#A1A1A1" }}
              />
              <InputBase
                placeholder="Search TALGiving"
                width="100%"
                inputProps={{ "aria-label": "search,filter" }}
                style={{width:"85%"}}
              />

              <FiFilter  style={{width:"24px",height:"24px",color:"#535353",cursor:"pointer"}}
              onClick={() =>
                handleViewProfile("right", true,)
              }
              />
                <Drawer
                anchor="right"
                open={drawerstate["right"]}
                onClose={() => hanldeCloseDrawer("right", false)}
                className="Veiw_Profile_radius"
              >
                <NewFilters/>
                </Drawer>
             

            </Box>
          </div>
          <Box className={cssStyle.suggestion_txt}>
            <span>Suggestions</span>
          </Box>
          <Box className={cssStyle.suggestion_btns}>
            <Box>
              <Button>Fundraiser</Button>
            </Box>
            <Box>
              <Button>Education</Button>
            </Box>
            <Box>
              <Button>Animals</Button>
            </Box>
            <Box>
              <Button>CareerHelp</Button>
            </Box>
            <Box>
              <Button>Zero Hungers</Button>
            </Box>
          </Box>
        </Grid>
      </Container>
      <Container
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid item={12}>
          <Box className={cssStyle.Recommended_text}>
            <span>Recommended for you </span>
          </Box>
          <Box className={cssStyle.Recommended_btns}>
            <Box className={cssStyle.Most_funded_btns}>
              <Button>Most Funded</Button>
            </Box>
            <Box className={cssStyle.ending_btns}>
              <Button>Ending Soon</Button>
            </Box>
            <Box className={cssStyle.ending_btns}>
              <Button>Recently Added</Button>
            </Box>
          </Box>
        </Grid>
      </Container>
      <Grid className={cssStyle.donate_grid}>
        <NewDonationRequestCard/>
        <NewDonationRequestCard/>

        <Button className={cssStyle.show_more}>
          Show more
        </Button>
      </Grid>

    </>
  );
};
export default NewSearchFilters;
