import React, { useContext } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";

// import "../App.css";
import axios from "axios";
import { appContext } from "../../App";
import PaymentGate from "./PaymentGate";

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

export default function PaymentGateApp(props) {
  const location = useLocation();
  const receivedData = location.state?.data;
  const { apiKeys } = useContext(appContext);

  return (
    <div className="App payment-stripe-tal">
      <div>
        {/* <div className="sr-root"> */}
        <div className="sr-main">
          <Elements stripe={loadStripe(apiKeys["UsStripePublicKey"])}>
            <PaymentGate />
          </Elements>
        </div>
      </div>
    </div>
  );
}
