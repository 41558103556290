import React, { useState, useEffect, useContext } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import Button from "@material-ui/core/Button";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Typography,
  Link,
  TablePagination,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import SideMenu from "../SideMenu/Sidemenu";
import { appContext } from "../../App";
import { HOME_PAGE } from "../../utils/utils";
import { getReconciliationReport } from "../../utils/api";
import CircularProgress from "@mui/material/CircularProgress";
import { FileDownload } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "80%",
    margin: "0 auto",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
    "& td": {
      padding: "4px 16px",
    },
    "& td:nth-child(3)": {
      wordBreak: "break-word",
    },
    "& td:nth-child(6)": {
      "& div": {
        whiteSpace: "nowrap",
        // "& Button": {
        //   padding: "12px 12px 12px 0",
        // },
      },
    },
    "& td:nth-child(7)": {
      "& div": {
        whiteSpace: "nowrap",
      },
    },
  },
  container: {
    padding: "0 10px",
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  formcontrol: {
    minWidth: "150px",
  },
}));

const AdminReconciliationReport = (props) => {
  const { authUser, isSuperAdmin, isFinAdmin } = useContext(appContext);
  const classes = useStyles();
  const [reconciliationData, setReconciliationData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);
  const [closeSideMenu, setCloseSideMenu] = useState(false);
  const [isFileDownload, setIsFileDownload] = useState(false);
  const [status, setStatus] = useState("all");

  const getStatusLabel = (status) => {
    if (status === -1) {
      return "Rejected";
    } else if (status === -2) {
      return "Incomplete";
    } else if (status === -3) {
      return "Spam";
    } else if (status === -4) {
      return "Expired";
    } else if (status === 0) {
      return "Pending";
    } else if (status === 1) {
      return "Approved";
    } else if (status === 2) {
      return "Completed";
    }
  };

  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };

  const loadReconciliationReportData = (page, reportData) => {
    setIsLoading(true);
    if (authUser) {
      if (isFinAdmin || isSuperAdmin) {
        getReconciliationReport("", rowsPerPage, page * rowsPerPage, status)
          .then((response) => {
            setIsLoading(false);
            setTotalRecords(response && response.totalCountOfRecords);
            const reportRequestArray = [];
            reportData.map((data) => {
              const obj = data;
              reportRequestArray.push(obj);
              return null;
            });
            const responseArray = [];
            response.data.map((data) => {
              const obj = data;
              responseArray.push(obj);
              return null;
            });
            setReconciliationData([...reportRequestArray, ...responseArray]);
          })
          .catch((error) => {
            setIsLoading(false);
            toastr.error("Problem in fetching. " + error.message);
          });
      } else {
        setIsLoading(false);
        toastr.warning(
          "You do not have the privilege to access the requested page!"
        );
        props.history.push(HOME_PAGE);
      }
    }
  };

  useEffect(() => {
    setPage(0);
    setReconciliationData([]);
    loadReconciliationReportData(0, []);
  }, [authUser, rowsPerPage, status]);

  const handleChangePage = (event, newPage) => {
    loadReconciliationReportData(newPage, reconciliationData);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setReconciliationData([]);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const exportToCSV = () => {
    let download = true;
    setIsFileDownload(true);
    getReconciliationReport(download, "", "", status).then((resp) => {
      var hiddenElement = document.createElement("a");
      hiddenElement.href = `data:text/csv;charset=utf-8,${resp}`;
      hiddenElement.target = "_blank";
      hiddenElement.download = `Reconciliation_Report-${new Date().getTime()}.csv`;
      hiddenElement.click();
      setIsFileDownload(false);
    });
  };
  return (
    <div className="myDonations-container">
      <div className="barnav">
        <Container MaxWidth="lg">
          <div className="search-right">
            <div style={{ display: "flex" }}>
              {authUser && (
                <Button onClick={handleCloseSideMenu}>
                  {!closeSideMenu && <MenuIcon />}
                  {closeSideMenu && <ArrowBackIcon />}
                </Button>
              )}
              <h4>Manage Reconciliation Report</h4>
            </div>
          </div>
          <div className="adm-dr">
            <div>
              <FormControl variant="filled" className={classes.formcontrol}>
                <InputLabel htmlFor="Select Status">Select Status</InputLabel>
                <Select
                  variant="outlined"
                  value={status}
                  name="status"
                  label="Select Status"
                  onChange={handleStatus}
                >
                  <MenuItem value="all">All</MenuItem>
                  <MenuItem value="0">Pending</MenuItem>
                  <MenuItem value="1">Approved</MenuItem>
                  <MenuItem value="2">Completed</MenuItem>
                  <MenuItem value="-1">Rejected</MenuItem>
                  <MenuItem value="-3">Spam</MenuItem>
                  <MenuItem value="-4">Expired</MenuItem>
                </Select>
              </FormControl>
              <Button
                startIcon={<FileDownload />}
                style={{
                  borderRadius: "5px",
                  border: "none",
                  color: "white",
                  height: "54px",
                  margin: "0",
                  backgroundColor: "var(--tal_primary) ",
                  marginLeft: "10px",
                }}
                onClick={exportToCSV}
              >
                {!isFileDownload && "Export Data"}
                {isFileDownload && (
                  <CircularProgress
                    style={{ color: "white", lineHeight: "10px" }}
                  />
                )}
              </Button>
            </div>
          </div>
        </Container>
      </div>

      <Container maxWidth="lg">
        <div className="menu-container">
          {closeSideMenu && (
            <div style={{ marginRight: "10px" }}>
              <SideMenu />
            </div>
          )}

          <div style={{ position: "sticky", top: "0", width: "100%" }}>
            <TableContainer className={classes.container}>
              <Table
                stickyHeader
                className={classes.table}
                aria-labelledby="tableTitle"
                size={"medium"}
                aria-label="sticky table"
              >
                <TableHead>
                  <TableRow style={{ background: "#000", color: "#fff" }}>
                    <TableCell align={"left"} className="table-header-text">
                      Title
                    </TableCell>
                    <TableCell align={"left"} className="table-header-text">
                      Status
                    </TableCell>
                    <TableCell align={"left"} className="table-header-text">
                      Region
                    </TableCell>
                    <TableCell align={"left"} className="table-header-text">
                      Currency
                    </TableCell>
                    <TableCell align={"left"} className="table-header-text">
                      Amount Requested
                    </TableCell>
                    <TableCell align={"left"} className="table-header-text">
                      Amount Raised
                    </TableCell>
                    <TableCell align={"left"} className="table-header-text">
                      Amount Withdrawn
                    </TableCell>
                    <TableCell align={"left"} className="table-header-text">
                      Pending Amount to Withdraw
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {reconciliationData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      return (
                        <TableRow>
                          <TableCell align="left">
                            <Link
                              href={`/donationRequest/${row._id}`}
                              target="blank"
                            >
                              {row.title}
                            </Link>
                          </TableCell>
                          <TableCell align="left">
                            {getStatusLabel(row.status)}
                          </TableCell>
                          <TableCell align="left">{row.region}</TableCell>
                          <TableCell align="left">{row.units}</TableCell>
                          <TableCell align="left">{row.quantity}</TableCell>
                          <TableCell align="left">
                            {(row.donated_quantity).toFixed(2)}
                          </TableCell>
                          <TableCell align="left">
                            {(row.totalWithdrawalAmount).toFixed(2)}
                          </TableCell>
                          <TableCell align="left">
                            {(
                              row.donated_quantity - row.totalWithdrawalAmount
                            ).toFixed(2)}
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {isLoading && (
                    <TableRow>
                      <TableCell colSpan={20}>
                        <Typography className="no-req-text">
                          Loading...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {!isLoading &&
                    reconciliationData &&
                    reconciliationData.length === 0 && (
                      <TableRow>
                        <TableCell colSpan={20}>
                          <Typography className="no-req-text">
                            No Records Found
                          </Typography>
                        </TableCell>
                      </TableRow>
                    )}
                </TableBody>
              </Table>

              <TablePagination
                rowsPerPageOptions={[5, 10, 20, 50, 100, 500]}
                component="div"
                count={totalRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
                style={{
                  position: "sticky",
                  background: "white",
                  bottom: "0",
                  borderTop: "1px solid #eaeaea",
                }}
              />
            </TableContainer>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AdminReconciliationReport;
