import React, { useState, Suspense, lazy, useContext } from "react";
import { Box } from "@material-ui/core";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import ScrollToTop from "./components/ScrollTop";
import Topbar from "./components/Topbar/Topbar";
import Footer from "./components/footer/Footer";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import { Tooltip } from "@material-ui/core";
import ProtectedRoute from "./ProtectedRoute";
import Spinner from "./components/common/Spinner";
import StreamingForm from "./components/TALRadio/StreamingForm";
import AccessDenied from "./components/AccessDenied";
import AdminReconciliationReport from "./components/Admin/AdminReconciliationReport";
import NewHome from "./components/FBLayout/NewHome";
import NewLogin from "./components/auth/login/NewLogin";
import AllDonationRequestCard from "./components/donationRequest/AllDonationRequestCard";
import NewFundRequest from "./components/NewRequest/NewFundRequest";
import GetStarted from "./components/NewRequest/GetStarted";
import NewDonationRequest from "./components/NewRequest/NewDonationRequest";
import NewSearchFilters from "./components/NewRequest/NewSearchFilters";
import NewFilters from "./components/NewRequest/NewFilters";

import SelectAppCategory from "./components/NewRequest/SelectAppCategory";
import Nonprofits from "./components/FBLayout/Solutions/Nonprofits";
import Corporates from "./components/FBLayout/Solutions/Corporates";
import TALEventThankYou from "./components/thankyou/TALEventThankYou";
// import EventPayment from "./components/TALKindnessEvent/EventPayment";
import PaymentGateApp from "./components/TALKindnessEvent/PaymentGateApp";

const Home2 = lazy(() => import("./components/FBLayout/Home2"));
const Login = lazy(() => import("./components/auth/login/Login"));
const Signup = lazy(() => import("./components/auth/signup/Signup"));
const Authorize = lazy(() => import("./components/auth/login/Authorize"));
const Wkd = lazy(() => import("./components/TALKindnessEvent/Index.js"));

const NewUserProfile = lazy(() =>
  import("./components/userProfile/newUserProfile")
);

const UserListReports = lazy(() =>
  import("./components/UserList/UserListReports")
);
const Statitics = lazy(() => import("./components/Admin/Statastics"));
const NewRequest = lazy(() => import("./components/NewRequest/NewRequest"));
const Notifications = lazy(() =>
  import("./components/Notifications/Notifications")
);
const Internship = lazy(() => import("./components/Internship/Internship"));
const Volunteering = lazy(() =>
  import("./components/Volunteerings/Volunteerings")
);
const MyFavourites = lazy(() =>
  import("./components/donationRequest/MyFavourites")
);
const PlasmaForms = lazy(() => import("./components/Plasma/PlasmaForms"));
const PlasmaRequest = lazy(() =>
  import("./components/PlasmaRequest/PlasmaRequest")
);
const PlasmaDonorReq = lazy(() =>
  import("./components/PlasmaDonorReq/PlasmaDonor")
);
const CareerHelp = lazy(() => import("./components/CareerHelp/CareerHelp"));
const CovidHelpRequestForm = lazy(() =>
  import("./components/CovidHelp/CovidHelpRequestForm")
);
const VolunteerProfile = lazy(() =>
  import("./components/userProfile/volunteerProfile")
);
const BoardMemberRequest = lazy(() =>
  import("./components/TALLeaders/BoardMemberRequest")
);
const PodcastRequest = lazy(() =>
  import("./components/TALLeaders/PodcastRequest")
);
const ScholarshipRequest = lazy(() =>
  import("./components/Scholarship/ScholarshipRequest.js")
);
const EventParticipation = lazy(() =>
  import("./components/EventParticipation/EventParticipation.js")
);
const MentoringRequest = lazy(() =>
  import("./components/Mentoring/MentoringRequest.js")
);
const MyApplications = lazy(() =>
  import("./components/Scholarship/MyApplications.js")
);
const ViewApplications = lazy(() =>
  import("./components/Scholarship/ViewApplications.js")
);
const BecomeAVolunteer = lazy(() =>
  import("./components/userProfile/BecomeAVolunteer")
);
const ForgotPassword = lazy(() =>
  import("./components/auth/forgotPassword/ForgotPassword")
);
const ChangePassword = lazy(() =>
  import("./components/auth/changePassword/ChangePassword")
);
const AdminDonationRequests = lazy(() =>
  import("./components/Admin/AdminDonationRequests")
);
const AdminWithdrawalRequests = lazy(() =>
  import("./components/Admin/AdminWithdrawalRequests")
);
const WithdrawForm = lazy(() =>
  import("./components/withdrawals/WithdrawForm")
);
const RequestRaised = lazy(() =>
  import("./components/withdrawals/RequestRaised")
);
const MyWithdrawals = lazy(() =>
  import("./components/withdrawals/MyWithdrawals")
);
const MyOrganizations = lazy(() =>
  import("./components/Organization/MyOrganizations")
);
const MyContactsandGroups = lazy(() =>
  import("./components/Contacts/MyContactsandGroups")
);
const ChatTabs = lazy(() => import("./components/ChatRooms/ChatTabs"));
const ChatRoomForm = lazy(() => import("./components/ChatRooms/ChatRoomForm"));

const TalMediaForm = lazy(() => import("./components/TALMedia/TalMediaForm"));
const TALRadio = lazy(() => import("./components/TALRadio/TALRadio"));
const DonationRequest = lazy(() =>
  import("./components/donationRequest/DonationRequest")
);
const DonorEmailCampaign = lazy(() =>
  import("./components/donationRequest/DonorEmailCampaign")
);
const EmailEditConfirmation = lazy(() =>
  import("./components/donationRequest/EmailEditConfirmation")
);
const EmailContactGroups = lazy(() =>
  import("./components/donationRequest/EmailContactGroups")
);
const EmailEdit = lazy(() => import("./components/donationRequest/EmailEdit"));
const EmailImportFiles = lazy(() =>
  import("./components/donationRequest/EmailImportFiles")
);
const ContactEmailCampaign = lazy(() =>
  import("./components/donationRequest/ContactEmailCampaign")
);
const OtpValidation = lazy(() =>
  import("./components/auth/OTPValidation/OtpValidation")
);
const PublicPayment = lazy(() =>
  import("./components/donationDetails/PublicPayment")
);
const Payment = lazy(() =>
  import("./components/donationDetails/PaymentsPopUp")
);
const Organization = lazy(() =>
  import("./components/Organization/Organization")
);
const OrgDetails = lazy(() => import("./components/OrgDetails/OrgDetails"));
const BillingInfo = lazy(() =>
  import("./components/donationDetails/BillingInfo")
);
const StripePayment = lazy(() =>
  import("./components/paymentGateway/stripe/components/StripePayment")
);
const ThankYou = lazy(() => import("./components/thankyou/Thankyou"));
const MyDonations = lazy(() =>
  import("./components/donationDetails/MyDonations")
);
const EditUserName = lazy(() => import("./components/EditUsername"));
const TalRadioPlayer = lazy(() => import("./components/common/TalRadioPlayer"));
const Podcast = lazy(() => import("./components/common/Podcast"));
const TalMediaVideos = lazy(() => import("./components/common/TalMediaVideos"));
const InviteUser = lazy(() => import("./components/InviteUser/InviteUser"));
const InviteGeneralUser = lazy(() =>
  import("./components/InviteUser/InviteGeneralUser")
);
const VerifyOrganizationEmail = lazy(() =>
  import("./components/InviteUser/verifyOrganizationEmail")
);
const Feedback = lazy(() => import("./components/Feedback/Feedback"));
const FAQ = lazy(() => import("./components/FAQ/Faq"));
const Privacy = lazy(() => import("./components/Privacy/privacy"));
const Terms = lazy(() => import("./components/Terms/terms"));
const ContactUs = lazy(() => import("./components/contactUs/ContactUs"));
const PublicProfile = lazy(() =>
  import("./components/PublicProfile/PublicProfile")
);
const PublicProfileLandingPage = lazy(() =>
  import("./components/PublicProfileLandingPage")
);
const AdminDonations = lazy(() => import("./components/Admin/AdminDonations"));
const AdminOrganizations = lazy(() =>
  import("./components/Admin/AdminOrganizations")
);
const UserActivityLog = lazy(() =>
  import("./components/ActivityLogs/UserActivityLog")
);
const UserList = lazy(() => import("./components/UserList/UserList"));
const BloodDonorsList = lazy(() =>
  import("./components/UserList/BloodDonorsList")
);
const SendEmailToUsers = lazy(() =>
  import("./components/donationRequest/SendEmailtoUsers")
);
const Channels = lazy(() => import("./components/TALRadio/Channels"));
const UserInterestedCategories = lazy(() =>
  import("./components/TALLeaders/UserInterestedCategories")
);
const PayuThankyou = lazy(() => import("./components/thankyou/PayuThankyou"));
const PayuFailure = lazy(() => import("./components/thankyou/PayuFailure"));
const RazorPayment = lazy(() =>
  import("./components/paymentGateway/razorpay/RazorPayment")
);
const AudioNew = lazy(() => import("./components/liveStreaming/AudioNew"));
const BloodDonationRequest = lazy(() =>
  import("./components/BloodDonation/BloodDonation.js")
);
const ManageEventParticpants = lazy(() =>
  import("./components/Admin/ManageEventParticipants")
);
const ManageEventPayments = lazy(() =>
  import("./components/Admin/ManageEventPayments")
);
const Routes = () => {
  const [showArrow, setShowArrow] = useState(0);
  useScrollPosition(({ currPos }) => {
    setShowArrow(currPos.y);
  });
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <BrowserRouter>
      <ScrollToTop>
        {window.location.pathname.indexOf("/GetStarted") < 0 &&
          window.location.pathname.indexOf("/NewFundRequest") < 0 &&
          window.location.pathname.indexOf("/login") < 0 &&
          window.location.pathname.indexOf("/signup") < 0 &&
          window.location.pathname.indexOf("/tal-kindness-day-2023") < 0 &&
          window.location.pathname.indexOf("/thank-you-tal-event-2023") < 0 &&
          // window.location.pathname.indexOf("/EventPayment") < 0 &&
          window.location.pathname.indexOf("/TALEventPayment") < 0 &&
          window.location.pathname.indexOf("/talradiowidget") < 0 &&
          window.location.pathname.indexOf("/NewHome") < 0 &&
          window.location.pathname.indexOf("/NewLogin") < 0 &&
          window.location.pathname.indexOf("/SelectAppCategory") < 0 &&
          window.location.pathname.indexOf("/NewDonationRequest") < 0 &&
          window.location.pathname.indexOf("/NewSearchFilters") < 0 &&
          window.location.pathname.indexOf("/Nonprofits") < 0 &&
          window.location.pathname.indexOf("/Corporates") < 0 &&
          window.location.pathname.indexOf("/AllDonationRequestCard") < 0 && (
            <Topbar />
          )}

        <Box width="100%" minHeight="100vh">
          <Switch>
            <Suspense fallback={<Spinner />}>
              <Route exact path="/" component={Home2} />
              <Route path="/search/:keyword" exact component={Home2} />
              <Route path="/search/" exact component={Home2} />
              <Route exact path="/login" component={Login} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/tal-kindness-day-2023" component={Wkd} />
              <Route
                exact
                path="/thank-you-tal-event-2023"
                component={TALEventThankYou}
              />
              {/* <Route exact path="/EventPayment" component={EventPayment} /> */}
              <Route exact path="/TALEventPayment" component={PaymentGateApp} />
              <Route exact path="/authorize" component={Authorize} />
              <Route exact path="/NewHome" component={NewHome} />
              <Route exact path="/NewLogin" component={NewLogin} />
              <Route exact path="/GetStarted" component={GetStarted} />
              <Route
                exact
                path="/SelectAppCategory"
                component={SelectAppCategory}
              />
              <Route exact path="/Nonprofits" component={Nonprofits} />
              <Route exact path="/Corporates" component={Corporates} />
              <Route
                exact
                path="/AllDonationRequestCard"
                component={AllDonationRequestCard}
              />
              <Route
                exact
                path="/NewDonationRequest"
                component={NewDonationRequest}
              />
              <Route
                exact
                path="/NewSearchFilters"
                component={NewSearchFilters}
              />
              <Route exact path="/newFilters" component={NewFilters} />

              <ProtectedRoute
                exact
                path="/newUserProfile/:id"
                component={NewUserProfile}
              />

              <ProtectedRoute
                exact
                path="/new-request/:id"
                component={NewRequest}
              />

              <ProtectedRoute
                exact
                path="/new-request"
                component={NewRequest}
              />
              <ProtectedRoute
                exact
                path="/NewFundRequest"
                component={NewFundRequest}
              />
              <ProtectedRoute
                exact
                path="/User-reports/:id"
                component={UserListReports}
              />
              <ProtectedRoute
                exact
                path="/ManageEventParticipants"
                component={ManageEventParticpants}
              />
              <ProtectedRoute
                exact
                path="/ManageEventPayments"
                component={ManageEventPayments}
              />
              <ProtectedRoute
                exact
                path="/notifications"
                component={Notifications}
              />
              <ProtectedRoute
                exact
                path="/internship-request/:id"
                component={Internship}
              />
              <ProtectedRoute
                exact
                path="/internship-request"
                component={Internship}
              />
              <ProtectedRoute
                exact
                path="/volunteer-request/:id"
                component={Volunteering}
              />
              <ProtectedRoute
                exact
                path="/volunteer-request"
                component={Volunteering}
              />
              <ProtectedRoute
                exact
                path="/myfavourites"
                component={MyFavourites}
              />
              <ProtectedRoute
                exact
                path="/plasma-donee-donor/:id"
                component={PlasmaForms}
              />
              <ProtectedRoute
                exact
                path="/plasma-donee-donor"
                component={PlasmaForms}
              />
              <ProtectedRoute
                exact
                path="/plasma-donee-request"
                component={PlasmaRequest}
              />
              <ProtectedRoute
                exact
                path="/plasma-donor-request"
                component={PlasmaDonorReq}
              />
              <ProtectedRoute
                exact
                path="/careerhelp-request/:id"
                component={CareerHelp}
              />
              <ProtectedRoute
                exact
                path="/careerhelp-request"
                component={CareerHelp}
              />
              <ProtectedRoute
                exact
                path="/covidhelp-request/:id"
                component={CovidHelpRequestForm}
              />
              <ProtectedRoute
                exact
                path="/covidhelp-request"
                component={CovidHelpRequestForm}
              />
              <ProtectedRoute
                exact
                path="/volunteerProfile/:id"
                component={VolunteerProfile}
              />
              <ProtectedRoute
                exact
                path="/volunteerProfile"
                component={VolunteerProfile}
              />
              <ProtectedRoute
                exact
                path="/boardmember-request/:id"
                component={BoardMemberRequest}
              />
              <ProtectedRoute
                exact
                path="/boardmember-request"
                component={BoardMemberRequest}
              />
              <ProtectedRoute
                exact
                path="/podcast-request/:id"
                component={PodcastRequest}
              />
              <ProtectedRoute
                exact
                path="/podcast-request"
                component={PodcastRequest}
              />
              <ProtectedRoute
                exact
                path="/scholarship-request/:id"
                component={ScholarshipRequest}
              />
              <ProtectedRoute
                exact
                path="/scholarship-request"
                component={ScholarshipRequest}
              />
              <ProtectedRoute
                exact
                path="/bloodDonation-request/:id"
                component={BloodDonationRequest}
              />
              <ProtectedRoute
                exact
                path="/bloodDonation-request"
                component={BloodDonationRequest}
              />
              <ProtectedRoute
                exact
                path="/myApplications"
                component={MyApplications}
              />
              <ProtectedRoute
                exact
                path="/viewApplicants/:id"
                component={ViewApplications}
              />
              <ProtectedRoute
                exact
                path="/eventspeaker-request/:id"
                component={EventParticipation}
              />
              <ProtectedRoute
                exact
                path="/eventspeaker-request"
                component={EventParticipation}
              />
              <ProtectedRoute
                exact
                path="/mentoring-request"
                component={MentoringRequest}
              />
              <ProtectedRoute
                exact
                path="/mentoring-request/:id"
                component={MentoringRequest}
              />
              <Route
                exact
                path="/become-a-volunteer"
                component={BecomeAVolunteer}
              />
              <Route exact path="/forgot-password" component={ForgotPassword} />
              <ProtectedRoute
                exact
                path="/change-password"
                component={ChangePassword}
              />
              <ProtectedRoute
                exact
                path="/admin/donationRequests"
                component={AdminDonationRequests}
                role="finAdmin"
              />
              <ProtectedRoute
                exact
                path="/admin/withdrawalRequests"
                component={AdminWithdrawalRequests}
                role="finAdmin"
              />
              <ProtectedRoute
                exact
                path="/admin/reconciliationReport"
                component={AdminReconciliationReport}
                role="finAdmin"
              />
              <ProtectedRoute
                exact
                path="/withdrawForm/:id"
                component={WithdrawForm}
              />
              <ProtectedRoute
                exact
                path="/withdrawfunds/request-raised/:id"
                component={RequestRaised}
              />
              <ProtectedRoute
                exact
                path="/myWithdrawals"
                component={MyWithdrawals}
              />
              <ProtectedRoute
                exact
                path="/myOrganizations"
                component={MyOrganizations}
              />
              <ProtectedRoute
                exact
                path="/myContactsandGroups"
                component={MyContactsandGroups}
              />
              <ProtectedRoute exact path="/chatTabs" component={ChatTabs} />
              <ProtectedRoute
                exact
                path="/createAudioRoom"
                component={ChatRoomForm}
              />
              <ProtectedRoute
                exact
                path="/admin/managePodcast"
                component={TalMediaForm}
              />
              <ProtectedRoute
                exact
                path="/talRadioAdmin/manageTALRadio/tal-radio/:id"
                component={TALRadio}
                role="talradioAdmin"
              />
              <ProtectedRoute
                exact
                path="/talRadioAdmin/manageTALRadio/tal-radio/streaming-form/:id"
                component={StreamingForm}
                role="talradioAdmin"
              />
              <ProtectedRoute
                exact
                path="/talRadioAdmin/manageTALRadio/tal-radio/streaming-form"
                component={StreamingForm}
                role="talradioAdmin"
              />
              <Route
                exact
                path="/donationRequest/:id"
                component={DonationRequest}
              />
              <ProtectedRoute
                path="/startDonorEmailCampaign/:id"
                exact
                component={DonorEmailCampaign}
              />
              <ProtectedRoute
                exact
                path="/startContactEmailCampaign/EmailEditConfirmation/:id"
                component={EmailEditConfirmation}
              />
              <ProtectedRoute
                exact
                path="/startContactEmailCampaign/EmailContactGroups/:id"
                component={EmailContactGroups}
              />
              <ProtectedRoute
                exact
                path="/startContactEmailCampaign/EmailEdit/:id"
                component={EmailEdit}
              />
              <ProtectedRoute
                exact
                path="/startContactEmailCampaign/EmailImportFiles/:id"
                component={EmailImportFiles}
              />
              <ProtectedRoute
                exact
                path="/startContactEmailCampaign/:id"
                component={ContactEmailCampaign}
              />
              <Route exact path="/otp-validation" component={OtpValidation} />
              <Route
                exact
                path="/otp-validation/:id"
                component={OtpValidation}
              />
              <Route exact path="/publicPayment" component={PublicPayment} />
              <Route exact path="/payment" component={Payment} />
              <ProtectedRoute
                exact
                path="/add-organization"
                component={Organization}
              />
              <Route exact path="/organization/:id" component={OrgDetails} />
              <Route exact path="/billingInfo" component={BillingInfo} />
              <Route exact path="/StripePayment" component={StripePayment} />
              <Route exact path="/RazorPayment" component={RazorPayment} />
              <Route exact path="/thankyou" component={ThankYou} />
              <ProtectedRoute
                exact
                path="/myDonations"
                component={MyDonations}
              />
              <ProtectedRoute
                exact
                path="/editUsername"
                component={EditUserName}
              />
              <Route exact path="/talmedia/:file" component={TalRadioPlayer} />
              <Route exact path="/talmedia" component={Podcast} />
              <Route
                exact
                path="/podcast/talvideos"
                component={TalMediaVideos}
              />
              <Route
                exact
                path="/talradiowidget/:defaultChannel"
                component={AudioNew}
              />
              <Route exact path="/talradiowidget/" component={AudioNew} />
              <Route exact path="/invitation" component={InviteUser} />
              <Route
                exact
                path="/inviteGeneralUser"
                component={InviteGeneralUser}
              />
              <Route
                exact
                path="/verifyOrganizationEmail/:orgId/:userId"
                component={VerifyOrganizationEmail}
              />
              <Route exact path="/users/Feedback" component={Feedback} />
              <Route exact path="/users/help-desk" component={FAQ} />
              <Route exact path="/users/Privacy" component={Privacy} />
              <Route exact path="/users/Terms" component={Terms} />
              <Route exact path="/users/ContactUs" component={ContactUs} />
              <Route exact path="/user/:id" component={PublicProfile} />
              <ProtectedRoute
                exact
                path="/admin/donations"
                component={AdminDonations}
                role="finAdmin"
              />
              <ProtectedRoute
                exact
                path="/admin/organization"
                component={AdminOrganizations}
                role="admin"
              />
              <ProtectedRoute
                exact
                path="/admin/userActivityLog"
                component={UserActivityLog}
                role="admin"
              />
              <ProtectedRoute
                exact
                path="/admin/userList"
                component={UserList}
                role="admin || finAdmin"
              />
              <ProtectedRoute
                exact
                path="/admin/bloodDonors"
                component={BloodDonorsList}
                role="admin"
              />
              <ProtectedRoute
                exact
                path="/admin/SendEmailToUsers"
                component={SendEmailToUsers}
                role="admin"
              />
              <ProtectedRoute
                exact
                path="/admin/Channels"
                component={Channels}
                role="talradioAdmin"
              />
              <ProtectedRoute
                exact
                path="/admin/TALLeaders/userInterests"
                component={UserInterestedCategories}
                role="admin"
              />
              <ProtectedRoute
                exact
                path="/admin/statistics"
                component={Statitics}
                role="admin"
              />
              <Route exact path="/payu-thankyou/:id" component={PayuThankyou} />
              <Route exact path="/payu-error/:id" component={PayuFailure} />
              <Route exact path="/:id" component={PublicProfileLandingPage} />
              <Route exact path="/access-denied" component={AccessDenied} />
            </Suspense>
          </Switch>
        </Box>
        {showArrow < -500 && (
          <div
            style={{
              position: "fixed",
              bottom: 70,
              right: 10,
              background: "var(--tal_primary)",
              padding: "6px 8px",
              borderRadius: "50%",
              color: "#fff",
              cursor: "pointer",
              zIndex: "99",
            }}
            onClick={scrollToTop}
          >
            <Tooltip title="Scroll To Top">
              <ArrowUpwardIcon />
            </Tooltip>
          </div>
        )}

        {window.location.pathname.indexOf("/GetStarted") < 0 &&
          window.location.pathname.indexOf("/SelectAppCategory") < 0 &&
          window.location.pathname.indexOf("/NewFundRequest") < 0 &&
          window.location.pathname.indexOf("/login") < 0 &&
          window.location.pathname.indexOf("/signup") < 0 &&
          window.location.pathname.indexOf("/tal-kindness-day-2023") < 0 &&
          window.location.pathname.indexOf("/thank-you-tal-event-2023") < 0 &&
          // window.location.pathname.indexOf("/EventPayment") < 0 &&
          window.location.pathname.indexOf("/TALEventPayment") < 0 &&
          window.location.pathname.indexOf("/talradiowidget") < 0 &&
          window.location.pathname.indexOf("/NewHome") < 0 &&
          window.location.pathname.indexOf("/NewLogin") < 0 &&
          window.location.pathname.indexOf("/Nonprofits") < 0 &&
          window.location.pathname.indexOf("/Corporates") < 0 &&
          window.location.pathname.indexOf("/NewDonationRequest") < 0 &&
          window.location.pathname.indexOf("/NewSearchFilters") < 0 &&
          window.location.pathname.indexOf("/AllDonationRequestCard") < 0 && (
            <Footer />
          )}
      </ScrollToTop>
    </BrowserRouter>
  );
};

export default Routes;
