import React, { useContext } from "react";
import { withRouter } from "react-router-dom";
import PayPalBtn from "./main";
import toastr from "toastr";
import { saveDonation } from "../../../utils/api";
import { appContext } from "../../../App";
import { roundToTwoDecimalPlaces } from "../../../utils/utils";

const Paypal = (props) => {
  const { donationDetails } = useContext(appContext);

  const paymentHandler = (details, data) => {
    if (details.status === "COMPLETED") {
      var payload = {};
      payload.customerId = "";
      payload.clientSecret = "";
      payload.paymentMethod = "paypal";
      payload.paymentMode = " ";
      payload.transactionId = data.orderID;
      payload.units = donationDetails.settlementCurrency;
      payload.user_id = donationDetails.userId;
      payload.type = "cash";
      payload.donationFor = donationDetails.donationFor;
      payload.externalUserInfo = donationDetails.externalUserInfo;
      if (donationDetails.donationFor === "donationRequest") {
        payload.donation_request_id = donationDetails.donationRequestId;
      } else if (donationDetails.donationFor === "individual") {
        payload.toUserInfo = donationDetails.toUserInfo;
      }
      payload.quantity = donationDetails.donatedAmount;
      payload.billingAddress = donationDetails.billingAddress;
      payload.processingFees = roundToTwoDecimalPlaces(
        donationDetails.processingFees
      );
    }
    saveDonation(payload)
      .then((response) => {
        response.data.currency = payload.units;
        var thankyouResponse = response.data;
        props.history.push("/thankyou", { thankyouResponse });
      })
      .catch((error) => {
        toastr.error(error.message);
      });
  };

  return (
    <div>
      <PayPalBtn
        amount={donationDetails && donationDetails.totalAmount}
        currency={donationDetails && donationDetails.currency}
        onSuccess={paymentHandler}
      />
    </div>
  );
};

export default withRouter(Paypal);
