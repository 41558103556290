import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Box, Button, Container, Divider,  MenuItem, MenuList, Dialog, Slide,} from "@material-ui/core";
import Grid from '@material-ui/core/Grid';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import csstyle from "./Topbar.module.scss";
import { Apps, Close, ExpandMore, HomeOutlined, Launch, Search } from "@material-ui/icons";
import Drawer from '@material-ui/core/Drawer';
import { NEWLOGIN_PAGE} from "../../utils/utils";
import { useContext } from "react";
import { appContext } from "../../App";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import IconButton from "@material-ui/core/IconButton";
import { MdOutlineSpatialAudioOff } from "react-icons/md";
import {FiHome,FiGrid,FiLifeBuoy,FiBell,FiSettings,FiLogIn,FiChevronLeft, FiMenu,FiPlus } from "react-icons/fi";
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const TransitionLogin = React.forwardRef(function TransitionLogin(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
const NewTopbar = (props) => {
  /*top menu1*/
  const {
    authUser,
  } = useContext(appContext);

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }
/*top menu1*/
/*top menu 2 */
const [dopen, setDopen] = useState(false);
const danchorRef = useRef(null);

const handledToggle = () => {
  setDopen((prevOpen) => !prevOpen);
};

const handledClose = (event) => {
  if (danchorRef.current && danchorRef.current.contains(event.target)) {
    return;
  }

  setDopen(false);
};

function handledListKeyDown(event) {
  if (event.key === 'Tab') {
    event.preventDefault();
    setDopen(false);
  }
}

/*top menu 2*/
/*top menu 3 */
const [abopen, setAbopen] = useState(false);
const abanchorRef = useRef(null);

const handleabToggle = () => {
  setAbopen((prevOpen) => !prevOpen);
};

const handleabClose = (event) => {
  if (abanchorRef.current && abanchorRef.current.contains(event.target)) {
    return;
  }

  setAbopen(false);
};

function handleabListKeyDown(event) {
  if (event.key === 'Tab') {
    event.preventDefault();
    setAbopen(false);
  }
}

/*top menu 3*/
/*top menu 2 */
const [uopen, setUopen] = useState(false);
const uanchorRef = useRef(null);

const handleuToggle = () => {
  setUopen((prevOpen) => !prevOpen);
};

const handleuClose = (event) => {
  if (uanchorRef.current && uanchorRef.current.contains(event.target)) {
    return;
  }

  setUopen(false);
};

function handleuListKeyDown(event) {
  if (event.key === 'Tab') {
    event.preventDefault();
    setUopen(false);
  }
}

/*top menu 2*/
  // return focus to the button when we transitioned from !open -> open
  const prevOpen =useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);
  /*menu*/
  const [drawerstate, setDrawerstate] = useState(false);

  const toggleDrawer = (state, isopen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerstate({ ...state, right: isopen });
  };

  const [drawerstateMenu, setMenuDrawerstate] = useState(false);

  const toggleMenuDrawer = (state, isopen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setMenuDrawerstate({ ...state, right: isopen });
  };
  /*menu*/
  
  const [openDialog, setopenDialog] = useState(false);
  const handleClickOpen = () => {
    setopenDialog(true);
  };
 
  const handleDialogClose = () => {
    setopenDialog(false);
  };
 
  return (
    <header className={csstyle.main_header}>
   <Container className={csstyle.topfull_width}>
    <Grid container className="align-items-center">
    <Grid item xs={7}>
      <Grid className={csstyle.logo_btn}>
        
          <Grid className={csstyle.logo_v}>
          <span className={csstyle.logo_desk}><img src="./images/talgiving-logo-black.svg" alt=""/></span>
          <span className={csstyle.logo_mobi}><img src="./images/talgiving-logo-dom.png" alt=""/></span>
          </Grid>
          <Grid className={csstyle.menu_hide960}>
            <MenuList className={csstyle.header_menu}>
            <MenuItem ref={danchorRef}
              aria-controls={dopen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handledToggle}>Solutions <ExpandMore/> 
              </MenuItem>
              <Popper open={dopen} anchorEl={danchorRef.current} role={undefined} transition disablePortal className={csstyle.solution_menu}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handledClose}>
                      <MenuList autoFocusItem={dopen} id="menu-list-grow" onKeyDown={handledListKeyDown}>
                        
                        <MenuItem onClick={handleClose}>For Individuals Donees</MenuItem>
                        <MenuItem onClick={handleClose}>For Individuals Donors</MenuItem>
                        <MenuItem onClick={handleClose}>For Nonprofits</MenuItem>
                        <MenuItem onClick={handleClose}>For Corporates</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
              <MenuItem ref={anchorRef}
              aria-controls={open ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}>Explore <ExpandMore/> 
              
             
            </MenuItem>
            <MenuItem ref={abanchorRef}
              aria-controls={abopen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleabToggle}>About <ExpandMore/> 
              </MenuItem>
              <Popper open={abopen} anchorEl={abanchorRef.current} role={undefined} transition disablePortal className={csstyle.about_menu}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleabClose}>
                      <MenuList autoFocusItem={abopen} id="menu-list-grow" onKeyDown={handleabListKeyDown}>
                        
                        <MenuItem onClick={handleClose}>About TALGiving</MenuItem>
                        <MenuItem onClick={handleClose}>How It Works</MenuItem>
                        <MenuItem onClick={handleClose}>Leadership</MenuItem>
                        <MenuItem onClick={handleClose}>Partners</MenuItem>
                        <MenuItem onClick={handleClose}>Contact us</MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
              <MenuItem>TalMedia</MenuItem>
               
              
            </MenuList>
          </Grid>
      
      </Grid>
    </Grid>
    <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal className={csstyle.mega_menu_dd}>
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                     
                      
                      <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                     <Grid className={csstyle.sub_dd_menu_text}>
                      <p className={csstyle.explore_line}>Explore Categories</p>
                      </Grid>
                    <Grid container className={csstyle.sub_dd_menu}>
                    <Grid item md={3} sm={4} xs={12} > 
                      <Box className={csstyle.box_line}  onClick={() => {
                      props.history.push("/AllDonationRequestCard");
                    }}>
                        <div>
                        <img src="images/fundraiser_icon.svg" />
                        </div>
                        <div>
                        <h2 className="font-size-m black_text">Fundraisers</h2>
                        <p className=" font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                        </div>
                        </Box>
                      </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                      <Box className={csstyle.box_line}>
                         <div>
                        <img src="images/internship-icon.svg" />
                        </div>
                        <div>
                        <h2 className="font-size-m black_text">Internship</h2>
                        <p className=" font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                        </div>
                        </Box>
                        </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                      <Box className={csstyle.box_line}>
                        <div>
                        <img src="images/voluteering-icon.svg" />
                        </div>
                        <div>
                        <h2 className="font-size-m black_text">Volunteering</h2>
                        <p className=" font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                        </div>
                        </Box>
                        </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                      <Box className={csstyle.box_line}>
                        <div>
                          <img src="/images/career-help-icon.svg"/>
                        </div>
                        <div>
                        <h2 className="font-size-m black_text">Career Help</h2>
                        <p className="font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                       </div>
                       </Box>
                       </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                       
                      <Box className={csstyle.box_line}>
                      <div>
                      <img src="/images/plasma-new.svg"/>
                      </div>
                      <div>
                      <h2 className="font-size-m black_text">Plasma Donation</h2>
                      <p className=" font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                      </div>
                      </Box>
                      </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                      <Box className={csstyle.box_line}>
                       <div>
                       <img src="images/Board-member.svg" />
                      </div>
                      <div>
                      <h2 className="font-size-m black_text">Board Member</h2>
                      <p className=" font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                      </div>
                      </Box>
                      </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                      <Box className={csstyle.box_line}>
                      <div>
                      <img src="/images/scholarship-new.svg"/>
                      </div>
                      <div>
                      <h2 className="font-size-m black_text">Scholarship</h2>
                      <p className=" font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                      </div>
                      </Box>
                      </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                     <Box className={csstyle.box_line}>
                   <div>
                   <img src="/images/podcast.svg"/>
                     </div>
                     <div>
                     <h2 className="font-size-m black_text">Podcasts</h2>
                     <p className="font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                     </div>
                     </Box>
                     </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                      <Box className={csstyle.box_line}>
                      <div>
                      <img src="images/event.svg"/>
                      </div>
                      <div>
                      <h2 className="font-size-m black_text">Event Speaker</h2>
                      <p className=" font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                      </div>
                      </Box>
                      </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                      <Box className={csstyle.box_line}>
                       <div>
                       <img src="/images/career-help-icon.svg"/>
                      </div>
                      <div>
                      <h2 className="font-size-m black_text">Mentoring</h2>
                      <p className=" font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                      </div>
                      </Box>
                      </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                      <Box className={csstyle.box_line}>
                      <div>
                      <img src="images/blood-donation.svg"/>
                      </div>
                      <div>
                      <h2 className="font-size-m black_text">Blood Donation</h2>
                      <p className=" font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                      </div>
                      </Box>
                      </Grid>
                      <Grid item md={3} sm={4} xs={12} > 
                     <Box className={csstyle.box_line}>
                   <div>
                   <img src="/images/coronavirus.svg"/>
                     </div>
                     <div>
                     <h2 className="font-size-m black_text">Covid Help</h2>
                     <p className="font-size-xs light_text">Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                     </div>
                     </Box>
                    </Grid>
                     
                    </Grid>
                    
                      </MenuList>
                     
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
    <Grid item xs={5}>
      <Grid className={csstyle.toggle_menu_open}>
      <IconButton
       size="large"
       edge="start"
       aria-label="menu"
       onClick={toggleMenuDrawer("right", true)}
       
      >
      <FiMenu/>
      </IconButton>
      <Drawer 
          anchor="right"
          open={drawerstateMenu["right"]}
          onClose={toggleMenuDrawer("right", false)}
          
        >
        <MenuList className={csstyle.top_m_menu}>
          <MenuItem>
            <Accordion >
        <AccordionSummary
          expandIcon={<FiChevronLeft/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={csstyle.top_dd_menu}
        >
          <Typography>Donor</Typography>
        </AccordionSummary>
        <AccordionDetails className={csstyle.top_dd_submenu}>
        <MenuList>
        <MenuItem>
          Donor
        </MenuItem>
        <Divider/>
        <MenuItem>
           Donee
        </MenuItem>
        
        </MenuList>
        </AccordionDetails>
            </Accordion>
          </MenuItem>
          <Divider/>
          <MenuItem>
            <Accordion >
        <AccordionSummary
          expandIcon={<FiChevronLeft/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
          className={csstyle.top_dd_menu}
        >
          <Typography>Explore</Typography>
        </AccordionSummary>
        <AccordionDetails  className={csstyle.top_dd_submenu}>
        <MenuList>
        <MenuItem>
          Fundraiser
        </MenuItem>
        <Divider/>
        <MenuItem>
           Covidhelp
        </MenuItem>
        <Divider/>
        <MenuItem>
           Plasma Donation
        </MenuItem>
        <Divider/>
        <MenuItem>
           Career Help
        </MenuItem>
        </MenuList>
        </AccordionDetails>
            </Accordion>
          </MenuItem>
          <Divider/>
          <MenuItem className={csstyle.sub_ad}>
          <MdOutlineSpatialAudioOff/>
          
            Audio Rooms
          </MenuItem>
          <Divider/>
          <MenuItem className={csstyle.sub_ad}>
          <img src="/images/talradio-mobile.png" alt="TALMedia" class="talmediamobi"/>
          <span>TalMedia</span>
          </MenuItem>
          <Divider/>
          <MenuItem className={csstyle.sub_ad}>
          <FiLogIn/> Logout
          </MenuItem>
        </MenuList>
 </Drawer>
      </Grid>
      <Grid className={csstyle.right_header_buttons}>
      
        <Button><Search/> </Button>
       <Button onClick={() => {
                      props.history.push("/SelectAppCategory");
                    }}><FiPlus/> {" "}Create Campaign</Button>
        <Button><img src="./images/flag.svg"/></Button>
        <Button className={csstyle.app_buttons} onClick={toggleDrawer("right", true)}> <Apps/> </Button>
        {authUser ? 
        <>
         <MenuItem ref={uanchorRef}
         aria-controls={uopen ? 'menu-list-grow' : undefined}
         aria-haspopup="true"
         onClick={handleuToggle}
         className={csstyle.user_log}
         ><img src="./images/user-1.svg"/> <ExpandMore/> 
         </MenuItem>
         <Popper open={uopen} anchorEl={uanchorRef.current} role={undefined} transition disablePortal>
         {({ TransitionProps, placement }) => (
           <Grow
             {...TransitionProps}
             style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom' }}
           >
             <Paper>
               <ClickAwayListener onClickAway={handleuClose}>
                
                 <MenuList autoFocusItem={dopen} id="menu-list-grow" onKeyDown={handleuListKeyDown} className={csstyle.user_dd}>
                 <Grid className={csstyle.kindness_score + " " + "d-flex" }>
                  <img src="./images/Kindness_Score.svg" alt=""/>
                  <Grid>
                    <h4>365</h4>
                    <p>Kindness Score</p>
                  </Grid>
                </Grid>
                   
                   <MenuItem>
                   <Accordion className={csstyle.user_sub_dd + " " + "drop_arrow"}>
        <AccordionSummary
          expandIcon={<FiChevronLeft/>}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography ><FiHome/> <span>Set Default Homepage</span></Typography>
        </AccordionSummary>
        <AccordionDetails className={csstyle.user_acc_select}>
        <MenuList className={csstyle.user_sub_ddselect}>
        <MenuItem>
          <div className={csstyle.form_check}>
              <label>
              <span>Homepage</span>
              <input type="radio" className={csstyle.input_radio + " " + csstyle.off_check} name="pilih"/>
              </label>  
              </div> 
</MenuItem>
        <MenuItem>
        <div className={csstyle.form_check}>
              <label>
              <span>Dashboard</span>
              <input type="radio" className={csstyle.input_radio + " " + csstyle.off_check} name="pilih"/>
              </label>  
              </div> 
        </MenuItem>
        <MenuItem>
        <div className={csstyle.form_check}>
              <label>
              <span>Public Profile</span>
              <input type="radio" className={csstyle.input_radio + " " + csstyle.off_check} name="pilih"/>
              </label>  
              </div> 
        </MenuItem>
        </MenuList>
        </AccordionDetails>
                    </Accordion>
                   </MenuItem>
                   <MenuItem onClick={handleuClose}><FiGrid/> <span>My Dashboard</span></MenuItem>
                   <MenuItem onClick={handleuClose}><FiLifeBuoy/> <span>My Organization</span></MenuItem>
                   <MenuItem onClick={handleuClose}><FiBell/> <span>Notifications</span></MenuItem>
                   <MenuItem onClick={handleuClose}> <FiSettings/><span>Settings</span></MenuItem>
                   <Divider/>
                   <MenuItem onClick={handleuClose}><FiLogIn/> <span>Logout</span></MenuItem>
                 </MenuList>
               </ClickAwayListener>
             </Paper>
           </Grow>
         )}
       </Popper>
       </>
         : 
        <Button className={csstyle.login_buttons} 
        onClick={() => {props.history.push(NEWLOGIN_PAGE);}}>Login/Signup</Button>
        }
        <Drawer 
          anchor="right"
          open={drawerstate["right"]}
          onClose={toggleDrawer("right", false)}
          
        >
          <Box className={csstyle.app_drawer} >
            <Box className={csstyle.app_title}>
              <h2>Other TALProducts</h2>
              
              <Close onClick={toggleDrawer("right", false)}/>
            </Box>
            <Box className={csstyle.app_list}>
              <a href="https://www.talscouts.org/" target="_blank">
                <img src="./images/talscouts.svg" alt=""/>
                <Box>
                  <h2>TALscouts</h2>
                  <span>Guide youngsters for social innovation and community service</span>
                </Box>
                <Box className={csstyle.app_link_icons}>
                  <Launch/>
                </Box>
              </a>
              <a href="https://www.talscouts.org/" target="_blank">
                <img src="./images/talleaders.svg" alt=""/>
                <Box>
                  <h2>TALLeaders</h2>
                  <span>Board advisory and mentors support from semi professionals, and executives</span>
                </Box>
                <Box className={csstyle.app_link_icons}>
                  <Launch/>
                </Box>
              </a>
              <a href="https://www.talscouts.org/" target="_blank">
                <img src="./images/talradio.svg" alt=""/>
                <Box>
                  <h2>TALRadio</h2>
                  <span>Inspire propogate positive and impactful</span>
                </Box>
                <Box className={csstyle.app_link_icons}>
                  <Launch/>
                </Box>
              </a>
              <a href="https://www.talscouts.org/" target="_blank">
                <img src="./images/talwines.svg" alt=""/>
                <Box>
                  <h2>TALWines</h2>
                  <span>Profits on the product sales directly goes to the non-profits</span>
                </Box>
                <Box className={csstyle.app_link_icons}>
                  <Launch/>
                </Box>
              </a>
              <a href="https://www.talscouts.org/" target="_blank">
                <img src="./images/talhospitals.svg" alt=""/>
                <Box>
                  <h2>TALHospitals</h2>
                  <span>Inspire propogate positive and impactful</span>
                </Box>
                <Box className={csstyle.app_link_icons}>
                  <Launch/>
                </Box>
              </a>
            </Box> 
            <Divider/>
            <Box className={csstyle.foot_link}>
            <h2>Powered By
              <a href="#">Touch-A-Life Foundation Inc</a> 
              </h2>
            </Box>
          </Box>
          </Drawer>
      </Grid>
    </Grid>
    </Grid>
   </Container>
   </header>
   
  );
};

export default withRouter(NewTopbar);