import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import TALEventDesign from "../auth/LogdesignTALEvent";
import { Container, Button } from "@material-ui/core";
import { Divider, TextField } from "@mui/material";
import toastr from "toastr";
import { Elements } from "@stripe/react-stripe-js";
import LockIcon from "@material-ui/icons/Lock";
import { appContext } from "../../App";
import api from "../paymentGateway/stripe/api";
import { eventDonation } from "../../utils/api";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";

const PaymentGate = (props) => {
  const { tALEventPayemntDetails, authUser } = useContext(appContext);

  const [disableSubmitButton, setDisableSubmitButton] = useState(false);
  const [ticketsCount, setTicketsCount] = useState(4);
  const stripe = useStripe();
  const elements = useElements();
  const [cardholderName, setCardholderName] = useState("");
  const [error, setError] = useState(null);

  const handleTickets = (val) => {
    if (val == "inc") {
      setTicketsCount(ticketsCount + 1);
    } else if (val == "dec") {
      setTicketsCount(ticketsCount - 1);
    }
  };
  const options = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };
  const handleError = (err) => {
    setError(false);
  };
  const handleChangeCardHolderName = (e) => {
    setCardholderName(e.target.value);
  };
  const handleSubmit = async (ev) => {
    setDisableSubmitButton(true);
    ev.preventDefault();
    var customer_id;
    function paymentComplete(clientSecret, cardData = null) {
      stripe.retrievePaymentIntent(clientSecret).then(function (result) {
        var paymentIntent = result.paymentIntent;
        paymentIntent.customer_id = customer_id;
        if (paymentIntent.last_payment_error != null) {
          setDisableSubmitButton(false);
          var paymentIntentJson = JSON.stringify(paymentIntent, null, 2);
          setError(
            "Some problem while processing the card. Please check the card information again."
          );
          // setSucceeded(false)1;
          // setProcessing(false);
          // setMetadata(paymentIntent);
          return console.log("[PaymentIntent]", paymentIntentJson);
        } else {
          paymentIntentJson = JSON.stringify(paymentIntent, null, 2);
          setError(null);
          // setSucceeded(true);
          // setProcessing(false);
          // setMetadata(paymentIntent);
          var payload = {};
          payload.customerId = paymentIntent.customer_id;
          payload.clientSecret = paymentIntent.client_secret;
          payload.paymentMethod = "stripe";
          // payload.paymentMode = donationDetails.paymentMode;
          payload.units = paymentIntent.currency;
          payload.user_id = tALEventPayemntDetails?._id;
          // payload.donationFor = donationFor;
          // if (donationFor === "donationRequest") {
          //   payload.donation_request_id = donationRequestId;
          // } else if (donationFor === "individual") {
          //   payload.toUserInfo = toUserInfo;
          // }
          // payload.externalUserInfo = externalUserInfo;
          payload.attendees = tALEventPayemntDetails?.attendees;
          payload.eventPackageType = tALEventPayemntDetails?.categoryPackage;
          payload.quantity = tALEventPayemntDetails?.categoryPackage;
          payload.transactionId = paymentIntent.id;
          payload.type = "cash";
          // payload.anonymous = isAnonymous;
          payload.cardInfo = cardData.paymentMethod.card;
          // payload.billingAddress = paymentData.billingAddress;
          // payload.processingFees = roundToTwoDecimalPlaces(
          //   donationDetails.processingFees
          // ); token
          eventDonation(
            payload,
            authUser?.unique_id ? " " : tALEventPayemntDetails?.token
          )
            .then((response) => {
              if (customer_id !== "") {
                localStorage.setItem("stripeCustomerId", customer_id);
              }
              response.data.currency = payload.units;
              var thankyouResponse = response.data;
              // props.history.push("/thankyou", { thankyouResponse });
              props.history.push("/thank-you-tal-event-2023");
            })
            .catch((error) => {
              toastr.error(error.message);
            });
        }
      });
    }

    var data = {
      billing_details: {},
    };
    if (cardholderName) {
      data["billing_details"]["name"] = cardholderName;
    }

    // if (cardholderName) {
    //   data["billing_details"]["name"] = cardholderName;
    //   data.billing_details.address = {
    //     line1: paymentData.billingAddress.line1,
    //     postal_code: paymentData.billingAddress.zip_code,
    //     city: paymentData.billingAddress.city,
    //     state: paymentData.billingAddress.state,
    //     //country: paymentData.billingAddress.country,
    //   };
    // }

    stripe
      .createPaymentMethod(
        "card",
        elements.getElement(
          CardNumberElement,
          CardExpiryElement,
          CardCvcElement
        ),
        data
      )
      .then(function (card_response) {
        if (card_response.error) {
          setDisableSubmitButton(false);
          setError(card_response.error.message);
          return null;
        }

        //process if no errors
        api
          .createPaymentIntent({
            payment_method_types: ["card"],
            paymentMethodId: card_response.paymentMethod.id,
            amount: Math.ceil(
              Number(tALEventPayemntDetails?.categoryPackage).toFixed(2) * 100
            ),
            currency: "USD",
            settlementCurrency: "USD",
            customer_id: tALEventPayemntDetails?._id,
            saveCard: false,
          })
          .then(function (paymentData) {
            if (paymentData.requiresAction) {
              customer_id = paymentData.customer_id;
              stripe
                .handleCardAction(paymentData.clientSecret)
                .then(function (actionData) {
                  if (actionData.error) {
                    setDisableSubmitButton(false);
                    setError(
                      "Your card was not authenticated, please try again"
                    );
                  } else if (
                    actionData.paymentIntent.status === "requires_confirmation"
                  ) {
                    // Card was properly authenticated, we can attempt to confirm the payment again with the same PaymentIntent and adding currency
                    api
                      .createPaymentIntent({
                        paymentIntentId: actionData.paymentIntent.id,
                        currency: "USD",
                        settlementCurrency: "USD",
                      })
                      .then(function (paymentSuccess) {
                        if (paymentSuccess.error) {
                          // setProcessing(false);
                          setError(paymentSuccess.error);
                        } else {
                          paymentComplete(
                            paymentData.clientSecret,
                            card_response
                          );
                        }
                      });
                  }
                });
            } else if (paymentData.error) {
              // setProcessing(false);
              setDisableSubmitButton(false);
              setError("Your card was not authenticated, please try again");
            } else if (paymentData.statusCode === 400) {
              // setProcessing(false);
              setDisableSubmitButton(false);
              setError(paymentData.raw.message);
            } else {
              setDisableSubmitButton(true);
              customer_id = paymentData.customer_id;
              paymentComplete(paymentData.clientSecret, card_response);
            }
          })
          .catch((err) => {
            setDisableSubmitButton(false);
            // setProcessing(false);
            setError(
              "Some problem while processing the card. Please check the card information again."
            );
          });
      })
      .catch((err) => {
        // setProcessing(false);
        setError(
          "Some problem while processing the card. Please check the card information again."
        );
      });
  };
  return (
    <Box component="main">
      <Grid container>
        <Grid item xs={12}>
          <TALEventDesign />
        </Grid>
        <Grid item xs={12}>
          <Container>
            {" "}
            <div
              className="warning-bill"
              // style={{ display: "flex", justifyContent: "center" }}
            >
              <div className="icon-bill">
                {" "}
                <ErrorOutlineIcon />
              </div>
              <p className="text-bill">
                Please do not refresh the page or click the "Back"or "Close"
                button of your browser
              </p>{" "}
            </div>
            <Grid className="payment_communication donation-payment-container  ">
              <Grid container spacing={3}>
                <Grid item md={8} sm={12} xs={12} className="order_pay2">
                  <h4 className="mb-3">Payment Method</h4>{" "}
                  <form onSubmit={handleSubmit}>
                    <Grid container className="mt-4" spacing={3}>
                      <Grid item md={6} xs={12} sm={12}>
                        <div className="card-grid">
                          <CardNumberElement
                            className="sr-input sr-card-element"
                            options={options}
                            onChange={handleError}
                          />
                        </div>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12}>
                        <div className="card-grid">
                          <CardExpiryElement
                            className="sr-input sr-card-element"
                            options={options}
                            onChange={handleError}
                          />
                        </div>
                      </Grid>

                      <Grid item md={6} xs={12} sm={12}>
                        <div className="card-cvv card-grid">
                          <CardCvcElement
                            className="sr-input sr-card-element "
                            options={options}
                            onChange={handleError}
                          />
                          <img src="/images/cvc.png" alt="card-cvv" />
                        </div>
                      </Grid>
                      <Grid item md={6} xs={12} sm={12}>
                        <div className="card-grid last-name">
                          <input
                            type="text"
                            id="name"
                            name="name"
                            placeholder="Name on card"
                            autoComplete="cardholder"
                            className="sr-input sr-card-element StripeElement StripeElement--empty"
                            style={{ "z-index": "1" }}
                            onChange={(e) => handleChangeCardHolderName(e)}
                            value={cardholderName}
                          />
                        </div>
                      </Grid>

                      {error && (
                        <div className="message sr-field-error">{error}</div>
                      )}
                    </Grid>
                  </form>
                  <Box className="register_event pay_btn_event">
                    {
                      <Button
                        variant="contained"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={disableSubmitButton}
                      >
                        <LockIcon style={{ marginRight: 6 }} /> Pay USD{" "}
                        {tALEventPayemntDetails?.categoryPackage}
                      </Button>
                    }
                  </Box>
                </Grid>
                <Grid item md={4} sm={12} xs={12} className="order_pay1">
                  <Box className="pay_info_box">
                    <Box className="payment_title">
                      <h3>Payment Summary</h3>
                      <Divider />
                      <p className="mt-3">
                        <span>Category</span>
                        <span>{tALEventPayemntDetails?.category}</span>
                      </p>
                      <p>
                        <span>Package</span>
                        <span>$ {tALEventPayemntDetails?.categoryPackage}</span>
                      </p>
                      <p>
                        {/* <span>No. of Tickets</span> */}
                        <span>
                          {/* <Button
                            variant="outlined"
                            onClick={(e) => handleTickets("dec")}
                            // className={csstyle.inde_btn}
                          >
                            -
                          </Button>{" "} */}
                          {/* {ticketsCount} */}
                          {/* <Button
                            variant="outlined"
                            onClick={(e) => handleTickets("inc")}
                            // className={csstyle.inde_btn}
                          >
                            +
                          </Button>{" "} */}
                        </span>
                      </p>
                      {/* <div className="coupon_code">
                        <div className="coupon-textfield">
                          <TextField
                            placeholder="Type coupon code here"
                            style={{ width: "196%" }}
                          />
                        </div>
                        <div className="coupon_apply-bt">
                          <Button>Apply</Button>
                        </div>
                      </div> */}
                      <Divider style={{ marginTop: "10px" }} />
                      <p className="mt-3">
                        <span>Total</span>
                        <span>$ {tALEventPayemntDetails?.categoryPackage}</span>
                      </p>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Container>
        </Grid>
      </Grid>
    </Box>
  );
};

export default withRouter(PaymentGate);
