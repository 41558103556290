import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { getUserData } from "../../utils/api";
import toastr from "toastr";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import "./TALEventThankYou.css";
import ReactGA from "react-ga4";
import { Box } from "@material-ui/core";
import { Button } from "@mui/material";
import DirectionsIcon from "@mui/icons-material/Directions";

const TALEventThankYou = (props) => {
  const [userId, setUserId] = useState(null);

  const handleHomepage = () => {
    props.history.push("/");
  };

  useEffect(() => {
    ReactGA.event({
      category: "Button",
      action: "tal_event_thank_you",
      label: "TALEvent Thank you Page",
      value: 1,
    });
  }, []);

  useEffect(() => {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    if (params.get("refId")) {
      setUserId(params.get("refId"));
    }
  }, []);

  const loadUserData = (userId) => {
    if (userId) {
      getUserData(userId)
        .then((response) => {
          console.log("User response", response.data);
        })
        .catch((error) => {
          if (error.data && error.data.statusCode === 422) {
            toastr.error(error.data.message);
          }
        });
    }
  };

  // Load DonationRequest Object
  useEffect(() => {
    userId && loadUserData(userId);
  }, [userId]);

  return (
    <div className="thank-container  thankyou-page">
      <Container maxWidth="lg">
        <div className="drop-box">
          <Grid container>
            <div className="thankyoucontent">
              <div className="wrapper-1">
                <div
                  style={{
                    textAlign: "center",
                  }}
                >
                  <img
                    src="/images/touch-a-life-logo-black.jpg"
                    alt="thank-you-envelope"
                    border={0}
                    width={"60%"}
                  />
                </div>
                <div className="wrapper-2">
                  <h3 style={{ margin: "30px 0 20px 0" }}>
                    Thank You for Registering!
                  </h3>
                  <p>Your registration has been successfully received.</p>
                  <p>
                    We are incredibly pleased to extend our warmest welcome to
                    you for TAL Kindness Day 2023!
                  </p>
                  <div className="event-details">
                    <p style={{ textAlign: "left" }}>
                      <strong>Event Details:</strong>
                    </p>
                    <table border="1" className="thank_table">
                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          <strong>Timing</strong>
                        </td>
                        <td className="left-align">
                          Nov 18, 2023 | 11:00 AM - 08:30 PM PST
                        </td>
                      </tr>
                      <tr>
                        <td style={{ verticalAlign: "top" }}>
                          <strong>Venue</strong>
                        </td>
                        <td className="left-align">
                          SRCM Heartfulness Meditation Centre, <br />
                          585 Mowry Ave, <br />
                          Fremont, CA 94536
                          <br />
                          <Button
                            href="https://maps.app.goo.gl/f9Um5NLRRebPNgEs5"
                            target="_blank"
                            style={{
                              backgroundColor: "#1a73e8",
                              color: "white",
                              margin: "5px 0px",
                            }}
                          >
                            <DirectionsIcon
                              style={{ color: "white", marginRight: "3px" }}
                            />{" "}
                            Get Directions
                          </Button>
                          <br />
                        </td>
                      </tr>
                    </table>
                  </div>
                  {/* <small
                    style={{
                      clear: "both",
                      textAlign: "left",
                      display: "block",
                      marginTop: "5px",
                    }}
                  >
                    Check your email for more information.{" "}
                  </small> */}
                  <br />
                  <Box className="pb-1">
                    <Button
                      style={{
                        backgroundColor: "rgb(243, 90, 87)",
                        color: "white",
                        margin: "5px 0px",
                        padding: "15px 20px",
                        margin: "30px 0",
                        borderRadius: "5px",
                        cursor: "pointer",
                      }}
                      className="go-home"
                      onClick={handleHomepage}
                    >
                      Go to home page
                      <a href="/"></a>
                    </Button>
                  </Box>
                </div>
              </div>
            </div>
          </Grid>
        </div>
      </Container>
    </div>
  );
};

export default withRouter(TALEventThankYou);
