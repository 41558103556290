import validator from "validator";
const validateAddress = (values) => {
  let errors = {};
  if (values.zip_code && !validator.isPostalCode(values.zip_code)) {
    errors.zip_code = "Please enter valid zip_code";
  }
  return errors;
};

export default validateAddress;
