import React, { useState } from "react";
import { Grid, Box, Button, Container } from "@material-ui/core";
import csstyle from "./SelectAppCategory.module.scss";
import { FiArrowRight } from "react-icons/fi";

const SelectAppCategory = props => {
  const handleClick = (e, app) => {
    // 👇️ toggle isActive state on click
    setAppActive(app.title);
  };
  const [appActive, setAppActive] = useState("");
  const appCategorys = [
    {
      title: "Fundraisers",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/fundraiser_icon.svg",
    },
    {
      title: "Internship",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/internship-icon.svg",
    },
    {
      title: "Volunteering",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/voluteering-icon.svg",
    },
    {
      title: "Career Help",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/career-help-icon.svg",
    },
    {
      title: "Plasma Donation",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/plasma-new.svg",
    },
    {
      title: "Board Member",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/Board-member.svg",
    },
    {
      title: "Scholarship",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/scholarship-new.svg",
    },
    {
      title: "Podcasts",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/podcast.svg",
    },
    {
      title: "Event Speaker",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/event.svg",
    },
    {
      title: "Mentoring",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/career-help-icon.svg",
    },
    {
      title: "Blood Donation",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/blood-donation.svg",
    },
    {
      title: "Covid Help",
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting .",
      image: "images/coronavirus.svg",
    },
  ];
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Grid className={csstyle.login_top}>
            <Grid>
              <Grid className={csstyle.help_line}>
                <Grid container>
                  <Grid item sm={6} xs={12}>
                    <div
                      className={csstyle.login_logo}
                      onClick={() => {
                        props.history.push("/NewHome");
                      }}
                    >
                      <img src="./images/talgiving-logo-black.svg" alt="" />
                    </div>
                  </Grid>
                  <Grid item sm={6} xs={12}>
                    <div className={csstyle.right_trouble}>
                      <p>
                        Having troubles?{" "}
                        <a href="#" className="red_text  font-weight-lg">
                          Get Help
                        </a>
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </Grid>

              <Grid className={csstyle.select_app}>
                <h4>Select app category</h4>
                <p>We're here to guide you every step of the way.</p>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid className="white_bg">
        <Grid container>
          <Grid md={12} xs={12}>
            <Grid className={csstyle.login_right}>
              <Grid container className={csstyle.card_box}>
                {appCategorys.map(app => (
                  <Grid item md={3} sm={4} xs={12}>
                    <Grid
                      className={
                        appActive === app.title
                          ? csstyle.image_border +
                            " " +
                            csstyle.image_borderactive
                          : csstyle.image_border
                      }
                      onClick={e => handleClick(e, app)}
                    >
                      <Box className={csstyle.box_line}>
                        <div>
                          <img src={app.image} />
                        </div>
                        <div>
                          <h2 className="font-size-m black_text">
                            {app.title}
                          </h2>
                          <p className>{app.description}</p>
                        </div>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Grid>
            <Grid className={csstyle.continue_btn}>
              <Grid className={csstyle.continue_btn_inner}>
                <Box className={csstyle.button_border}>
                  <Button className="global_btn white_text red_bg">
                    Continue <FiArrowRight />
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
export default SelectAppCategory;
