import { useState } from "react";

// Custom hook to validate a form. Provides a generic changeHandler and submitHandler.
// Form values are validated in the submitHandler.
// submitHandler calls the provided validate function.
// If the form is error free then postValidateFunction is called.

const useFormValidation = (initialState, validate, postValidateFunction) => {
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState({});

  const changeHandler = (event) => {
    event.persist();
    if (event.target.value.length === 13 && event.target.name == "aadhaar") {
      return;
    } else {
      setValues((previousValues) => ({
        ...previousValues,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    const errors = validate(values)|| {};
    setErrors(errors);
    if (Object.keys(errors).length === 0) {
      postValidateFunction();
    }
  };
  return { values, setValues, errors, setErrors, changeHandler, submitHandler };
};

export default useFormValidation;
