import React, {
  useEffect,
  useState,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Button, Typography } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import VerifyPhone from "../common/VerifyPhone";
import CancelIcon from "@material-ui/icons/Cancel";
import "react-phone-number-input/style.css";
import toastr from "toastr";
import { appContext } from "../../App";
import { changeMobileNumber } from "../../utils/api";
import InputAdornment from "@material-ui/core/InputAdornment";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import EditIcon from "@material-ui/icons/Edit";
import { Tooltip } from "@material-ui/core";

const PhoneNumber = forwardRef((props, ref) => {
  const { authUser, setAuthUser, userLocaleInfo } = useContext(appContext);
  const [phoneError, setPhoneError] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(false);
  const [showVerifyPhone, setShowVerifyPhone] = useState(false);
  const [phoneEnableEdit, setPhoneEnableEdit] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");

  const handleEditNumber = () => {
    setPhoneEnableEdit(true);
    props.setIsPhoneVerified && props.setIsPhoneVerified(false);
  };

  useImperativeHandle(ref, () => ({
    isValid() {
      if (
        newPhoneNumber === undefined ||
        !isValidPhoneNumber(newPhoneNumber) ||
        !newPhoneNumber
      ) {
        setPhoneError("Enter valid phone number");
        props.setPhoneError && props.setPhoneError("Enter valid phone number");
        return false;
      } else if (!phoneVerified || isDirty()) {
        setPhoneError("Please verify the mobile number");
        props.setPhoneError &&
          props.setPhoneError("Please verify the mobile number");
        return false;
      }
      setPhoneError("");
      props.setPhoneError && props.setPhoneError("");
      props.setIsPhoneVerified && props.setIsPhoneVerified(true);
      return true;
    },
    validateOnly() {
      if (
        newPhoneNumber === undefined ||
        !isValidPhoneNumber(newPhoneNumber) ||
        !newPhoneNumber
      ) {
        setPhoneError("Enter valid phone number");
        props.setPhoneError && props.setPhoneError("Enter valid phone number");
        return false;
      }
      setPhoneError("");
      props.setPhoneError && props.setPhoneError("");
      props.setIsPhoneVerified && props.setIsPhoneVerified(true);
      return true;
    },
    phoneNumber() {
      return newPhoneNumber;
    },
  }));

  const handleVerifyPhone = (phone) => {
    const obj = {};
    obj.userId = authUser && authUser.unique_id;
    obj.phone = phone;
    if (phoneVerified && !isDirty()) {
      toastr.success("Phone Number " + phone + " is already verified ");
      setPhoneEnableEdit(false);
      props.setIsPhoneVerified && props.setIsPhoneVerified(true);

      return;
    }
    if (!isValidPhoneNumber(phone) || !phone) {
      setPhoneError("Enter valid phone number");
      props.setPhoneError && props.setPhoneError("Enter valid phone number");
    } else {
      setPhoneError("");
      props.setPhoneError && props.setPhoneError("");
      setPhoneVerified(false);
      props.setIsPhoneVerified && props.setIsPhoneVerified(false);
      changeMobileNumber(obj)
        .then((response) => {
          if (response && response.data.statusCode === 200) {
            toastr.success(response.data.message);
            setShowVerifyPhone(true);
            setPhoneEnableEdit(false);
            setAuthUser((currentValues) => {
              return {
                ...currentValues,
                phone: phone,
              };
            });
            localStorage.setItem(
              "authUser",
              JSON.stringify({
                ...authUser,
                phone: phone,
                phone_verified: false,
              })
            );
          }
        })
        .catch((error) => {
          toastr.error(error.response.data.message);
          if (
            error &&
            error.response &&
            error.response.data &&
            error.response.data.message.includes(
              `Your number (${phone}) is already verified`
            )
          ) {
            setPhoneVerified(true);
            setPhoneEnableEdit(false);
            props.setIsPhoneVerified(true);
          }
        });
    }
  };

  const onCloseVerifyPhoneModal = () => {
    setShowVerifyPhone(false);
  };

  useEffect(() => {
    setNewPhoneNumber(props.phone);
  }, [props.phone]);

  useEffect(() => {
    if (props) {
      setPhoneVerified(props.isPhoneVerified);
      if (props.isPhoneVerified) {
        setPhoneEnableEdit(false);
      } else {
        setPhoneEnableEdit(true);
      }
    }
  }, [props.isPhoneVerified]);

  useEffect(() => {
    if (phoneEnableEdit) {
      if (!newPhoneNumber) {
        props.setPhoneError &&
          props.setPhoneError("Please enter Mobile Number");
      } else if (!isValidPhoneNumber(newPhoneNumber)) {
        props.setPhoneError &&
          props.setPhoneError("Please enter valid Mobile Number");
      } else {
        props.setPhoneError && props.setPhoneError("");
      }
    }
  }, [newPhoneNumber !== props.phone]);

  const isDirty = () => newPhoneNumber !== props.phone;

  return (
    <div>
      <div className="userprofileFormBlk">
        <FormControl
          className="full-width border-radius"
          style={{ width: "100%" }}
        >
          <PhoneInput
            className="full-width border-radius profile-field"
            placeholder="Enter phone number"
            disabled={!phoneEnableEdit}
            countryCallingCodeEditable={false}
            international={props.supportedInIndia}
            defaultCountry={
              props.supportedInIndia
                ? "IN"
                : userLocaleInfo && userLocaleInfo.data.country_code
            }
            value={newPhoneNumber}
            onChange={setNewPhoneNumber}
            style={{
              width: "100%",
              border: "1px solid #ccc",
              borderRadius: "5px",
              paddingLeft: "10px",
              borderLeft: "3px solid #f44336",
              height: "53px",
            }}
          />
          {props.supportedInIndia && (
            <style>
              {`
            .PhoneInputCountrySelect {
              pointer-events: none;
            }
          `}
            </style>
          )}
          {phoneError && (
            <Typography
              className="custom-error"
              style={{ border: "none", marginTop: "10px" }}
            >
              {phoneError}
            </Typography>
          )}
          {authUser && authUser.unique_id && (
            <InputAdornment>
              <span
                aria-label="toggle password visibility"
                style={{
                  top: "1px",
                  position: "absolute",
                  padding: "0",
                  right: "0",
                }}
                edge="end"
              >
                {!phoneEnableEdit && phoneVerified ? (
                  <>
                    <Tooltip title="Verified">
                      <VerifiedUser
                        style={{
                          color: "green",
                          size: "large",
                          backgroundColor: "white",
                          paddingRight: " 2px",
                          fontSize: "20px",
                        }}
                      />
                    </Tooltip>
                    <Button
                      style={{
                        background: "var(--tal_primary)",
                        height: "52px",
                        minWidth: "45px",
                        border: "none",
                        color: "white",
                        fontSize: "12px",
                        display: "inline-block",
                        padding: "0",
                        margin: "0",
                        borderRadius: "4px",
                        position: "relative",
                        top: "-1px",
                        lineHeight: "30px",
                      }}
                      onClick={handleEditNumber}
                    >
                      <EditIcon />
                      <p style={{ color: "white" }}>Edit</p>
                    </Button>
                  </>
                ) : (
                  <>
                    {!phoneVerified && (
                      <Tooltip title="Not Verified">
                        <CancelIcon style={{ color: "red", size: "large" }} />
                      </Tooltip>
                    )}
                    <Button
                      style={{
                        background: "rgb(252, 105, 35)",
                        height: "52px",
                        width: "60px",
                        border: "none",
                        color: "white",
                        fontSize: "14px",
                        padding: "0",
                        margin: "0",
                        borderRadius: "4px",
                        position: "relative",
                        top: "-1px",
                      }}
                      onClick={() => handleVerifyPhone(newPhoneNumber)}
                    >
                      VERIFY
                    </Button>
                  </>
                )}
              </span>
            </InputAdornment>
          )}
        </FormControl>
      </div>
      <VerifyPhone
        openVerifyPhoneModal={showVerifyPhone}
        onCloseModal={onCloseVerifyPhoneModal}
        phoneNumber={newPhoneNumber}
        userId={authUser && authUser.unique_id}
        setPhoneVerified={setPhoneVerified}
        fromUserProfile={true}
      />
    </div>
  );
});

export default PhoneNumber;
