import React from "react";
import Slider from "react-slick";
import csstyle from "./Testimonial.module.scss";
const Testimonial = (props) => {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    arrows:false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <>
   <Slider {...settings} className={csstyle.testi_slide}>
         <div>
          <div className={csstyle.testi_card}>
            <div className={csstyle.black_quote}>
              <img src="./images/black_quote.svg"/>
            </div>
            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
            <div className={csstyle.testi_user}>
              <div><img src="./images/test_avthar.png"/></div>
              <div className={csstyle.testi_title}>Darrell Steward</div>
            </div>
          </div>
          </div>
          <div>
          <div className={csstyle.testi_card}>
            <div className={csstyle.black_quote}>
              <img src="./images/black_quote.svg"/>
            </div>
            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
            <div className={csstyle.testi_user}>
              <div><img src="./images/test_avthar.png"/></div>
              <div className={csstyle.testi_title}>Darrell Steward</div>
            </div>
          </div>
          </div>
          <div>
          <div className={csstyle.testi_card}>
            <div className={csstyle.black_quote}>
              <img src="./images/black_quote.svg"/>
            </div>
            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
            <div className={csstyle.testi_user}>
              <div><img src="./images/test_avthar.png"/></div>
              <div className={csstyle.testi_title}>Darrell Steward</div>
            </div>
          </div>
          </div>
          <div>
          <div className={csstyle.testi_card}>
            <div className={csstyle.black_quote}>
              <img src="./images/black_quote.svg"/>
            </div>
            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
            <div className={csstyle.testi_user}>
              <div><img src="./images/test_avthar.png"/></div>
              <div className={csstyle.testi_title}>Darrell Steward</div>
            </div>
          </div>
          </div>
          <div>
          <div className={csstyle.testi_card}>
            <div className={csstyle.black_quote}>
              <img src="./images/black_quote.svg"/>
            </div>
            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
            <div className={csstyle.testi_user}>
              <div><img src="./images/test_avthar.png"/></div>
              <div className={csstyle.testi_title}>Darrell Steward</div>
            </div>
          </div>
          </div>
          <div>
          <div className={csstyle.testi_card}>
            <div className={csstyle.black_quote}>
              <img src="./images/black_quote.svg"/>
            </div>
            <p>Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. Velit officia consequat duis enim velit mollit. Exercitation veniam consequat sunt nostrud amet.</p>
            <div className={csstyle.testi_user}>
              <div><img src="./images/test_avthar.png"/></div>
              <div className={csstyle.testi_title}>Darrell Steward</div>
            </div>
          </div>
          </div>
          
        </Slider>
    </>
  );
};
export default Testimonial;
