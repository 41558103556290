import React from "react";
import Hidden from "@material-ui/core/Hidden";
import { Box, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import "./TALEvent.scss";
const useStyles = makeStyles((theme) => ({
  sign_left: {
    padding: "2rem 5rem",
    backgroundImage: `url(${"/images/tal-kindness-banner.jpg"})`,
    backgroundRepeat: "no-repeat",
    minHeight: "100%",
    backgroundSize: "cover",

    showpassword: {
      backgroundColor: "var(--tal_primary)",
    },

    "& article": {

      "& p": {
        color: "white",
        lineHeight: "2rem",
        maxWidth: "75%",
      },

      "& figure:nth-child(2)": {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
  },
}));

const LogDesign = (props) => {
  const classes = useStyles();

  return (

    <Box component="section" className="sign_left">
      <Container>
        <Box component="figure" pb={2}>
          <img
            src="/images/tal-logo-black-event.png"
            style={{ width: "190px", height: "46px" }}
          />
        </Box>

        <Box component="article">
          {/* <Box component="figure">
            <img src="/images/new_web_images/login_cont_arrow_img.svg" />
          </Box> */}
          <Box className="wkdTitle">
            TAL <span>Kindness Day 2023</span>
          </Box>
          <Box className="eventText">
            A platform to celebrate, inspire, and amplify kindness, bringing
            together diverse change agents. Experience the transformative power
            of kindness.
          </Box>
          <Box className="eventIcons">
            <Box>
              <CalendarTodayIcon />
              Nov 18, 2023
            </Box>
            <Box>
              <LocationOnIcon />
              585 Mowry Ave, Fremont, CA 94536
            </Box>
          </Box>
          {/* <Box component="p">
            TAL Kindness Day celebrates human civilization's powerful essence of
            kindness. TAL World Kindness Day stands as a beacon of hope for a
            kinder world and a better future in a world often overshadowed by
            chaos and despair. Aligned with the vision of Touch A Life, this
            event brings together minds to create a positive impact,
            acknowledging achievements, addressing social issues, and inspiring
            solutions. Join us in this Kindness Revolution as we strive for a
            brighter tomorrow.
          </Box> */}

          {/* <Box component="p">- Albert Schweitzer</Box> */}

          {/* <Box component="figure" textAlign="right">
            <img src="/images/new_web_images/login_cont_white_img.svg" />
          </Box> */}
        </Box>
        {/* <Box
          component="p"
          style={{
            color: "#fff",
            position: "absolute",
            bottom: "1%",
            fontSize: "1.1rem",
          }}
        >
          Powered by Touch-A-Life
        </Box> */}
      </Container>
    </Box>

  );
};
export default LogDesign;
