import React from "react";
import Hidden from '@material-ui/core/Hidden';
import { Grid, Box,Button} from "@material-ui/core";
import csstyle from "./GetStarted.module.scss";
import {FiArrowRight } from "react-icons/fi";
const GetStarted = (props) => {
  return (
    <>
    <Grid container>
      <Hidden smDown>
            <Grid item md={4} sm={12} xs={12} style={{backgroundColor: "var(--lightbg-color)"}} >
                <Grid className={csstyle.login_left}>
                    <Grid>
                  <Grid className={csstyle.login_logo}
                    onClick={() => {
                      props.history.push("/NewHome");
                    }}
                    >
                    <img src="./images/talgiving-logo-black.svg" alt=""/>
                    </Grid>
                    <Grid className={csstyle.login_head}>
                        <h3>Let's get started</h3>
                        <p>We're here to guide you every step <br/> of the way.</p>
                    </Grid>
                    </Grid>
                </Grid>
                <Grid className={csstyle.login_footer}>
                        <img src="./images/rocket-icon.svg" alt=""/>
                    </Grid>
            </Grid>
          </Hidden>
            <Grid item md={8} sm={12} xs={12} style={{background:"#fff"}}>
            
            <Grid className={csstyle.login_right }>
                <Hidden mdUp>
                  <Grid className={csstyle.login_smlogo}
                      onClick={() => {
                        props.history.push("/NewHome");
                      }}
                      >
                      <img src="./images/talgiving-logo-black.svg" alt=""/>
                      </Grid>
                      </Hidden>
                      <p className="text-right font-size-m font-weight-lg light_text">Having troubles? <a href="#" className="red_text ">Get Help</a></p>
                      <Grid className={csstyle.willing_img}>
                      <Grid className="mt-5">
                      <h4 className="font-size-lg black_text">Hello! What brings you to TALGiving?</h4>
                      <p className="light_text">Connect with our community of mentors and users from 141+ <br/>countries around the world.</p>
                      </Grid>
                      <Grid container className={csstyle.card_box + " " +"mt-5 "}>
                          <Grid item md={6} sm={6} xs={12} className={csstyle.image_border}>
                            <Box>
                              <img src="./images/need-icon.svg"/> 
                            </Box>
                            <h5 className="font-size-m black_text">I’m in need of..</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                          </Grid>
                          <Grid item  md={6} sm={6} xs={12} className={csstyle.image_border}>
                            <Box>
                              <img src="./images/willing-icon.svg"/>
                            </Box>
                            <h5 className="font-size-m black_text">I’m willing to give</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting .</p>
                          </Grid>
                          
                      </Grid>
                      </Grid>
                         <Grid className="mt-5">
                          <Box className={csstyle.button_border}>
                          <Button className="global_btn white_text grey_ultralight">Continue <FiArrowRight/></Button>
                            
                          </Box>
                          </Grid>
                  </Grid>
              </Grid>
            
        </Grid>
    </>
  );
};

export default GetStarted;
