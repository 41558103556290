import React from "react";
import cssstyles from "./NewFilters.module.scss";
import { Box, Button, Chip, Divider, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const NewFilters = (props) => {
  return (
    <div>
      <div>
        <div className={cssstyles.Fade_style}>
          {/* <h1 className={classes.orgTitle}>TALHero profile</h1> */}
          <div className={cssstyles.filters_text}>
            <span>Filter</span>
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          ></div>

          <div className={cssstyles.Veiw_align}>
            <CloseIcon />
          </div>
        </div>

        {/* <div className={cssstyles.Veiw_align}>
          <CloseIcon />
        </div> */}
        <div className={cssstyles.category_chips}>
          <div>
            <Box className={cssstyles.category_txt}>
              <span>Category</span>
            </Box>
            <Box className={cssstyles.chips_style}>
              <Chip className={cssstyles.chip_txt} label="Fundraisers"></Chip>
              <Chip className={cssstyles.chip_txt} label="Career Help"></Chip>
              <Chip className={cssstyles.chip_txt} label="Scholarship"></Chip>
              <Chip className={cssstyles.chip_txt} label="Internship"></Chip>
              <Chip className={cssstyles.chip_txt} label="Volunteering"></Chip>
              <Chip className={cssstyles.chip_txt} label="Mentoring"></Chip>
              <Chip className={cssstyles.chip_txt} label="Fundraisers"></Chip>
              <Chip className={cssstyles.chip_txt} label="Fundraisers"></Chip>
            </Box>
            <Divider style={{ margin: "24px 0px" }} />
          </div>
          <div>
            <Box className={cssstyles.category_txt}>
              <span>Sub-Category</span>
            </Box>
            <Box className={cssstyles.chips_style}>
              <Chip className={cssstyles.chip_txt} label="Health"></Chip>
              <Chip className={cssstyles.chip_txt} label="Animals"></Chip>
              <Chip className={cssstyles.chip_txt} label="Hunger Free"></Chip>
              <Chip className={cssstyles.chip_txt} label="Women & Girl"></Chip>
              <Chip className={cssstyles.chip_txt} label="Environment"></Chip>
            </Box>
            <Divider style={{ margin: "24px 0px" }} />
          </div>
          <div>
            <Box className={cssstyles.category_txt}>
              <span>Location</span>
            </Box>
            <Box className={cssstyles.chips_style}>
              <Chip className={cssstyles.chip_txt} label="India"></Chip>
              <Chip className={cssstyles.chip_txt} label="USA"></Chip>
            </Box>
            <Divider style={{ margin: "24px 0px" }} />
          </div>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems:"center",
              }}
            >
              <Box className={cssstyles.category_txt}>
                <span>TAX Benefit Based Requests</span>
              </Box>
              <div class="toggle-group">
                <input
                  type="checkbox"
                  name="on-off-switch"
                  id="on-off-switch"
                  tabindex="1"
                />
                <label for="on-off-switch">
                  <span class="aural">Show:</span>
                </label>
                <div class="onoffswitch pull-right" aria-hidden="true">
                  <div class="onoffswitch-label">
                    <div class="onoffswitch-inner"></div>
                    <div class="onoffswitch-switch"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={cssstyles.edit_reject_Short_btn}>
          <div className={cssstyles.reject_color}>
            <Button> Reset</Button>
          </div>
          <div className={cssstyles.Approve_color}>
            <Button>Apply</Button>
          </div>
        </div>
      </div>{" "}
    </div>
  );
};
export default NewFilters;
