import React, { useContext, useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { fade, makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import AddCircleOutlineRoundedIcon from "@material-ui/icons/AddCircleOutlineRounded";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "@material-ui/core";
import { faRupeeSign, faDollarSign } from "@fortawesome/free-solid-svg-icons";
import InputBase from "@material-ui/core/InputBase";
import moment from "moment";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import TopSnackbar from "../Snackbar/Snackbar";
import ExpandMoreOutlinedIcon from "@material-ui/icons/ExpandMoreOutlined";
import Menu from "@material-ui/core/Menu";
import HomeOutlinedIcon from "@material-ui/icons/HomeOutlined";
import Grid from "@material-ui/core/Grid";
import Organization from "../Organization/Organization";
import AllInboxIcon from "@material-ui/icons/AllInbox";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import Typography from "@material-ui/core/Typography";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import CloseIcon from "@material-ui/icons/Close";
import {
  SearchRounded,
  AccountCircleRounded,
  AvTimerTwoTone,
  AccountCircleOutlined,
  RadioOutlined,
  MonetizationOnOutlined,
  SupervisorAccountOutlined,
  YouTube,
  Subject,
} from "@material-ui/icons";
import MenuIcon from "@material-ui/icons/Menu";
import toastr from "toastr";
import PaymentsPopUp from "../donationDetails/PaymentsPopUp";
import { appContext } from "../../App";
import {
  HOME_PAGE,
  formatScore,
  AVATAR_DONOR,
  AVATAR_DONEE,
  TALMEDIA_PAGE,
  DATE_TIME_FORMAT,
  numberWithCommas,
} from "../../utils/utils";
import "./Topbar.scss";
import { updateNotification, getUserNotifications } from "../../utils/api";
import { Hidden } from "@material-ui/core";
import { isAndroid, isIOS } from "react-device-detect";
import RecordVoiceOverOutlinedIcon from "@material-ui/icons/RecordVoiceOverOutlined";
//import Menubar from "react-responsive-multi-level-menu";
/*menu*/
import Toolbar from "@material-ui/core/Toolbar";
import Drawer from "@material-ui/core/Drawer";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import MenuList from "@material-ui/core/MenuList";
import ReactGA from "react-ga4";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import PersonOutlineOutlinedIcon from "@material-ui/icons/PersonOutlineOutlined";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import BookIcon from "@material-ui/icons/Book";
/*menu*/
const useStyles = makeStyles((theme) => ({
  rootli: {
    width: "100%",
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  menuButton: {
    "& img": {
      width: "250px",
      [theme.breakpoints.down("xs")]: {
        width: "50px",
      },
    },
  },
  grow: {
    flexGrow: 1,
    position: "sticky",
    top: 0,
    width: "100%",
    zIndex: 2,
    marginBottom: "25px",
  },
  title: {
    display: "none",
    [theme.breakpoints.up("sm")]: {
      display: "block",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalpaper: {
    backgroundColor: theme.palette.background.paper,
    width: 550,
    borderRadius: 10,
    textAlign: "center",
    height: 510,
  },
  modalImage: {
    width: 650,
    position: "relative",
    right: 50,
    top: 10,
    margin: "25px 0",
  },
  modalTitle: {
    font: "500 24px Roboto",
    padding: 15,
  },
  modalDesc: {
    font: "400 18px Roboto",
    padding: 15,
    marginTop: 0,
    color: "#555",
    paddingTop: 0,
  },
  modalButton: {
    width: "100%",
    background: "var(--tal_primary) ",
    color: "#fff",
    border: "none",
    padding: "10px 20px",
    borderRadius: 5,
    fontSize: 18,
  },
  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    "&:hover": {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: 212,
    height: 29,
    top: "25px",

    [theme.breakpoints.down("sm")]: {
      width: "100%",
      top: "-10px",

      MuiInputBaseRoot: {
        width: "100%",
      },
    },

    add_menu: {
      [theme.breakpoints.down("sm")]: {
        padding: "12px 0 12px 0",
      },
    },
  },
  searchIcon: {
    // padding: theme.spacing(0, 2),
    // height: "100%",
    position: "absolute",
    pointerEvents: "none",
    zIndex: 1,
    color: "#aaa",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "7px 6px",
  },
  inputRoot: {
    color: "inherit",
    width: "100%",
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: "calc(1em + 15px)",
    transition: theme.transitions.create("width"),
    width: "100%",
    background: "white",
    height: 19,
    borderRadius: 2,
    [theme.breakpoints.up("md")]: {
      width: "145px",
    },
  },
  sectionDesktop: {
    display: "none",
    [theme.breakpoints.up("md")]: {
      display: "flex",
      width: "100%",
      justifyContent: "flex-end",
    },
    [theme.breakpoints.down("md")]: {
      borderTop: "1px solid #707070",
    },
  },
  sectionMobile: {
    display: "flex",
    // margin: "12px 0",
    color: "white",
    position: "relative",
    right: " 0",

    [theme.breakpoints.down("sm")]: {
      flexDirection: "row-reverse",
    },

    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },

  slid_menu: {
    gap: "5px",
    display: "flex",
    padding: " 5px ",
    borderRadius: "50px",
    backgroundColor: "#f1f1f1",
    minWidth: "100%",

    "& Button": {
      padding: "5px 10px",
      border: "none",
      borderRadius: "50px",
      width: "100%",
    },
  },
  mobile_menu: {
    "& li": {
      minHeight: "38px",
    },
    "& p": {
      margin: "0",
    },
  },
}));

const Topbar = (props) => {
  const {
    authUser,
    setAuthUser,
    regions,
    hideLoginButton,
    region,
    setRegion,
    avatars,
    avatar,
    setAvatar,
    userSummary,
    userNotifications,
    setUserNotifications,
    isTALRadioAdmin,
    isSuperAdmin,
    isAdmin,
    isFinAdmin,
    setTalMediaType,
    orgList,
    contacts,
    userScholarshipRequests,
  } = useContext(appContext);
  const classes = useStyles();
  const [radioMenu, setRadioMenu] = useState("");

  const testFunction = () => {
    alert("welcome");
  };

  const handleMyRequests = () => {
    hideMyProfile();
    setRadioMenu("");
    setAvatar(AVATAR_DONEE);
    props.history.push(HOME_PAGE);
  };
  const handleMyDonations = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push("/myDonations");
  };
  const handleMyWithdrawals = () => {
    setRadioMenu("");
    hideMyProfile();
    props.history.push("/myWithdrawals");
  };

  const handleMyOrganizations = () => {
    setRadioMenu("");
    hideMyProfile();
    props.history.push("/myOrganizations");
  };
  const handleContactsGroups = () => {
    setRadioMenu("");
    hideMyProfile();
    props.history.push("/myContactsandGroups");
  };
  const handleMyApplications = () => {
    setRadioMenu("");
    hideMyProfile();
    props.history.push("/myApplications");
  };
  const createAudioRoom = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push("/createAudioRoom");
  };

  const viewAudioRooms = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push("/chatTabs");
  };

  const showFavourites = () => {
    hideMyProfile();
    setRadioMenu("");
    props.history.push("/myfavourites");
  };

  const handleOrganization = () => {
    setRadioMenu("");
    setOpenOrgModal(true);
  };

  const onSavingOrg = (orgId) => {
    setOpenOrgModal(false);
    setRadioMenu("");
    props.history.push(`/organization/${orgId}`);
  };

  const verifyBankAccount = () => {
    setOpenOrgModal(false);
    setRadioMenu("");
    window.location.href = `${process.env.REACT_APP_BASE_URL}/newUserProfile/${authUser.unique_id}#bank-section`;
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [isPaymentsPopUpOpen, setIsPaymentsPopUpOpen] = useState(false);
  const [switchAVatar, setSwitchAvatar] = useState(false);
  const [myProfile, setMyProfile] = useState(false);
  const [showIcons, setShowIcons] = useState(true);
  const [active, setActive] = useState("avatar");
  const [showNotification, setShowNotification] = useState(false);
  const [showTopbar, setShowTopbar] = React.useState(false);
  const [isRegionSelected, setIsRegionSelected] = useState(
    localStorage.getItem("isRegionSelected")
  );
  const [donor, setDonor] = useState("");
  const [donee, setDonee] = useState("active");
  const [expandApps, setExpandApps] = useState(false);
  const [openOrgModal, setOpenOrgModal] = useState(false);
  const [myRegion, setMyRegion] = React.useState(false);
  const [openMobile, setOpenMobile] = React.useState(true);
  const [totalRequestCount, setTotalRequestCount] = useState(0);
  const wrapperRef = useRef(null);
  const [donateToTALClicked, setDonateToTALClicked] = useState(false);

  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          hideMyProfile();
          hideSwitchAvatar();
          hideMyRegion();
          hideNotification();
          hideApps();
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(wrapperRef);

  const pathname = window.location.pathname;
  useEffect(() => {
    if (pathname === "/publicPayment") {
      setShowTopbar(true);
    }
  }, [pathname]);

  useEffect(() => {
    if (
      pathname === "/StripePayment" ||
      pathname === "/thankyou" ||
      pathname === "/publicPayment" ||
      pathname === "/billingInfo" ||
      pathname === "/RazorPayment"
    ) {
      setDonateToTALClicked(true);
      setShowIcons(false);
    } else {
      setDonateToTALClicked(false);
      setShowIcons(true);
    }
  });

  useEffect(() => {
    if (
      pathname.includes("/createAudioRoom") ||
      pathname.includes("/chatTabs")
    ) {
      setActive("audio");
    } else if (pathname.includes("/talmedia")) {
      setActive("radio");
    } else if (
      pathname === "/new-request" ||
      pathname === "/internship-request" ||
      pathname === "/volunteering-request" ||
      pathname === "/plasma-donee-donor" ||
      pathname === "/careerhelp-request" ||
      pathname === "/covidhelp-request" ||
      pathname === "/boardmember-request" ||
      pathname === "/podcast-request" ||
      pathname === "/scholarship-request" ||
      pathname === "/bloodDonation-request"
    ) {
      setActive("plus");
    } else if (
      pathname.includes("/newUserProfile") ||
      pathname.includes("/user") ||
      pathname.includes("/users/help-desk") ||
      pathname.includes("/users/feedback")
    ) {
      setActive("profile");
    } else if (pathname.includes("/notifications")) {
      setActive("notification");
    } else {
      setActive("avatar");
    }
  }, [window.location.pathname]);

  const handleSwitchAvatar = () => {
    setActive("avatar");
    setSwitchAvatar(true);
    hideMyProfile();
    hideMyRegion();
    hideApps();
    hideNotification();
  };

  const handleExpandApps = () => {
    setActive("plus");
    setExpandApps(true);
    hideMyProfile();
    hideSwitchAvatar();
    hideMyRegion();
    hideNotification();
  };

  const hideApps = () => {
    setExpandApps(false);
  };

  useEffect(() => {
    if (userSummary) {
      let total = 0;
      userSummary &&
        userSummary.myApplications &&
        userSummary.myApplications.requestTypes &&
        userSummary.myApplications.requestTypes.forEach((requestType) => {
          if (
            ["scholarship", "career", "help", "bloodDonation"].includes(
              requestType._id
            )
          ) {
            total += requestType.count;
          }
        });
      setTotalRequestCount(total);
    }
  }, [userSummary]);

  const handleTalRadio = () => {
    setMyProfile(false);
    setActive("radio");
    hideSwitchAvatar();
    hideMyRegion();
    hideApps();
    hideNotification();
    props.history.push(TALMEDIA_PAGE);
  };

  const handleMyProfile = () => {
    setActive("profile");
    setMyProfile(true);
    hideSwitchAvatar();
    hideMyRegion();
    hideApps();
    hideNotification();
  };

  const handleDonationType = (type) => {
    if (type === "cash" && donor === "active") {
      ReactGA.event({
        category: "Menu",
        action: "home_donor",
        label: "Home Donor*",
        value: 1,
      });
      setAvatar(AVATAR_DONOR);
      props.history.push(HOME_PAGE);
    } else if (type === "cash" && donee === "active") {
      ReactGA.event({
        category: "Menu",
        action: "home_donee",
        label: "Home Donee",
        value: 1,
      });
      if (authUser && authUser.unique_id) {
        setAvatar(AVATAR_DONEE);
      }
      setMyProfile(false);
      props.history.push("/new-request");
    }
    if (type === "plasma" && donor === "active") {
      props.history.push("/plasma-donor-request");
    } else if (type === "plasma" && donee === "active") {
      props.history.push("/plasma-donee-donor");
    }
    if (type === "careerhelp") {
      props.history.push("/careerhelp-request");
    }
    if (type === "internship") {
      props.history.push("/internship-request");
    }
    if (type === "volunteering") {
      props.history.push("/volunteer-request");
    }
    if (type === "covidhelp") {
      props.history.push("/covidhelp-request");
    }
    if (type === "boardmember") {
      props.history.push("/boardmember-request");
    }
    if (type === "podcast") {
      props.history.push("/podcast-request");
    }
    if (type === "scholarship") {
      props.history.push("/scholarship-request");
    }
    if (type === "eventparticipation") {
      props.history.push("/eventspeaker-request");
    }
    if (type === "mentoring") {
      props.history.push("/mentoring-request");
    }
    if (type === "bloodDonation") {
      props.history.push("/bloodDonation-request");
    }
    hideApps();
  };

  const notification = () => {
    setActive("notification");
    setShowNotification(true);
    hideSwitchAvatar();
    hideMyRegion();
    hideMyProfile();
    hideApps();
  };

  const hideMyProfile = () => {
    setMyProfile(false);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      props.history.push(`/search/${event.target.value}`);
    }
  };

  const hideMyRegion = () => {
    setMyRegion(false);
  };

  const hideNotification = () => {
    setShowNotification(false);
  };

  const regionChangeHandler = (reg) => {
    setRegion(regions.find((region) => reg._id === region._id));
    setMyRegion(false);
  };

  const handleStartGiving = () => {
    setDonateToTALClicked(true);
    if (authUser) {
      ReactGA.event({
        category: "Button",
        action: "donate_tal",
        label: "Donation",
        value: 1,
      });
      setIsPaymentsPopUpOpen(true);
    } else {
      props.history.push(
        `/publicPayment?donationfor=tal&currency=${region && region.currency}`
      );
    }
    setMyProfile(false);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const hideSwitchAvatar = () => {
    setSwitchAvatar(false);
  };

  const handleAvatar = (avatar) => {
    setActive("avatar");
    setAvatar(avatar);
    setSwitchAvatar(false);
    props.history.push(HOME_PAGE);
    window.scrollTo(0, 0);
  };

  const handleMyRegion = () => {
    setActive("region");
    setMyRegion(true);
    hideMyProfile();
    hideApps();
    hideSwitchAvatar();
    hideNotification();
  };

  const logout = () => {
    ReactGA.event({
      category: "Menu",
      action: "logout",
      label: "Logout",
      value: 1,
    });
    setAvatar(AVATAR_DONOR);
    setAnchorEl(null);
    setAuthUser(null);
    localStorage.removeItem("authUser");
    localStorage.clear();
    window.location.reload();
    props.history.push(HOME_PAGE);
  };

  const handleMyProfileOptions = (option) => {
    setMyProfile(false);
    hideMyProfile();
    option === "edit" &&
      props.history.push(`/newUserProfile/${authUser.unique_id}`);
    option === "public" && window.open(`/user/${authUser.username}`);
    option === "helpdesk" && props.history.push("/users/help-desk");
    option === "feedback" && props.history.push("/users/feedback");
    option === "logout" && logout();
    option === "organization" && handleOrganization();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleNewRequest = () => {
    setMyProfile(false);
    setActive("fundraise");
    if (authUser) {
      props.history.push("/new-request");
    } else {
      toastr.warning("Please signup or login to create a fundraiser.");
      props.history.push("/login");
    }
  };

  const showAllNotifications = () => {
    setShowNotification(false);
    props.history.push("/notifications");
  };

  const showNotificationCount = (notiLength) => {
    let arrayCount = [];
    notiLength.map((data) => {
      if (data.status === 0) {
        arrayCount.push(data);
      }
      return null;
    });
    return arrayCount.length;
  };

  const updateNotificationStatus = (data) => {
    if (data && data.status === 0 && authUser) {
      updateNotification(authUser.unique_id, data._id)
        .then((response) => {
          if (response) {
            // hideNotification();
            // props.history.push(`/notifications`);
            getUserNotifications(authUser.unique_id)
              .then((response) => {
                setUserNotifications(response);
              })
              .catch((error) => {
                toastr.error(error.data.message);
              });
          }
        })
        .catch((error) => {
          toastr.error(error.data.message);
        });
    }
  };
  /*menu*/
  const [drawerstate, setDrawerstate] = React.useState(false);

  const toggleDrawer = (state, isopen) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setDrawerstate({ ...state, right: isopen });
  };
  /*menu*/
  return (
    <div className={classes.grow} ref={wrapperRef}>
      {/* {!isRegionSelected && !showTopbar && (
        <TopSnackbar setIsRegionSelected={setIsRegionSelected} />
      )} */}
      {/* <div className="event_bg">
    <a href="/tal-kindness-day-2023" target="_blank">
      <Container>
        <Grid container className="top_scroll">
          <Grid item lg={10} md={10} sm={9} xs={8} >
          <marquee behavior="scroll" direction="left">TAL Kindness Day 2023: Witness the kindness revolution in action! </marquee>
          </Grid>
          <Grid item lg={2} md={2} sm={3} xs={4}>
          <Grid className="top_scroll_button">
            
            <span>Register now!</span>
            <img src="/images/arrow-icon.png"/>
            </Grid>
          </Grid>
        </Grid>
        </Container>
        </a>
    </div> */}
      <AppBar
        position="static"
        style={{
          background: "#283E4A",
          // height: 51,
          boxShadow: "none",
          zIndex: 1,
          position: "sticky",
          top: "0",
        }}
      >
        {(isAndroid || isIOS) && (
          <Hidden mdUp>
            <Collapse in={openMobile}>
              <Box component="section" className="app-dn-icon">
                <Box component="div" display="flex">
                  <Box component="div" flexDirection="column" pl={1}>
                    <Box
                      component="p"
                      alignSelf="centre"
                      fontSize="14px"
                      fontWeight="600"
                      color="var(--tal_primary)"
                      m={0}
                    >
                      Download TALGiving App
                    </Box>
                  </Box>

                  <Box component="div" alignSelf="centre">
                    {isAndroid ? (
                      <Box
                        component="a"
                        color="white"
                        href="https://play.google.com/store/apps/details?id=com.touchalife.app"
                      >
                        <img
                          src="/images/playstore-1.png"
                          alt="TALGiving Android App"
                          className="app-icon-mobi"
                        />
                      </Box>
                    ) : isIOS ? (
                      <Box
                        color="white"
                        component="a"
                        href="https://apps.apple.com/in/app/touchalife/id1446609985"
                      >
                        <img
                          src="/images/appstore.png"
                          alt="TALGiving ios App"
                          className="app-icon-mobi"
                        />
                      </Box>
                    ) : (
                      ""
                    )}
                  </Box>
                </Box>
                <Box>
                  <IconButton
                    aria-label="close"
                    onClick={() => {
                      setOpenMobile(false);
                    }}
                  >
                    <CloseIcon className="close-btn" />
                  </IconButton>
                </Box>
              </Box>
            </Collapse>
          </Hidden>
        )}

        <Container MaxWidth="md">
          <div className="topbar">
            {/* <Toolbar> */}

            <Grid container style={{ alignItems: "center" }}>
              <Grid
                item
                xs={4}
                style={{ display: "flex", position: "relative" }}
              >
                <IconButton
                  edge="start"
                  className={classes.menuButton}
                  color="inherit"
                  aria-label="open drawer"
                  onClick={() => {
                    props.history.push("/");
                  }}
                  disabled={donateToTALClicked}
                >
                  <Hidden xsDown>
                    <img
                      src="/images/talgiving-logo-white.svg"
                      alt="Touch-A-Life Foundation"
                    />
                  </Hidden>

                  <Hidden smUp>
                    <img
                      src="/images/talgiving-logo-dom.png"
                      alt="Touch-A-Life Foundation"
                    />
                  </Hidden>
                </IconButton>
                {!donateToTALClicked && (
                  <Hidden smDown>
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchRounded />
                      </div>
                      <InputBase
                        placeholder="Search"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        id="topbar-search-mobile"
                        inputProps={{ "aria-label": "search" }}
                        onKeyPress={(e) => handleEnterClick(e)}
                      />
                    </div>
                  </Hidden>
                )}
              </Grid>
              {showIcons && (
                <Grid item xs={8} className="menu-pull">
                  <div className="desktop-view">
                    <div className={classes.sectionDesktop}>
                      {authUser && (
                        <IconButton
                          className={`top-icon-btn ${
                            active === "avatar" && "active"
                          }`}
                          color="inherit"
                          style={{ position: "relative" }}
                        >
                          <p onClick={handleSwitchAvatar}>
                            <HomeOutlinedIcon
                              style={{
                                color:
                                  active === "avatar" ? "#cd2729" : "#ffffff",
                              }}
                            />
                          </p>
                          <p
                            onClick={handleSwitchAvatar}
                            style={{
                              color:
                                active === "avatar" ? "#cd2729" : "#ffffff",
                            }}
                          >
                            {avatar === AVATAR_DONEE
                              ? AVATAR_DONEE
                              : AVATAR_DONOR}{" "}
                            <span>
                              <ExpandMoreOutlinedIcon />
                            </span>
                          </p>
                          {switchAVatar && (
                            <div className="tab-box" style={{ left: "3px" }}>
                              {avatars &&
                                avatars.length > 0 &&
                                avatars.map((avatar) => {
                                  return (
                                    <MenuItem
                                      value={avatar}
                                      onClick={() => handleAvatar(avatar)}
                                    >
                                      {avatar}
                                    </MenuItem>
                                  );
                                })}
                            </div>
                          )}
                        </IconButton>
                      )}
                      <IconButton
                        className={`top-icon-btn ${
                          active === "audio" && "active"
                        }`}
                        color="inherit"
                      >
                        <a href="http://blog.talgiving.org/" target="_blank">
                          <p
                            style={{
                              color: active === "audio" ? "#cd2729" : "#ffffff",
                            }}
                          >
                            <img src="../images/blog-icon.svg" alt="" />
                          </p>
                          <p
                            style={{
                              color: active === "audio" ? "#cd2729" : "#ffffff",
                            }}
                          >
                            TALBlogs
                          </p>
                        </a>
                      </IconButton>
                      <IconButton
                        className={`top-icon-btn ${
                          active === "radio" && "active"
                        }`}
                        color="inherit"
                      >
                        <p>
                          <img
                            src={`/images/${
                              active === "radio"
                                ? "talradio-active"
                                : "talradio-inactive"
                            }.png`}
                            alt="TALMedia"
                            width="100%"
                            onClick={handleTalRadio}
                          />
                        </p>
                        <p
                          style={{
                            color: active === "radio" ? "#cd2729" : "#ffffff",
                          }}
                          onClick={handleTalRadio}
                        >
                          TALMedia
                        </p>
                      </IconButton>

                      <IconButton
                        style={{ padding: "12px 7px", position: "relative" }}
                        className={`top-icon-btn ${
                          active === "plus" && "active"
                        }`}
                        color="inherit"
                      >
                        <p onClick={handleExpandApps}>
                          <AddCircleOutlineRoundedIcon
                            style={{
                              color: active === "plus" ? "#cd2729" : "#ffffff",
                            }}
                          />
                        </p>
                        <p
                          onClick={handleExpandApps}
                          style={{
                            color: active === "plus" ? "#cd2729" : "#ffffff",
                          }}
                        >
                          Add
                          <span style={{ margin: 0 }}>
                            <ExpandMoreOutlinedIcon />
                          </span>
                        </p>
                        {expandApps && (
                          <div className="tab-box apps-cont add">
                            <MenuItem
                              style={{ paddingRight: "0" }}
                              onClick={() => handleDonationType("cash")}
                            >
                              <p>
                                <img
                                  src="../images/fundraiser_icon.svg"
                                  alt="Fundraiser"
                                />{" "}
                              </p>
                              <div>Fundraiser</div>
                            </MenuItem>
                            <MenuItem
                              style={{ paddingRight: "0" }}
                              onClick={() => handleDonationType("internship")}
                            >
                              <p>
                                <img
                                  src="/images/internship-icon.svg"
                                  alt="Internship"
                                />{" "}
                              </p>
                              <div> Internship</div>
                            </MenuItem>
                            <MenuItem
                              style={{ paddingRight: "0" }}
                              onClick={() => handleDonationType("volunteering")}
                            >
                              <p>
                                <img
                                  src="/images/voluteering-icon.svg"
                                  alt="Volunteering"
                                />{" "}
                              </p>
                              <div> Volunteering</div>
                            </MenuItem>

                            <MenuItem
                              style={{ paddingRight: "0" }}
                              onClick={() => handleDonationType("careerhelp")}
                            >
                              <p>
                                <img
                                  src="/images/career-help-icon.svg"
                                  alt="Career Help"
                                />{" "}
                              </p>
                              <div> Career Help</div>
                            </MenuItem>
                            <MenuItem
                              style={{ paddingRight: "0" }}
                              onClick={() => handleDonationType("plasma")}
                            >
                              <p>
                                <img
                                  src="/images/plasma-new.svg"
                                  alt="Plasma"
                                />{" "}
                              </p>
                              <div>Plasma Donation </div>
                            </MenuItem>
                            <MenuItem
                              style={{ paddingRight: "0" }}
                              onClick={() => handleDonationType("boardmember")}
                            >
                              <p>
                                <img
                                  src="/images/Board-member.svg"
                                  alt="Plasma"
                                />{" "}
                              </p>
                              <div> Board Member </div>
                            </MenuItem>
                            <MenuItem
                              onClick={() => handleDonationType("scholarship")}
                            >
                              <p>
                                <img
                                  src="/images/scholarship-new.svg"
                                  alt="Covid Help"
                                />{" "}
                              </p>
                              <div> Scholarship</div>
                            </MenuItem>
                            {authUser &&
                              authUser.unique_id &&
                              isTALRadioAdmin && (
                                <MenuItem
                                  style={{ paddingRight: "0" }}
                                  onClick={() => handleDonationType("podcast")}
                                >
                                  <p>
                                    <img
                                      src="/images/podcast.svg"
                                      alt="podcast Request"
                                      className="menu-icon"
                                    />{" "}
                                  </p>
                                  <div> Podcasts </div>
                                </MenuItem>
                              )}
                            <MenuItem
                              onClick={() =>
                                handleDonationType("eventparticipation")
                              }
                            >
                              <p>
                                <img
                                  src="/images/event.svg"
                                  alt="event Request"
                                  className="menu-icon"
                                />{" "}
                              </p>
                              <div> Event Speaker </div>
                            </MenuItem>

                            <MenuItem
                              onClick={() => handleDonationType("mentoring")}
                            >
                              <p>
                                <img
                                  src="/images/career-help-icon.svg"
                                  alt=" Request"
                                  className="menu-icon"
                                />
                              </p>
                              <div> Mentoring </div>
                            </MenuItem>
                            {region && region._id !== "US" && (
                              <MenuItem
                                onClick={() =>
                                  handleDonationType("bloodDonation")
                                }
                              >
                                <p>
                                  <img
                                    src="/images/blood-donation.svg"
                                    alt="Blood donation Request"
                                    className="menu-icon"
                                  />
                                </p>
                                <div> Blood Donation </div>
                              </MenuItem>
                            )}
                            <MenuItem
                              onClick={() => handleDonationType("covidhelp")}
                            >
                              <p>
                                <img
                                  src="/images/coronavirus.svg"
                                  alt="covid help"
                                  className="menu-icon"
                                />
                              </p>
                              <div>Covid Help</div>
                            </MenuItem>
                          </div>
                        )}
                      </IconButton>

                      {authUser && (
                        <IconButton
                          className={`top-icon-btn ${
                            active === "notification" && "active"
                          }`}
                          color="inherit"
                        >
                          <span className="kindness-points">
                            {userNotifications &&
                              showNotificationCount(userNotifications)}
                          </span>
                          <p onClick={notification}>
                            {userNotifications && (
                              <AllInboxIcon
                                style={{
                                  color:
                                    active === "notification"
                                      ? "#cd2729"
                                      : "#ffffff",
                                }}
                              />
                            )}
                          </p>
                          <p
                            onClick={notification}
                            style={{
                              color:
                                active === "notification"
                                  ? "#cd2729"
                                  : "#ffffff",
                            }}
                          >
                            Inbox{" "}
                            <span style={{ margin: 0 }}>
                              <ExpandMoreOutlinedIcon />
                            </span>
                          </p>
                          {userNotifications && showNotification && (
                            <div className="fix">
                              <div className="tab-box">
                                <List className="indox">
                                  {userNotifications &&
                                  userNotifications.length > 0 ? (
                                    userNotifications
                                      .slice(0, 4)
                                      .map((data) => {
                                        return (
                                          <List>
                                            <ListItem
                                              alignItems="flex-start"
                                              style={{
                                                cursor: "pointer",
                                                "background-color":
                                                  data.status === 0
                                                    ? "#f1f1f1"
                                                    : "transparent",
                                              }}
                                              onClick={() =>
                                                updateNotificationStatus(data)
                                              }
                                            >
                                              <ListItemAvatar>
                                                <Avatar
                                                  style={{
                                                    marginRight: "10px",
                                                  }}
                                                  alt="Remy Sharp"
                                                  src={data.sourceImageUrl}
                                                />
                                              </ListItemAvatar>
                                              <ListItemText
                                                primary={
                                                  data &&
                                                  data.destinationUrlReference ? (
                                                    <Link
                                                      href={`/donationRequest/${data.destinationUrlReference}`}
                                                      target="blank"
                                                    >
                                                      <span
                                                        style={{
                                                          "font-weight":
                                                            data.status === 0
                                                              ? "bold"
                                                              : "normal",
                                                        }}
                                                      >
                                                        {data.message}
                                                      </span>
                                                    </Link>
                                                  ) : (
                                                    <span
                                                      style={{
                                                        "font-weight":
                                                          data.status === 0
                                                            ? "bold"
                                                            : "normal",
                                                      }}
                                                    >
                                                      {data.message}
                                                    </span>
                                                  )
                                                }
                                                secondary={
                                                  <React.Fragment>
                                                    <Typography
                                                      component="span"
                                                      variant="body2"
                                                      color="textPrimary"
                                                      style={{ right: "0" }}
                                                    >
                                                      <small>
                                                        {" "}
                                                        {moment(
                                                          data.createdAt
                                                        ).format(
                                                          DATE_TIME_FORMAT
                                                        )}
                                                      </small>
                                                    </Typography>
                                                  </React.Fragment>
                                                }
                                              />
                                            </ListItem>
                                            <hr />
                                          </List>
                                        );
                                      })
                                  ) : (
                                    <span>You have no new notifications</span>
                                  )}

                                  <ListItem
                                    alignItems="flex-start"
                                    className="view"
                                    onClick={showAllNotifications}
                                  >
                                    {userNotifications.length > 0 ? (
                                      <a href="#" color="#868686">
                                        view all
                                      </a>
                                    ) : (
                                      ""
                                    )}
                                  </ListItem>
                                </List>
                              </div>
                            </div>
                          )}
                        </IconButton>
                      )}
                      <IconButton
                        className={`top-icon-btn ${
                          active === "region" && "active"
                        }`}
                        color="inherit"
                        style={{ position: "relative" }}
                      >
                        <p onClick={handleMyRegion} style={{ width: "30px" }}>
                          {region && (
                            <img
                              src={`/images/${region._id}.svg`}
                              alt={region.regionCodeIso3}
                              style={{ width: "80%" }}
                            />
                          )}
                        </p>
                        <p
                          onClick={handleMyRegion}
                          style={{
                            color: active === "region" ? "#cd2729" : "#ffffff",
                          }}
                        >
                          {region && region.regionCodeIso3}

                          <span style={{ margin: 0 }}>
                            <ExpandMoreOutlinedIcon />
                          </span>
                        </p>
                        {myRegion && (
                          <div className="tab-box" style={{ left: "-15px" }}>
                            {regions &&
                              regions.length > 0 &&
                              regions.map((region, index) => {
                                return (
                                  <MenuItem
                                    key={index}
                                    value={avatar}
                                    onClick={() => regionChangeHandler(region)}
                                  >
                                    <img
                                      src={`/images/${region._id}.svg`}
                                      width="25px"
                                      alt={region.regionCodeIso3}
                                      style={{ marginRight: 15 }}
                                    />{" "}
                                    {region.regionCodeIso3}
                                  </MenuItem>
                                );
                              })}
                          </div>
                        )}
                      </IconButton>
                      {authUser && (
                        <IconButton
                          className={`top-icon-btn ${
                            active === "profile" && "active"
                          }`}
                          color="inherit"
                          style={{ position: "relative" }}
                        >
                          <span className="kindness-points">
                            {userSummary &&
                              formatScore(userSummary.kindnessScore)}
                          </span>
                          <p onClick={handleMyProfile}>
                            {authUser && authUser.profile_image_url ? (
                              <img
                                style={{
                                  width: "25px",
                                  border: "2px solid white",
                                  height: "25px",
                                  margin: "0px 5px 0px 5px",
                                }}
                                src={
                                  authUser.profile_image_url
                                    ? authUser.profile_image_url
                                    : "/images/default-profile-photo.png"
                                }
                                alt="User Profile"
                                className="user-profile-image"
                                height="100%"
                                // onClick={handlePostMenuClick}
                              />
                            ) : (
                              <AccountCircleRounded
                                style={{
                                  color:
                                    active === "profile"
                                      ? "#cd2729"
                                      : "#ffffff",
                                }}
                              />
                            )}
                          </p>
                          <p
                            onClick={handleMyProfile}
                            style={{
                              color:
                                active === "profile" ? "#cd2729" : "#ffffff",
                            }}
                          >
                            Me{" "}
                            <span>
                              <ExpandMoreOutlinedIcon />
                            </span>
                          </p>

                          {myProfile && (
                            <div
                              className={
                                pathname &&
                                pathname.indexOf("/donationRequest/") < 0
                                  ? "tab-box position_re"
                                  : "tab-box dd-position position_re"
                              }
                              style={{ left: "-50px" }}
                            >
                              <MenuItem>
                                Hi{" "}
                                {authUser &&
                                  authUser.name &&
                                  authUser.name.first_name}
                                ,
                              </MenuItem>
                              <MenuItem>
                                <div style={{ display: "flex" }}>
                                  <div> Your Kindness Score: </div>
                                  <div>
                                    {userSummary &&
                                      numberWithCommas(
                                        userSummary.kindnessScore
                                      )}
                                  </div>
                                </div>
                              </MenuItem>

                              <hr />
                              <MenuItem
                                onClick={() => handleMyProfileOptions("edit")}
                              >
                                Edit Profile
                              </MenuItem>
                              <MenuItem
                                onClick={() => handleMyProfileOptions("public")}
                              >
                                Public Profile
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleMyProfileOptions("organization")
                                }
                              >
                                Add Organization
                              </MenuItem>

                              <hr />
                              <MenuItem onClick={verifyBankAccount}>
                                <span> Verify Bank Account</span>
                              </MenuItem>
                              <hr />

                              <MenuItem onClick={handleMyRequests}>
                                <span>My Requests</span>
                                <span>
                                  {userSummary &&
                                    userSummary.donationRequests &&
                                    userSummary.donationRequests.total}
                                </span>
                              </MenuItem>
                              <MenuItem onClick={handleMyDonations}>
                                <span>My Donations</span>
                                <span>
                                  {userSummary &&
                                    userSummary.donations &&
                                    userSummary.donations.total}
                                </span>
                              </MenuItem>

                              <MenuItem onClick={handleMyWithdrawals}>
                                <span>My Withdrawals</span>
                                <span>
                                  {userSummary &&
                                    userSummary.withdrawals &&
                                    userSummary.withdrawals.total}
                                </span>
                              </MenuItem>
                              <MenuItem onClick={handleMyOrganizations}>
                                <span>My Organizations</span>
                                <span>{orgList && orgList.length}</span>
                              </MenuItem>
                              <MenuItem onClick={handleContactsGroups}>
                                <span>My Contacts & Groups </span>
                                <span>{contacts && contacts.length}</span>
                              </MenuItem>
                              <MenuItem onClick={handleMyApplications}>
                                <span>My Applications </span>
                                <span>{totalRequestCount}</span>
                              </MenuItem>
                              <MenuItem onClick={showFavourites}>
                                <span>Favourites</span>
                                <span>
                                  {userSummary &&
                                    userSummary.favorites &&
                                    userSummary.favorites.total}
                                </span>
                              </MenuItem>

                              <hr />

                              <MenuItem onClick={createAudioRoom}>
                                <span>Create Audio Room</span>
                              </MenuItem>
                              <MenuItem onClick={() => viewAudioRooms()}>
                                <span> View Ongoing and Future Rooms</span>
                              </MenuItem>
                              <hr />

                              <MenuItem
                                onClick={() =>
                                  handleMyProfileOptions("feedback")
                                }
                              >
                                Feedback
                              </MenuItem>
                              <MenuItem
                                onClick={() =>
                                  handleMyProfileOptions("helpdesk")
                                }
                              >
                                Help Desk
                              </MenuItem>
                              <hr />
                              <MenuItem
                                onClick={() => handleMyProfileOptions("logout")}
                              >
                                Logout
                              </MenuItem>
                            </div>
                          )}
                        </IconButton>
                      )}
                      {pathname &&
                        pathname.indexOf("/donationRequest/") < 0 && (
                          <Button
                            className="donate-to-tal"
                            style={{
                              minWidth: "110px",
                              background: authUser ? "#f35a57 " : "transparent",
                            }}
                            onClick={handleStartGiving}
                          >
                            Donate to TAL &nbsp;
                            {region && region.currency === "INR" ? (
                              <FontAwesomeIcon
                                color="#fff"
                                size="1x"
                                icon={faRupeeSign}
                              />
                            ) : (
                              <FontAwesomeIcon
                                color="#fff"
                                size="1x"
                                icon={faDollarSign}
                              />
                            )}
                          </Button>
                        )}
                      {!authUser && !hideLoginButton && (
                        <Button
                          className="login-btn"
                          onClick={() => {
                            props.history.push("/login");
                          }}
                        >
                          Login/Sign up
                        </Button>
                      )}
                    </div>
                  </div>

                  <div className="mltMobile">
                    {pathname && pathname.indexOf("/donationRequest/") < 0 && (
                      <Button
                        className="donate-to-tal"
                        style={{
                          minWidth: "110px",
                          background: authUser ? "#f35a57 " : "#f35a57",
                        }}
                        onClick={handleStartGiving}
                      >
                        Donate to TAL &nbsp;
                        {region && region.currency === "INR" ? (
                          <FontAwesomeIcon
                            color="#fff"
                            size="1x"
                            icon={faRupeeSign}
                          />
                        ) : (
                          <FontAwesomeIcon
                            color="#fff"
                            size="1x"
                            icon={faDollarSign}
                          />
                        )}
                      </Button>
                    )}
                    <Box sx={{ flexGrow: 1 }}>
                      <AppBar position="static" className="msidemenu">
                        <Toolbar>
                          <IconButton
                            size="large"
                            edge="start"
                            aria-label="menu"
                            onClick={toggleDrawer("right", true)}
                            style={{ paddingRight: "0" }}
                          >
                            <MenuIcon />
                          </IconButton>
                        </Toolbar>
                        <Drawer
                          anchor="right"
                          open={drawerstate["right"]}
                          onClose={toggleDrawer("right", false)}
                        >
                          <Box>
                            <Paper
                              sx={{ width: 320, maxWidth: "100%" }}
                              className="menu-mobi"
                            >
                              <MenuList>
                                {authUser && (
                                  <>
                                    <MenuItem>
                                      <div className={classes.rootli}>
                                        <Accordion className="accmobile">
                                          <AccordionSummary
                                            expandIcon={
                                              switchAVatar ? (
                                                <ExpandLessIcon />
                                              ) : (
                                                <ExpandMoreIcon />
                                              )
                                            }
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                            className="accsum"
                                          >
                                            <HomeOutlinedIcon /> &nbsp; Donor
                                          </AccordionSummary>
                                          <AccordionDetails className="submenu-mobi">
                                            <MenuList>
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    setAvatar(AVATAR_DONOR);
                                                    props.history.push(
                                                      HOME_PAGE
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Donor
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    setAvatar(AVATAR_DONEE);
                                                    props.history.push(
                                                      HOME_PAGE
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Donee
                                                </ListItemText>
                                              </MenuItem>
                                            </MenuList>
                                          </AccordionDetails>
                                        </Accordion>
                                      </div>
                                    </MenuItem>
                                    <Divider />
                                  </>
                                )}
                                <MenuItem>
                                  <a
                                    href="http://blog.talgiving.org/"
                                    target="_blank"
                                  >
                                    <ListItemText
                                      style={{
                                        color:
                                          active === "audio"
                                            ? "#cd2729"
                                            : "#000000",
                                      }}
                                      onClick={() => {
                                        setDrawerstate(false);
                                      }}
                                    >
                                      {" "}
                                      <img
                                        src="/images/blog-icon-black.png"
                                        className="talmediamobi"
                                      />{" "}
                                      &nbsp; TALBlogs
                                    </ListItemText>
                                  </a>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                  <ListItemText
                                    onClick={() => {
                                      handleTalRadio();
                                      setDrawerstate(false);
                                    }}
                                    style={{
                                      color:
                                        active === "radio"
                                          ? "#cd2729"
                                          : "#000000",
                                    }}
                                  >
                                    <img
                                      src={`/images/${
                                        active === "radio"
                                          ? "talradio-active"
                                          : "talradio-mobile"
                                      }.png`}
                                      alt="TALMedia"
                                      className="talmediamobi"
                                    />
                                    &nbsp; TALMedia
                                  </ListItemText>
                                </MenuItem>
                                <Divider />
                                <MenuItem>
                                  <div className={classes.rootli}>
                                    <Accordion
                                      className="accmobile"
                                      style={{
                                        color:
                                          active === "plus"
                                            ? "#cd2729"
                                            : "#000000",
                                      }}
                                    >
                                      <AccordionSummary
                                        expandIcon={
                                          expandApps ? (
                                            <ExpandLessIcon />
                                          ) : (
                                            <ExpandMoreIcon />
                                          )
                                        }
                                        aria-controls="panel2a-content"
                                        id="panel2a-header"
                                        className="accsum"
                                      >
                                        <AddCircleOutlineRoundedIcon /> &nbsp;
                                        Add
                                      </AccordionSummary>
                                      <AccordionDetails className="submenu-mobi">
                                        <MenuList>
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType(
                                                  "internship"
                                                );
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                <img
                                                  src="/images/internship-icon.svg"
                                                  alt="Internship"
                                                />
                                              </span>
                                              <span>Internship </span>
                                            </ListItemText>
                                          </MenuItem>
                                          <Divider />
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType(
                                                  "volunteering"
                                                );
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                <img
                                                  src="/images/voluteering-icon.svg"
                                                  alt="Volunteering"
                                                />
                                              </span>
                                              <span>Volunteerings </span>
                                            </ListItemText>
                                          </MenuItem>
                                          <Divider />
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType("cash");
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                <img src="/images/fundraiser_icon.svg" />
                                              </span>
                                              <span>Fundraiser </span>
                                            </ListItemText>
                                          </MenuItem>
                                          <Divider />
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType("plasma");
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                {" "}
                                                <img
                                                  src="/images/plasma-new.svg"
                                                  alt="Plasma"
                                                />
                                              </span>
                                              <span> Plasma Donation </span>
                                            </ListItemText>
                                          </MenuItem>
                                          <Divider />
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType(
                                                  "careerhelp"
                                                );
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                {" "}
                                                <img
                                                  src="/images/career-help-icon.svg"
                                                  alt="Career Help"
                                                />
                                              </span>
                                              <span> Career Help </span>
                                            </ListItemText>
                                          </MenuItem>
                                          <Divider />
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType("covidhelp");
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                {" "}
                                                <img
                                                  src="/images/coronavirus.svg"
                                                  alt="Covid Help"
                                                />{" "}
                                              </span>
                                              <span> Covid Help </span>
                                            </ListItemText>
                                          </MenuItem>
                                          <Divider />
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType(
                                                  "boardmember"
                                                );
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                {" "}
                                                <img
                                                  src="/images/Board-member.svg"
                                                  alt="BoardMember Request"
                                                  className="mobi-menu-icon"
                                                />{" "}
                                              </span>
                                              <span> BoardMember </span>
                                            </ListItemText>
                                          </MenuItem>

                                          {isTALRadioAdmin && (
                                            <>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleDonationType(
                                                      "podcast"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                  className="add-fund-mobi"
                                                >
                                                  <span>
                                                    {" "}
                                                    <img
                                                      src="/images/podcast.svg"
                                                      alt="Podcast Request"
                                                    />{" "}
                                                  </span>
                                                  <span> Podcast </span>
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                            </>
                                          )}
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType(
                                                  "scholarship"
                                                );
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                <img
                                                  src="/images/scholarship-new.svg"
                                                  alt="Scholarship Request"
                                                  className="mobi-menu-icon"
                                                />
                                              </span>
                                              <span> Scholarship </span>
                                            </ListItemText>
                                          </MenuItem>
                                          <Divider />
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType(
                                                  "eventparticipation"
                                                );
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                <img
                                                  src="/images/event.svg"
                                                  alt="Scholarship Request"
                                                  className="mobi-menu-icon"
                                                />
                                              </span>
                                              <span> Event Speaker </span>
                                            </ListItemText>
                                          </MenuItem>
                                          <Divider />
                                          {region && region._id !== "US" && (
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  handleDonationType(
                                                    "bloodDonation"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                                className="add-fund-mobi"
                                              >
                                                <span>
                                                  <img
                                                    src="/images/blood-donation.svg"
                                                    alt="blood-donation Request"
                                                    className="mobi-menu-icon"
                                                  />
                                                </span>
                                                <span>Blood Donation </span>
                                              </ListItemText>
                                            </MenuItem>
                                          )}
                                          <MenuItem>
                                            <ListItemText
                                              onClick={() => {
                                                handleDonationType("mentoring");
                                                setDrawerstate(false);
                                              }}
                                              className="add-fund-mobi"
                                            >
                                              <span>
                                                <img
                                                  src="/images/career-help-icon.svg"
                                                  alt="Mentoring Request"
                                                  className="mobi-menu-icon"
                                                />
                                              </span>
                                              <span> Mentoring </span>
                                            </ListItemText>
                                          </MenuItem>
                                        </MenuList>
                                      </AccordionDetails>
                                    </Accordion>
                                  </div>
                                </MenuItem>
                                {authUser && (
                                  <>
                                    <Divider />
                                    <MenuItem>
                                      <div className={classes.rootli}>
                                        <Accordion className="accmobile">
                                          <AccordionSummary
                                            expandIcon={
                                              myProfile ? (
                                                <ExpandLessIcon />
                                              ) : (
                                                <ExpandMoreIcon />
                                              )
                                            }
                                            aria-controls="panel3a-content"
                                            id="panel3a-header"
                                            className="accsum"
                                          >
                                            <PersonOutlineOutlinedIcon /> &nbsp;
                                            Me
                                          </AccordionSummary>
                                          <AccordionDetails className="submenu-mobi">
                                            <MenuList>
                                              <MenuItem>
                                                <ListItemText>
                                                  {"Hi " +
                                                    (authUser &&
                                                      authUser.name &&
                                                      authUser.name.first_name)}
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText>
                                                  {"Your Kindness Score : " +
                                                    (userSummary &&
                                                      numberWithCommas(
                                                        userSummary.kindnessScore
                                                      ))}
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyProfileOptions(
                                                      "edit"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Edit Profile
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyProfileOptions(
                                                      "public"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  {" "}
                                                  Public Profile
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyProfileOptions(
                                                      "organization"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Add Organization
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyRequests();
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  {"My Requests " +
                                                    ": " +
                                                    (userSummary &&
                                                      userSummary.donationRequests &&
                                                      userSummary
                                                        .donationRequests
                                                        .total)}
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyDonations();
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  {"My Donations " +
                                                    " : " +
                                                    (userSummary &&
                                                      userSummary.donations &&
                                                      userSummary.donations
                                                        .total)}
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyWithdrawals();
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  {"My Withdrawals " +
                                                    " : " +
                                                    (userSummary &&
                                                      userSummary.withdrawals &&
                                                      userSummary.withdrawals
                                                        .total)}
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyOrganizations();
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  {"My Organizations " +
                                                    " : " +
                                                    (orgList && orgList.length)}
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleContactsGroups();
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  {"My Contact Groups " +
                                                    " : " +
                                                    (contacts &&
                                                      contacts.length)}
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyApplications();
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  {"My Applications " +
                                                    ": " +
                                                    totalRequestCount}
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    showFavourites();
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  {"Favourites " +
                                                    " : " +
                                                    (userSummary &&
                                                      userSummary.favorites &&
                                                      userSummary.favorites
                                                        .total)}
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    createAudioRoom();
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Create Audio Room
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    viewAudioRooms();
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  View Ongoing and Future Rooms
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyProfileOptions(
                                                      "feedback"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Feedback
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    handleMyProfileOptions(
                                                      "helpdesk"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Help Desk
                                                </ListItemText>
                                              </MenuItem>
                                            </MenuList>
                                          </AccordionDetails>
                                        </Accordion>
                                      </div>
                                    </MenuItem>
                                  </>
                                )}
                                <Divider />
                                {authUser && (
                                  <>
                                    <MenuItem>
                                      <ListItemText
                                        onClick={() => {
                                          showAllNotifications();
                                          setDrawerstate(false);
                                        }}
                                      >
                                        <AllInboxIcon /> &nbsp; Inbox
                                      </ListItemText>
                                    </MenuItem>
                                  </>
                                )}
                                <Divider />
                                <MenuItem>
                                  <MenuItem>
                                    <Box className={classes.slid_menu}>
                                      {regions &&
                                        regions.length > 0 &&
                                        regions.map((rgn, index) => {
                                          return (
                                            <Box
                                              component="Button"
                                              key={index}
                                              value={avatar}
                                              style={{
                                                backgroundColor:
                                                  region === rgn ? "white" : "",
                                              }}
                                              onClick={() => {
                                                regionChangeHandler(rgn);
                                                setMobileMoreAnchorEl(null);
                                              }}
                                            >
                                              <img
                                                src={`/images/${rgn._id}.svg`}
                                                width="20px"
                                                alt={rgn.regionCodeIso3}
                                                style={{ marginRight: 10 }}
                                              />{" "}
                                              {rgn.regionCodeIso3}
                                            </Box>
                                          );
                                        })}
                                    </Box>
                                  </MenuItem>
                                </MenuItem>
                                <Divider />
                                {(isSuperAdmin || isAdmin) && (
                                  <>
                                    <MenuItem>
                                      <div className={classes.rootli}>
                                        <Accordion className="accmobile">
                                          <AccordionSummary
                                            expandIcon={
                                              switchAVatar ? (
                                                <ExpandLessIcon />
                                              ) : (
                                                <ExpandMoreIcon />
                                              )
                                            }
                                            aria-controls="panel4a-content"
                                            id="panel4a-header"
                                            className="accsum"
                                          >
                                            <AccountCircleOutlined /> &nbsp;
                                            Admin
                                          </AccordionSummary>
                                          <AccordionDetails className="submenu-mobi">
                                            <MenuList>
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/donationRequests"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Donation Requests
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/withdrawalRequests"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Withdrawal Requests
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/organization"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Organizations
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/userActivityLog"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Activity Logs
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/userList"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Users List
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/SendEmailtoUsers"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Send email to users/contacts
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/bloodDonors"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Blood Donors
                                                </ListItemText>
                                              </MenuItem>
                                            </MenuList>
                                          </AccordionDetails>
                                        </Accordion>
                                      </div>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem>
                                      <div className={classes.rootli}>
                                        <Accordion className="accmobile">
                                          <AccordionSummary
                                            expandIcon={
                                              switchAVatar ? (
                                                <ExpandLessIcon />
                                              ) : (
                                                <ExpandMoreIcon />
                                              )
                                            }
                                            aria-controls="panel4a-content"
                                            id="panel4a-header"
                                            className="accsum"
                                          >
                                            <RadioOutlined /> &nbsp; TALRadio
                                            Admin
                                          </AccordionSummary>
                                          <AccordionDetails className="submenu-mobi">
                                            <MenuList>
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/talradioAdmin/manageTALRadio/tal-radio/liveStreamings"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Live Streamings
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/talradioAdmin/manageTALRadio/tal-radio/managePlaylist"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Playlists
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/talradioAdmin/manageTALRadio/tal-radio/managePlaylistItems"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Playlist Items
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/talradioAdmin/manageTALRadio/tal-radio/categories"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Categories
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/talradioAdmin/manageTALRadio/tal-radio/statisticsReport"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Generate Playlist Report
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/talradioAdmin/manageTALRadio/tal-radio/BMImusicsReport"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Generate BMI Music Report
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/managePodcast"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Podcast
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/Channels"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Channels
                                                </ListItemText>
                                              </MenuItem>
                                            </MenuList>
                                          </AccordionDetails>
                                        </Accordion>
                                      </div>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem>
                                      <div className={classes.rootli}>
                                        <Accordion className="accmobile">
                                          <AccordionSummary
                                            expandIcon={
                                              switchAVatar ? (
                                                <ExpandLessIcon />
                                              ) : (
                                                <ExpandMoreIcon />
                                              )
                                            }
                                            aria-controls="panel4a-content"
                                            id="panel4a-header"
                                            className="accsum"
                                          >
                                            <SupervisorAccountOutlined /> &nbsp;
                                            Manage TALLeaders
                                          </AccordionSummary>
                                          <AccordionDetails className="submenu-mobi">
                                            <MenuList>
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/TALLeaders/userInterests"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage User Interests
                                                </ListItemText>
                                              </MenuItem>
                                            </MenuList>
                                          </AccordionDetails>
                                        </Accordion>
                                      </div>
                                    </MenuItem>
                                    <Divider />
                                    <MenuItem>
                                      <div className={classes.rootli}>
                                        <Accordion className="accmobile">
                                          <AccordionSummary
                                            expandIcon={
                                              switchAVatar ? (
                                                <ExpandLessIcon />
                                              ) : (
                                                <ExpandMoreIcon />
                                              )
                                            }
                                            aria-controls="panel4a-content"
                                            id="panel4a-header"
                                            className="accsum"
                                          >
                                            <MonetizationOnOutlined /> &nbsp;
                                            Financial Admin Functionality
                                          </AccordionSummary>
                                          <AccordionDetails className="submenu-mobi">
                                            <MenuList>
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/donations"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Donations
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/withdrawalRequests"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Withdrawal Requests
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/donationRequests"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Manage Donation Requests
                                                </ListItemText>
                                              </MenuItem>
                                              <Divider />
                                              <MenuItem>
                                                <ListItemText
                                                  onClick={() => {
                                                    props.history.push(
                                                      "/admin/bloodDonors"
                                                    );
                                                    setDrawerstate(false);
                                                  }}
                                                >
                                                  Blood Donors
                                                </ListItemText>
                                              </MenuItem>
                                            </MenuList>
                                          </AccordionDetails>
                                        </Accordion>
                                      </div>
                                    </MenuItem>

                                    <Divider />
                                  </>
                                )}
                                {isFinAdmin && (
                                  <MenuItem>
                                    <div className={classes.rootli}>
                                      <Accordion className="accmobile">
                                        <AccordionSummary
                                          expandIcon={
                                            switchAVatar ? (
                                              <ExpandLessIcon />
                                            ) : (
                                              <ExpandMoreIcon />
                                            )
                                          }
                                          aria-controls="panel4a-content"
                                          id="panel4a-header"
                                          className="accsum"
                                        >
                                          <MonetizationOnOutlined /> &nbsp;
                                          Financial Admin Functionality
                                        </AccordionSummary>
                                        <AccordionDetails className="submenu-mobi">
                                          <MenuList>
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  props.history.push(
                                                    "/admin/donations"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Manage Donations
                                              </ListItemText>
                                            </MenuItem>
                                            <Divider />
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  props.history.push(
                                                    "/admin/withdrawalRequests"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Manage Withdrawal Requests
                                              </ListItemText>
                                            </MenuItem>
                                            <MenuItem>
                                              <ListItemText
                                                onClick={() => {
                                                  props.history.push(
                                                    "/admin/reconciliationReport"
                                                  );
                                                  setDrawerstate(false);
                                                }}
                                              >
                                                Manage Reconciliation Report
                                              </ListItemText>
                                            </MenuItem>
                                          </MenuList>
                                        </AccordionDetails>
                                      </Accordion>
                                    </div>
                                  </MenuItem>
                                )}

                                <Divider />

                                <MenuItem>
                                  <ListItemText>
                                    {authUser !== null && (
                                      <div
                                        className="mobi-logout"
                                        onClick={() => {
                                          handleMyProfileOptions("logout");
                                          setDrawerstate(false);
                                        }}
                                      >
                                        Logout
                                      </div>
                                    )}
                                  </ListItemText>
                                </MenuItem>
                                <MenuItem>
                                  <ListItemText>
                                    {!authUser && !hideLoginButton && (
                                      <div
                                        className="mobi-logout"
                                        onClick={() => {
                                          props.history.push("/login");
                                          setDrawerstate(false);
                                        }}
                                      >
                                        Login/Sign up
                                      </div>
                                    )}
                                  </ListItemText>
                                </MenuItem>
                              </MenuList>
                            </Paper>
                          </Box>
                        </Drawer>
                      </AppBar>
                    </Box>
                  </div>
                </Grid>
              )}
            </Grid>
            {!donateToTALClicked && (
              <Hidden mdUp>
                <div>
                  {showIcons && (
                    <div className={classes.search}>
                      <div className={classes.searchIcon}>
                        <SearchRounded />
                      </div>
                      <InputBase
                        placeholder="Search"
                        classes={{
                          root: classes.inputRoot,
                          input: classes.inputInput,
                        }}
                        id="topbar-search"
                        inputProps={{ "aria-label": "search" }}
                        onKeyPress={(e) => handleEnterClick(e)}
                      />
                    </div>
                  )}
                </div>
              </Hidden>
            )}
          </div>
        </Container>
      </AppBar>

      <PaymentsPopUp
        isOpen={isPaymentsPopUpOpen}
        donationFor="tal"
        onClose={() => setIsPaymentsPopUpOpen(false)}
      />
      <Organization
        openOrgModal={openOrgModal}
        onCloseModal={() => setOpenOrgModal(false)}
        onSavingOrg={onSavingOrg}
      />
    </div>
  );
};

export default withRouter(Topbar);
