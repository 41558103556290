import React, { useState } from "react";
import {
  AppBar,
  Button,
  Container,
  Divider,
  Grid,
  Tab,
  Tabs,
  TextField,
  Typography,
  withStyles,
} from "@material-ui/core";
import cssStyle from "./NewDonationRequest.module.scss";
import Avatar from "react-avatar";
import App from "../paymentGateway/stripe/App";
import InfoIcon from "@material-ui/icons/Info";
import { GrUpdate } from "react-icons/gr";
import { FaComment } from "react-icons/fa";
import { Box } from "@mui/system";
import LinearProgress from "@material-ui/core/LinearProgress";
import { IoIosShareAlt } from "react-icons/io";
import ThumbUpAltOutlinedIcon from "@material-ui/icons/ThumbUpAltOutlined";
import FavoriteBorderOutlinedIcon from "@material-ui/icons/FavoriteBorderOutlined";
import NewFooter from "../footer/NewFooter";
import NewTopbar from "../Topbar/NewTopbar";
import PropTypes from "prop-types";

import { GrFlag } from "react-icons/gr";

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor:
      theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    borderRadius: 5,
    backgroundColor: "#20A12D",
  },
}))(LinearProgress);

const NewDonationRequest = (props) => {
  return (
    <>
      <NewTopbar />
      <Container style={{ display: "flex", marginTop: "60px" }}>
        <Grid item xs={8} md={8}>
          <Grid className={cssStyle.card_tittle}>
            <span>Help me raise funds for imporve education 2021</span>
          </Grid>
          <Grid className={cssStyle.image_txt}>
            <img src="./images/new_web_images/Ellipse-6.svg" />
            <Grid className={cssStyle.text_style}>
              <p>Help me raise funds for imporve education 2021</p>
            </Grid>
          </Grid>
          <Grid className={cssStyle.profile_style}>
            <img src="./images/new_web_images/Background.png" />
          </Grid>
          <Grid>
            <AppBar
              position="static"
              style={{
                backgroundColor: "white",
                boxShadow: "none",
                padding: "15px",
                width: "92%",
              }}
            >
              <Tabs
                className={cssStyle.tabs_bar}
                aria-label="simple tabs example"
              >
                <Box className={cssStyle.icons_alig}>
                  <div className={cssStyle.icons_style}>
                    <InfoIcon />
                  </div>
                  <Tab label="Details" style={{ marginLeft: "-15px" }} />
                </Box>
                <Box className={cssStyle.icons_alig}>
                  <div className={cssStyle.icons_style}>
                    <img src="./images/new_web_images/Document-icon.svg" />
                  </div>
                  <Tab label="Documents" />
                </Box>
                <Box className={cssStyle.icons_alig}>
                  <div className={cssStyle.icons_style}>
                    <GrUpdate />
                  </div>
                  <Tab label="Update" />
                </Box>
                <Box className={cssStyle.icons_alig}>
                  <div className={cssStyle.icons_style}>
                    <FaComment />
                  </div>
                  <Tab label="Comments" />
                </Box>
              </Tabs>
              <Box className={cssStyle.Details_tittle}>
                <span>Description</span>
              </Box>
              <Box className={cssStyle.Details_Desc}>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
                <p>
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever since the 1500s, when an unknown
                  printer took a galley of type and scrambled it to make a type
                  specimen book. It has survived not only five centuries, but
                  also the leap into electronic typesetting, remaining
                  essentially unchanged. It was popularised in the 1960s with
                  the release of Letraset sheets containing Lorem Ipsum
                  passages, and more recently with desktop publishing software
                  like Aldus PageMaker including versions of Lorem Ipsum.
                </p>
              </Box>
            </AppBar>
          </Grid>
          <Box className={cssStyle.fund_bene_card}>
            <Grid item xs={6} md={6}>
              <Box className={cssStyle.fund_card}>
                <span>Fundraiser</span>
                <Box className={cssStyle.fund_pic_txt}>
                  <img src="./images/new_web_images/Ellipse-fund.svg" />
                  <Box className={cssStyle.profile_txt}>
                    <span>Gowthammadhamachi</span>
                    <img src="./images/new_web_images/fund-right.svg" />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={6}>
              <Box className={cssStyle.Benefi_card}>
                <span>Beneficiary</span>
                <Box className={cssStyle.fund_pic_txt}>
                  <img src="./images/new_web_images/Ellipse-bene (1).svg" />
                  <Box className={cssStyle.profile_txt}>
                    <span>Indian Red Cross Soc...</span>
                  </Box>
                </Box>
              </Box>
            </Grid>
          </Box>
        </Grid>
        <Grid item xs={4} className={cssStyle.Drop_box}>
          <Typography component="div" className={cssStyle.amount_text}>
            <Grid>
              <Grid className={cssStyle.currecy_line}>
                <div>
                  <span className={cssStyle.amount_fund}> ₹2,505 </span>
                  <span className={cssStyle.fund_line}>
                    {" "}
                    raised of ₹500,000
                  </span>
                </div>
                <div>30%</div>
              </Grid>
              <Box>
                <BorderLinearProgress
                  variant="determinate"
                  value={50}
                  style={{ margin: "15px 0" }}
                />
              </Box>
              <Grid className={cssStyle.day_mark}>
                <div className={cssStyle.donar_mark}>
                  <a href="#">25 Donars</a>
                </div>
                <div>
                  <span className={cssStyle.amount_fund}>56</span>
                  <span className={cssStyle.fund_line}> Days left </span>
                </div>
              </Grid>
            </Grid>
            <Divider style={{ margin: "20px" }} />
            <Box className={cssStyle.txt_donete}>
              <span>Donate</span>
            </Box>
            <Box className={cssStyle.payment_btn_container}>
              <Box>
                <Button>$100</Button>
              </Box>
              <Box>
                <Button>$200</Button>
              </Box>
              <Box>
                <Button>$500</Button>
              </Box>
              <Box>
                <Button>$1000</Button>
              </Box>
              <Box>
                <Button>$1500</Button>
              </Box>

            </Box>

            <Box className={cssStyle.Txt_field}>
              <TextField
                type="number"
                style={{ width: "100%" }}
                variant="outlined"
                placeholder="Enter other amount"
              ></TextField>
            </Box>
            <Box className={cssStyle.proceed_btn}>
              <Button>Proceed to Donate $200</Button>
            </Box>
            <Typography component="div" className={cssStyle.footer_sec}>
              <Box className={cssStyle.line_bar}>Power in Sharing</Box>
            </Typography>
            <Box className={cssStyle.share_btn}>
              <Button>
                <IoIosShareAlt className={cssStyle.share_icon} />
                share
              </Button>
            </Box>
            <Box className={cssStyle.like_fav_btn}>
              <Grid item xs={6}>
                <Box className={cssStyle.LIke_btn}>
                  <Button>
                    <ThumbUpAltOutlinedIcon />
                    <span>Like (5)</span>
                  </Button>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Box className={cssStyle.fav_btn}>
                  <Button>
                    <FavoriteBorderOutlinedIcon />
                  </Button>
                </Box>
              </Grid>
            </Box>
          </Typography>
        </Grid>

      </Container>
      <Box className={cssStyle.Report_req} >
      <img src="./images/new_web_images/flag.svg" />
      <span>
      Report request
      </span>
      </Box>

      <NewFooter />
    </>
  );
};
export default NewDonationRequest;
