import React, { useContext, useEffect, useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import {
  Divider,
  Fade,
  FormControl,
  InputLabel,
  makeStyles,
  Select,
  Tooltip,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import { Box, OutlinedInput } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import DateFnsUtils from "@date-io/date-fns";
import csstyle from "./NewFundRequest.module.scss";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HelpIcon from "@material-ui/icons/Help";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepButton from "@mui/material/StepButton";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import Modal from "@material-ui/core/Modal";
const usecsstyle = makeStyles((theme) => ({
  Paper: {
    minHeight: "100vh",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  papers: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    width: "673px",
    height: "564px",
    borderRadius: "8px",
    background: "#FFF",
    overflowX: "hidden",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "6px",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      margin: "0 10px",
    },
    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  modalorg: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  icons: {
    display: "flex",
    gridGap: "10px",
    justifyContent: "start",

    "& div": {
      boxShadow:
        " 0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
      padding: "8px 15px",
      cursor: "pointer",
      borderRadius: "10%",
    },

    "& img": {
      width: "20px",
      height: "20px",
      cursor: "pointer",
    },
  },

  sign_right: {
    padding: "2rem",
    minHeight: "100vh",

    "& p:last-child": {
      textAlign: "left",
      "& strong": {
        cursor: "pointer",
      },
    },
    "& section": {
      padding: "2rem 8rem",

      [theme.breakpoints.down("sm")]: {
        padding: "1rem 0 0",
      },

      "& hr": {
        position: "relative",
        top: "20px",
      },

      "& label": {
        display: "block",
        color: "#696F79",
        padding: "1rem 0",
      },

      "& h4": {
        fontWeight: "800",
        // textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        marginBottom: "20px",
        color: "#0000",
        fontSize: "25px",
        textAlign: " start",
      },

      "& FormControl": {
        display: "block",
      },
    },
  },
  formwidth: {
    margin: "12px 0px",
    width: "100%",
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const NewFundRequest = (props) => {
  const [showPassword, setShowPassword] = useState(true);
  const [rememberMe, setRememberMe] = useState(false);
  const [reasonModal, setReasonModal] = useState(false);
  const initialState = {
    account: "",
    password: "",
  };

  const classes = usecsstyle();
  const [openUploadPhotosModal, setOpenUploadPhotosModal] = useState(false);

  const [state, setState] = React.useState({
    age: "",
    name: "hai",
  });
  const handleUploadPhotos = () => {
    setOpenUploadPhotosModal(true);
  };
  const handleUploadClose = () => {
    setOpenUploadPhotosModal(false);
  };
  const handleContinue = () => {
    setActiveStep(activeStep + 1);
  };
  // const handleBack = () => {
  //   setActiveStep(activeStep - 1);
  // };
  const handleChange = (event) => {
    const name = event.target.name;
    setState({
      ...state,
      [name]: event.target.value,
    });
  };
  const steps = ["Basic information", "Tell about your self", "Allmost done"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };
  const [toggle, setToggle] = useState(true);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    window.scrollTo(0, 0);
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
    console.log("step", step);
  };
  const handleCloseNotesModal = () => {
    setReasonModal(false);
    // setApprovalOrRejectionNotes("");
    // setErrors("");
  };
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box component="main">
      <Grid container>
        <Grid
          item
          md={4}
          sm={12}
          xs={12}
          style={{ backgroundColor: "#FDF6F6"}}
        >
          <div className={ csstyle.login_left} >
            <Grid className={csstyle.sign_left}>
              <img
                src="/images/fund-img.svg"
                alt="funds-img"
                className={csstyle.fund_img}
              ></img>
              <Box className={csstyle.fund_stepper}>
                <p>Fundraisers</p>
                <Box  style={{ margin: "45px" }}>
                  <Stepper
                    nonLinear
                    activeStep={activeStep}
                    orientation="vertical"
                    className="stepper-align"
                  >
                    {steps.map((label, index) => (
                      <Step key={label} completed={completed[index]}>
                        <StepButton color="inherit" onClick={handleStep(index)}>
                          {label}
                        </StepButton>
                      </Step>
                    ))}
                  </Stepper>
                </Box>
              </Box>
              <img
                style={{ margin: "25px" }}
                src="/images/fundraiser_img.svg"
                alt="funds-img"
                className="fund_img"
              ></img>
            </Grid>
          </div>
        </Grid>

        <Grid
          item
          md={8}
          sm={12}
          xs={12}
          style={{ position: "relative", backgroundColor: "#FFF" }}
        >
          <Paper elevation={0}>
            <div className={csstyle.login_right}>
              <Box component="section">
                <Box component="aside" className={classes.sign_right}>
                  <Box component="p" mt={2} style={{ textAlign: "right" }}>
                    {" "}
                    Having trobules?{" "}
                    <span>
                      <strong
                        className={csstyle.tal_primary_color}
                        style={{ cursor: "pointer" }}
                      >
                        {" "}
                        Get Help
                      </strong>
                    </span>
                    {activeStep === 0 && (
                      <div>
                        <Box className={csstyle.counter_title}>
                          <h2>Basic request info</h2>
                          <p className={csstyle.p_tag}>
                            I am raising funds for
                          </p>
                        </Box>
                        <hr />
                        <Box>
                          <Box style={{ display: "flex" }}>
                            <Button className={csstyle.btn_Donar}>
                              <p>
                                Donar
                                <br /> <span>I'm wiling to give you </span>
                              </p>
                            </Button>

                            <Button className={csstyle.btn_Donee}>
                              Donee
                              <br />
                              I'm in need of...
                            </Button>
                          </Box>
                        </Box>
                        <Grid container>
                          <Grid item xs={12}>
                            <Box style={{ marginTop: "15px" }}>
                              <FormControl
                                variant="outlined"
                                className={classes.formwidth}
                              >
                                <InputLabel htmlFor="outlined-age-native-simple">
                                  Choose cause category
                                </InputLabel>
                                <Select
                                  native
                                  value={state.age}
                                  onChange={handleChange}
                                  label="Choose cause category"
                                  inputProps={{
                                    name: "Choose cause category",
                                    id: "outlined-age-native-simple",
                                  }}
                                >
                                  <option value={10}>Fundriser</option>
                                  <option value={20}>Internship</option>
                                </Select>
                              </FormControl>
                              <Grid>
                                <TextField
                                  className="w-100 mt-2"
                                  required
                                  id="outlined-required"
                                  label="Benificiary Name"
                                  defaultValue="Hello World"
                                  variant="outlined"
                                />
                              </Grid>

                              <FormControl
                                variant="outlined"
                                className={classes.formwidth}
                              >
                                <InputLabel htmlFor="outlined-age-native-simple">
                                  Select country
                                </InputLabel>
                                <Select
                                  native
                                  value={state.age}
                                  onChange={handleChange}
                                  label="select country"
                                  inputProps={{
                                    name: "Select conutry",
                                    id: "outlined-age-native-simple",
                                  }}
                                >
                                  <option value={10}>India</option>
                                  <option value={10}>USA</option>
                                </Select>
                              </FormControl>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <Box className={csstyle.target_fields}>
                              <TextField
                                type="number"
                                required
                                className={csstyle.target_field}
                                variant="outlined"
                                placeholder="Trarget Amount"
                                name="cashQuantity"
                                lable="Target Amount"
                              />
                              <span
                                className={csstyle.target_icon}
                                style={{
                                  position: "absolute",
                                  top: 15,
                                  padding: "0 4px",
                                  right: 12,
                                }}
                              >
                                <HelpIcon />
                              </span>
                            </Box>
                          </Grid>
                          <Grid item xs={12}>
                            <FormControl
                              style={{
                                fontWeight: "bold",
                                width: "100%",
                              }}
                              className="recipient"
                            >
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  className={classes.formwidth}
                                  showTodayButton
                                  format="dd-MMM-yyyy"
                                  inputVariant="outlined"
                                  label="Request end Date"
                                  name="startDate"
                                  disablePast
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                />
                              </MuiPickersUtilsProvider>
                            </FormControl>
                          </Grid>
                          <Grid item xs={12}>
                            <Box>
                              <p className={csstyle.p_tag}>
                                Keep in mind that trasaction fees,including
                                credit and debit charges,are deducated from each
                                donation.
                              </p>
                            </Box>{" "}
                          </Grid>
                        </Grid>
                      </div>
                    )}
                    {activeStep === 1 && (
                      <Grid item xs={12}>
                        <Box className={csstyle.counter_title}>
                          <h2>Tell About Request </h2>
                          <p>All feilds are mandatory</p>
                          <hr />
                        </Box>
                        <Grid>
                          <TextField
                            style={{ width: "100%" }}
                            required
                            id="outlined-required"
                            label="Fundriser Title"
                            defaultValue="Hello World"
                            variant="outlined"
                          />
                        </Grid>
                        <Grid className="w-100">
                          <textarea
                            className="w-100 mt-3 "
                            id="w3review"
                            name="w3review"
                            rows="4"
                            cols="50"
                          >
                            Hi my name is suraj you will learn how to make a
                            website. They offer free tutorials in all web
                            development technologies.
                          </textarea>
                        </Grid>
                        {/* <Grid item xs={12}>
                          <FormControl
                            style={{ width: "100%", padding: "10px" }}
                            className="recipient"
                             >
                            <Grid className="ck-editor-css">
                              <CKEditor
                                style={{ padding: "10px 20px" }}
                                maxLength={300}
                                data="description"
                                name="description"
                                // editor={ClassicEditor}
                                config={{
                                  toolbar: [
                                    "Heading",
                                    "bold",
                                    "italic",
                                    "bulletedList",
                                    "numberedList",
                                    "blockQuote",
                                    "Link",
                                  ],
                                  placeholder:
                                    "Give description about this Board Member Request",
                                }}
                              />
                            </Grid>
                            
                          </FormControl>
                        </Grid> */}
                        <Grid>
                          <div className={csstyle.toggles_btn}>
                            <div className="secFormLabel">
                              <p>Make my request private</p>
                            </div>
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div class="toggle-group">
                                <input
                                  type="checkbox"
                                  name="on-off-switch"
                                  id="on-off-switch"
                                  toggle={toggle}
                                  tabindex="1"
                                  onChange={handleToggle}
                                />
                                <label for="on-off-switch">
                                  <span class="aural">Show:</span>
                                </label>
                                <div
                                  class="onoffswitch pull-right"
                                  aria-hidden="true"
                                >
                                  <div class="onoffswitch-label">
                                    <div class="onoffswitch-inner"></div>
                                    <div class="onoffswitch-switch"></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p></p>
                        </Grid>
                        <Grid>
                          {" "}
                          <from>
                            <input
                              className={csstyle.width_felid}
                              type="text"
                              placeholder="Press enter to add multipule id's"
                              id="fname"
                              name="fname"
                            />
                          </from>
                          <p className={csstyle.p_tag}>
                            only people with access can view
                          </p>
                        </Grid>
                      </Grid>
                    )}
                    {activeStep === 2 && (
                      <Grid className="w-100">
                        <Box className={csstyle.counter_title}>
                          <h2>Uploads </h2>
                          <hr />
                        </Box>
                        <div>
                          <Box>
                            <span style={{ textAlign: "start" }}>
                              upload photos
                            </span>
                            <p className={csstyle.p_tag}>supports jpg,png</p>
                          </Box>
                          <Box>
                            <Box className={csstyle.upload_Btn}>
                              <IconButton
                                onClick={handleUploadPhotos}
                                className={csstyle.Btn_text}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="19"
                                  viewBox="0 0 18 19"
                                  fill="none"
                                >
                                  <path
                                    d="M14.25 2.75H3.75C2.92157 2.75 2.25 3.42157 2.25 4.25V14.75C2.25 15.5784 2.92157 16.25 3.75 16.25H14.25C15.0784 16.25 15.75 15.5784 15.75 14.75V4.25C15.75 3.42157 15.0784 2.75 14.25 2.75Z"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M6.375 8C6.99632 8 7.5 7.49632 7.5 6.875C7.5 6.25368 6.99632 5.75 6.375 5.75C5.75368 5.75 5.25 6.25368 5.25 6.875C5.25 7.49632 5.75368 8 6.375 8Z"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M15.75 11.75L12 8L3.75 16.25"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                <span className={csstyle.margin_left}>
                                  upload
                                </span>
                              </IconButton>
                            </Box>
                          </Box>
                        </div>
                        <div>
                          <Box>
                            <span style={{ textAlign: "start" }}>
                              support documents(Optional)
                            </span>
                            <p className={csstyle.p_tag}>
                              supports jpg,png,pdf,Doc
                            </p>
                          </Box>
                          <Box>
                            <Box className={csstyle.upload_Btn}>
                              <IconButton className={csstyle.Btn_text}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M10.5 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V6L10.5 1.5Z"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.5 1.5V6H15"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12 9.75H6"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M12 12.75H6"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M7.5 6.75H6.75H6"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                <span className={csstyle.margin_left}>
                                  upload
                                </span>
                              </IconButton>
                              <p className={csstyle.p_tag_btn}>
                                Or Drag & Drop
                              </p>
                            </Box>
                          </Box>
                        </div>
                        <div>
                          <Box>
                            <p mt={2}>Upload videos(Optional)</p>
                            <p className={csstyle.p_tag}>
                              supports jpg,png,pdf,Doc
                            </p>
                          </Box>
                          <Box>
                            <Box className={csstyle.upload_Btn}>
                              <IconButton className={csstyle.Btn_text}>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M17.25 5.25L12 9L17.25 12.75V5.25Z"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M10.5 3.75H2.25C1.42157 3.75 0.75 4.42157 0.75 5.25V12.75C0.75 13.5784 1.42157 14.25 2.25 14.25H10.5C11.3284 14.25 12 13.5784 12 12.75V5.25C12 4.42157 11.3284 3.75 10.5 3.75Z"
                                    stroke="#F35A57"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                                <span className={csstyle.margin_left}>
                                  upload
                                </span>
                              </IconButton>
                              <p className={csstyle.p_tag_btn}>
                                Or Drag & Drop
                              </p>
                            </Box>
                          </Box>
                        </div>
                      </Grid>
                    )}
                  </Box>
                  <hr style={{ marginTop: "8px" }} />
                  <Box className={csstyle.display_btn}>
                    <Button style={{ color: "#606060" }} onClick={handleBack}>
                      <ArrowBackIcon
                        style={{ color: "#606060", fontSize: "14px" }}
                      />{" "}
                      Back
                    </Button>
                    <Button
                      style={{}}
                      className={csstyle.tal_primary_Bg}
                      variant="contained"
                      type="submit"
                      onClick={handleContinue}
                    >
                      <span>Continue</span>
                      <ArrowForwardIcon
                        style={{ color: "#ffff", fontSize: "14px" }}
                      />
                    </Button>
                  </Box>
                </Box>
              </Box>
            </div>

            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              className={classes.modal}
              open={openUploadPhotosModal}
              onClose={handleUploadClose}
              closeAfterTransition
              // BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}
            >
              <Fade in={openUploadPhotosModal}>
                <div className={classes.paper}>
                  <div className={csstyle.disply_modal}>
                    <div className={csstyle.counter_titles}>
                      <h2> Upload Photos</h2>
                    </div>
                    <div>
                      {" "}
                      <span className={csstyle.cursor_view}>
                        <CloseIcon onClose={handleUploadClose} />
                      </span>
                    </div>
                  </div>
                  <div style={{ position: "relative" }}>
                    <img
                      src="./images/new_web_images/Background.png"
                      alt=""
                      className={csstyle.display_img}
                    />
                    <img
                      className={csstyle.img_postion}
                      src="./images/new_web_images/drag-image.svg"
                      alt=""
                    />
                  </div>
                  <div className={csstyle.disply_modals}>
                    <Button className={csstyle.cancel_btn}>cancel </Button>
                    <Button className={csstyle.save_btn}>save</Button>
                  </div>
                </div>
              </Fade>
            </Modal>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};
export default NewFundRequest;
