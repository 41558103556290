import React  from "react";
import { withRouter } from "react-router-dom";
import csstyle from "./AllDonationRequestCard.module.scss";
import { Container, Grid, Button, MenuItem, Select, Hidden, FormControl, InputLabel } from "@material-ui/core";
import {  PlayArrow} from "@material-ui/icons";
import NewTopbar from "../Topbar/NewTopbar";
import NewFooter from "../footer/NewFooter";
import NewDonationRequestCard from "./NewDonationRequestCard";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
  
const AllDonationRequestCard = (props) => {
  var settings = {
    className: "slider variable-width",
    variableWidth: true,
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 0,
    slidesToScroll: 0,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: false,
          dots: false,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 0,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 0,
        },
      },
    ],
  }; 
 
 return (
  <>
  <Grid style={{background:"#fff"}}>
  <NewTopbar/>
  <Grid className={csstyle.internal_banner}>
    <Container>
      <Grid container> 
          <Grid item md={6} sm={12} xs={12} className={csstyle.banner_titles + " " + csstyle.inner_order1}>
          <h1>Browse Fundraisers</h1>
            <p>People around the world are raising money for what they are <br/>passionate about.</p>
            <Grid>
            <Button className="red_btn white_text font-weight-xl">Create Requests</Button>
            <Button className="red_btn_outer ml-3 font-weight-xl"> <PlayArrow/> Watch Video </Button>
            </Grid>
          </Grid>
          <Hidden smDown>
          <Grid item md={6} sm={12} xs={12} className={csstyle.card_banner  + " " + csstyle.inner_order2}>
            <img src="./images/all-cards.png" alt=" "/>
          </Grid>
          </Hidden>
      </Grid>
    </Container>
  </Grid>
  <Container>
      <Grid container> 
      <Grid xs={12}>
      <Grid className={csstyle.lan_chips}>
      <Slider {...settings}>
      <MenuItem className={csstyle.lan_chip_selected}>
      All
      </MenuItem>
      <MenuItem>
      Education
      </MenuItem>
      <MenuItem>
      Health
      </MenuItem>
      <MenuItem>
      Women & Girls
      </MenuItem>
      <MenuItem>
      Animals
      </MenuItem>
      <MenuItem>
      Food & Hunger
      </MenuItem>
      <MenuItem>
      Environment
      </MenuItem>
        </Slider>
        </Grid>
      </Grid>
      </Grid>
      <Grid container className="mb-4 justify-content-between" >
      <Grid md={6}>
      <p className="font-size-xs grey_text mb-0">Showing fundraisers for:</p>
      <h6 className="font-size-lg font-weight-lg black_text">Education</h6>
      </Grid>
      <Grid md={6}>
        <Grid className="d-flex justify-content-end">
        <FormControl variant="outlined" className={csstyle.sort_by + " " + "mr-3"}>
        <InputLabel htmlFor="outlined-age-native-simple">Sort by</InputLabel>
        <Select
          native
         
          label="Sort by"
          inputProps={{
            name: 'sort',
            id: 'outlined-sort-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option>Option 1</option>
          <option>Option 2</option>
          <option>Option 3</option>
        </Select>
      </FormControl>
      <FormControl variant="outlined" className={csstyle.sort_by}>
        <InputLabel htmlFor="outlined-location-native-simple">All Location</InputLabel>
        <Select
          native
         
          label="All Location"
          inputProps={{
            name: 'location',
            id: 'outlined-location-native-simple',
          }}
        >
          <option aria-label="None" value="" />
          <option>Location 1</option>
          <option>Location 2</option>
          <option>Location 3</option>
        </Select>
      </FormControl>
               
        </Grid>
      </Grid>
      
      </Grid>
    </Container>
    <Container>
    <NewDonationRequestCard/>
    </Container>
    <NewFooter/>
    </Grid>
  </>
 );
};
export default withRouter(AllDonationRequestCard);