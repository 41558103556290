import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import toastr from "toastr";
import Grid from "@material-ui/core/Grid";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import CancelIcon from "@material-ui/icons/Cancel";

import "./stripe/components/CheckoutForm.scss";
import {
  TextField,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  FormControl,
  Button,
  Tooltip,
} from "@material-ui/core";
import { appContext } from "../../App";
import useFormValidation from "../../hooks/useFormValidation";
import {
  deleteAccount,
  getAccountInfo,
  accountInformation,
  verifyBankAccount,
  chargeDonation,
} from "../../utils/api";

import "./stripe/components/CheckoutForm.scss";
import {
  PAYMENT_METHOD_STRIPE_ACH,
  roundToTwoDecimalPlaces,
} from "../../utils/utils";

const BankDetails = (props) => {
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [extraErrors, setExtraErrors] = useState({});
  const [valuesCopy, setValuesCopy] = useState({});
  const [isEditable, setIsEditable] = useState(false);

  const initialState = {
    fullName: "",
    firstName: "",
    lastName: "",
    RoutingNumber: "",
    AccountNumber: "",
    AccountHolderType: "",
    country: "",
    bankName: "",
    Amount1: "",
    Amount2: "",
  };

  const { authUser, regions, region, donationDetails } = useContext(appContext);

  const validateProfile = (values) => {
    if (donationDetails && donationDetails.userId) {
      let errors = {};
      if (!values.firstName) {
        errors.firstName = "Please enter First Name";
      }
      if (!values.lastName) {
        errors.lastName = "Please enter Last Name";
      }
      return errors;
    } else {
      return {};
    }
  };

  useEffect(() => {
    props.userId &&
      getAccountInfo(props.userId)
        .then((response) => {
          let accountsList = response.data.data;

          //check for default account
          let accountDetails = accountsList.find((i) => i.isDefault === true);

          //if default account is not found, consider first account from the list
          if (!accountDetails) {
            accountDetails = accountsList[0] || null;
          }
          setValues((prevState) => {
            return {
              ...prevState,
              AccountNumber: accountDetails && accountDetails.accountNumber,
              RoutingNumber: accountDetails && accountDetails.routingNumber,
              currentAccountId: accountDetails && accountDetails.uniqueId,
              bankName:
                accountDetails && accountDetails.label
                  ? accountDetails.label
                  : "",
              isDefault: accountDetails && accountDetails.isDefault,
              fullName:
                accountDetails && accountDetails.accountHolderName
                  ? accountDetails.accountHolderName
                  : `${authUser.name.first_name} ${authUser.name.last_name}`,
              status: accountDetails && accountDetails.status,
              country: accountDetails && accountDetails.country,
              AccountHolderType:
                accountDetails && accountDetails.accountHolderType,
            };
          });
          if (
            (accountDetails && accountDetails.status === "verified") ||
            (accountDetails && accountDetails.status === "new")
          )
            setIsEditable(false);
          else setIsEditable(true);
          setValuesCopy({
            AccountNumber: accountDetails && accountDetails.accountNumber,
            RoutingNumber: accountDetails && accountDetails.routingNumber,
            bankName:
              accountDetails && accountDetails.label
                ? accountDetails.label
                : "",
            fullName:
              accountDetails && accountDetails.accountHolderName
                ? accountDetails.accountHolderName
                : `${authUser.name.first_name} ${authUser.name.last_name}`,
            country: accountDetails && accountDetails.country,
            AccountHolderType:
              accountDetails && accountDetails.accountHolderType,
          });
        })
        .catch((error) => {
          console.log("Failed to fetch", error);
        });
  }, [props.userId, authUser]);

  const createStripeBankToken = () => {
    var bankAccountData = {
      country: values.country,
      routingNumber: values.RoutingNumber,
      accountNumber: values.AccountNumber,
      accountHolderName: values.fullName,
      accountHolderType: values.AccountHolderType,
      isDefault: true,
      label: values.bankName,
      currency: values.country === "IN" ? "inr" : "usd",
    };
    accountInformation(bankAccountData, authUser.unique_id)
      .then((response) => {
        if (response.data.statusCode === 200) {
          toastr.success(
            "Thank you for updating the bank account information."
          );
          if (values.country == "US") {
            alert(
              "We need to verify your bank account. We will desposit two small amounts in your bank account. Please check your bank account for those amounts and complete the verification process."
            );
          }
          setError("");
          setValues((prevState) => {
            return {
              ...prevState,
              status: response.data.data.status,
              currentAccountId: response.data.data.uniqueId,

              AccountNumber: response.data.data.accountNumber,
              RoutingNumber: response.data.data.routingNumber,

              bankName: response.data.data.label
                ? response.data.data.label
                : "",
              isDefault: response.data.data.isDefault,
              fullName: response.data.data.accountHolderName,
              country: response.data.data.country,
              AccountHolderType: response.data.data.accountHolderType,
            };
          });
          if (
            (response.data &&
              response.data.data &&
              response.data.data.status === "verified") ||
            (response.data &&
              response.data.data &&
              response.data.data.status === "new")
          )
            setIsEditable(false);
          else setIsEditable(true);
          setValuesCopy((prevState) => {
            return {
              ...prevState,
              AccountNumber: response.data.data.accountNumber,
              RoutingNumber: response.data.data.routingNumber,
              bankName: response.data.data.label
                ? response.data.data.label
                : "",
              fullName: response.data.data.accountHolderName,
              country: response.data.data.country,
              AccountHolderType: response.data.data.accountHolderType,
            };
          });
        } else {
          toastr.error(response.data.message);
          setError(response.data.message);
        }
        setProcessing(false);
      })
      .catch((error) => {
        console.log("error 2", error);
        setProcessing(false);
        setError(error.data.message);
      });
  };
  const verifyAccount = () => {
    const amount = [values.Amount1, values.Amount2];
    const obj = { amount: amount };
    verifyBankAccount(values && values.currentAccountId, obj)
      .then((response) => {
        toastr.success(response.message);
        setError("");
        setValues((prevState) => {
          return {
            ...prevState,
            status: response.data.status,
            Amount1: "",
            Amount2: "",
          };
        });
        if (
          response.data &&
          (response.data.status === "verified" ||
            response.data.status === "new")
        )
          setIsEditable(false);
        else setIsEditable(true);
        setProcessing(false);
      })
      .catch((error) => {
        toastr.error(error.message);
        setError(error.message);
        setProcessing(false);
      });
  };

  const updateAccount = () => {
    deleteAccount(authUser.unique_id, values && values.currentAccountId)
      .then(() => {
        createStripeBankToken();
      })
      .catch((error) => {
        setProcessing(false);
        setError(error.message);
      });
  };
  const makePayment = () => {
    var payload = {};

    payload.donationFor = donationDetails.donationFor;
    if (donationDetails.donationFor === "donationRequest") {
      payload.donationRequestId = donationDetails.donationRequestId;
    } else if (donationDetails.donationFor === "individual") {
      payload.toUserInfo = donationDetails.toUserInfo;
    }
    payload.amount =
      donationDetails &&
      donationDetails.totalAmount &&
      roundToTwoDecimalPlaces(donationDetails.totalAmount);
    payload.units = "usd";
    chargeDonation(payload)
      .then((response) => {
        if (response.data) {
          const achResponse = {
            amount: response.data.amount,
            currency: response.data.currency,
            transactionNumber: response.data.balance_transaction,
            receiptData: {
              donatedTo:
                donationDetails.donationFor === "individual"
                  ? donationDetails.toUserInfo
                  : donationDetails.donationRequestId,
              units: response.data.currency,
              amount: response.data.amount,
              receiptId: null,
              paymentId: null,
              donatedDate: response.data.created,
              paymentMethod: PAYMENT_METHOD_STRIPE_ACH,
              donationFor: donationDetails.donationFor,
              name: response.data.source.account_holder_name,
              userMail: response.data.billing_details.email,
              phone: response.data.billing_details.phone,
              address: response.data.billing_details.address,
              status: response.data.status,
            },
            score: response.data.score,
          };
          toastr.success(response.message);
          props.history.push("/thankyou", { achResponse });
        }
      })
      .catch((error) => {
        toastr.error(error.message);
        setError(error.message);
      });
  };

  const handleACH = (op) => {
    const errors = {};
    setExtraErrors(errors);
    if (op === "ach" && values.country !== "US") {
      toastr.error("StripeACH only available in USA");
      return;
    }
    if (values.status === null || values.status === "verified") {
      if (!values.RoutingNumber) {
        errors.RoutingNumber =
          values && values.country
            ? values.country === "US"
              ? "Please Enter Routing Number"
              : "Please Enter IFSC Code"
            : region && region._id === "IN"
            ? "Please Enter IFSC Code"
            : region && region._id === "US" && "Please Enter Routing Number";
      } else if (values.country === "US" && values.RoutingNumber.length !== 9) {
        errors.RoutingNumber = "Invalid Routing Number";
      }
      if (!values.bankName) {
        errors.bankName = "Please Enter Bank Name";
      }
      if (!values.country) {
        errors.country = "Please select Country";
      }
      if (!values.fullName) {
        errors.fullName = "Please Enter Full Name";
      }
      if (!values.AccountHolderType) {
        errors.AccountHolderType = "Please Enter Account Holder Type";
      }
      if (!values.AccountNumber) {
        errors.AccountNumber = "Please Enter Account Number";
      }
    } else if (values.status === "new" && !isEditable) {
      if (!values.Amount1) {
        errors.Amount1 = "Please enter the first small deposit amount";
      }
      if (!values.Amount2) {
        errors.Amount2 = "Please enter the second small deposit amount";
      }
    }

    setExtraErrors(errors);
    if (errors && Object.keys(errors).length > 0) return;
    const bank = {
      AccountNumber: values.AccountNumber,
      RoutingNumber: values.RoutingNumber,
      bankName: values.bankName,
      fullName: values.fullName,
      AccountHolderType: values.AccountHolderType,
      country: values.country,
    };

    if (values.status === null) {
      createStripeBankToken();
    } else if (bank.country !== valuesCopy.country) {
      updateAccount();
    } else if (values.status === "new" && !isEditable) {
      if (values.country === "US") {
        verifyAccount();
      }
    } else if (values.status === "verified") {
      if (isEditable) {
        setIsEditable(false);
      } else {
        makePayment();
      }
    } else {
      toastr.error("Issue in updating or verifying account");
      console.log("error");
    }
  };

  const { values, setValues, errors, changeHandler } = useFormValidation(
    initialState,
    validateProfile
  );

  return (
    <div>
      <Grid container spacing={3} className="donation-payment-container">
        <Grid item xs={12} sm={6} md={6}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            className="bnk_style"
          >
            <InputLabel id="country">Country</InputLabel>
            <Select
              labelId="country"
              name="country"
              value={values.country}
              onChange={changeHandler}
              label="Country"
              style={{ width: "100%" }}
              disabled={!isEditable}
            >
              {regions &&
                regions.map((region) => (
                  <MenuItem value={region._id}>{region.name}</MenuItem>
                ))}
            </Select>
          </FormControl>
          {extraErrors && extraErrors.country && (
            <Typography className="custom-error">
              {extraErrors.country}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            type="text"
            label="Bank Name"
            variant="outlined"
            style={{ width: "100%" }}
            name="bankName"
            value={`${values.bankName}`}
            onChange={changeHandler}
            disabled={!isEditable}
          />
          {extraErrors && extraErrors.bankName && (
            <Typography className="custom-error">
              {extraErrors.bankName}
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          <TextField
            type="text"
            label="Account Holder Name"
            variant="outlined"
            style={{ width: "100%" }}
            name="fullName"
            value={`${values.fullName}`}
            onChange={changeHandler}
            disabled={!isEditable}
          />
          {extraErrors && extraErrors.fullName && (
            <Typography className="custom-error">
              {extraErrors.fullName}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <FormControl
            style={{ width: "100%" }}
            variant="outlined"
            className="bnk_style"
          >
            <InputLabel id="account-type">Account Type</InputLabel>
            <Select
              labelId="account-type"
              name="AccountHolderType"
              value={values.AccountHolderType}
              onChange={changeHandler}
              label="Account Type"
              style={{ width: "100%" }}
              disabled={!isEditable}
            >
              <MenuItem value="">Select Account Type</MenuItem>
              <MenuItem value="individual">Individual</MenuItem>
              <MenuItem value="company">Company</MenuItem>
            </Select>
          </FormControl>
          {extraErrors && extraErrors.AccountHolderType && (
            <Typography className="custom-error">
              {extraErrors.AccountHolderType}
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label="Account Number"
            variant="outlined"
            style={{ width: "100%" }}
            name="AccountNumber"
            // disabled={!editMode}
            onChange={changeHandler}
            type="number"
            value={values.AccountNumber}
            disabled={!isEditable}
          />
          {extraErrors && extraErrors.AccountNumber && (
            <Typography variant="span" className="custom-error">
              {extraErrors.AccountNumber}
            </Typography>
          )}
          {values && values.status === "new" && values.country === "US" && (
            <>
              <Tooltip
                title={
                  "Please verify this bank account by entering the small amounts that we deposited in your bank account"
                }
              >
                <CancelIcon
                  style={{
                    color: "red",
                    fontSize: "40px",
                  }}
                />
              </Tooltip>
              <span>This bank account is not yet verified. Please verify.</span>
            </>
          )}

          {values &&
            values.status === "verified" &&
            values.country === "US" &&
            !isEditable && (
              <>
                <div style={{ marginTop: "15px" }}>
                  <Tooltip title={"This bank account is verified. Thank you!"}>
                    <VerifiedUser
                      style={{
                        color: "green",
                        fontSize: "40px",
                      }}
                    />
                  </Tooltip>
                  <span>This bank account is verified. Thank you!</span>
                </div>
              </>
            )}
        </Grid>
        <Grid item xs={12} sm={6} md={6}>
          <TextField
            label={
              values && values.country
                ? values.country === "US"
                  ? "Routing Number"
                  : "IFSC Code"
                : region && region._id === "IN"
                ? "IFSC Code"
                : region && region._id === "US" && "Routing Number"
            }
            variant="outlined"
            style={{ width: "100%" }}
            name="RoutingNumber"
            //  disabled={!editMode}
            // value={accountValues && accountValues.routingNumber}
            onChange={changeHandler}
            type="text"
            value={values.RoutingNumber}
            disabled={!isEditable}
          />
          {extraErrors && extraErrors.RoutingNumber && (
            <Typography className="custom-error">
              {extraErrors.RoutingNumber}
            </Typography>
          )}
        </Grid>
        {values.status === "new" && values.country === "US" && !isEditable && (
          <>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                placeholder="First amount deposited in your account"
                variant="outlined"
                style={{ width: "100%" }}
                name="Amount1"
                value={values.Amount1}
                onChange={changeHandler}
              />
              {extraErrors && extraErrors.Amount1 && (
                <Typography className="custom-error">
                  {extraErrors.Amount1}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="text"
                placeholder="Second amount deposited in your account"
                variant="outlined"
                style={{ width: "100%" }}
                name="Amount2"
                value={values.Amount2}
                onChange={changeHandler}
              />
              {extraErrors && extraErrors.Amount2 && (
                <Typography className="custom-error">
                  {extraErrors.Amount2}
                </Typography>
              )}
            </Grid>
          </>
        )}
        {error && <div className="custom-error">{error}</div>}
        <Grid item xs={12} container>
          <Grid item xs={12} sm={6} md={6}>
            <Button
              variant="contained"
              color="primary"
              className="saveBtn"
              onClick={
                isEditable
                  ? (event) => handleACH(event)
                  : () => setIsEditable(true)
              }
            >
              {isEditable ? "Save" : "Edit"}
            </Button>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            {values.status !== null &&
              (values.status === "new" && values.country === "US" ? (
                <Button
                  variant="contained"
                  className="saveBtn"
                  color="primary"
                  onClick={(event) => handleACH("")}
                >
                  Verify
                </Button>
              ) : !isEditable &&
                !window.location.pathname.includes("/newUserProfile") ? (
                <Button
                  variant="contained"
                  className="saveBtn"
                  color="primary"
                  onClick={(event) => handleACH("ach")}
                >
                  Donate
                </Button>
              ) : (
                ""
              ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default withRouter(BankDetails);
