import React, { useState, useEffect, useContext } from "react";
import moment from "moment";
import toastr from "toastr";
import Button from "@material-ui/core/Button";

import {
  FormControl,
  FormHelperText,
  TextField,
  Grid,
  InputLabel,
  Switch,
  Box,
  TableCell,
  TablePagination,
  Typography,
  IconButton,
  InputBase,
  Tooltip,
  Radio,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { DATE_TIME_FORMAT_FULL_PICKER } from "../../utils/utils";
import DateFnsUtils from "@date-io/date-fns";
import {
  getStreamings,
  saveStreaming,
  updateStreaming,
  validateScheduleDates,
  getCurrentTime,
  updatePlaylist,
  getPlaylists,
} from "../../utils/api";
import Loader from "../common/Loader";
import ChannelSelectionDDBox from "./ChannelSelectDDBox";
import { appContext } from "../../App";
import VideoPlayer from "../common/VideoPlayer";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { SearchRounded } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import styles from "./StreamingForm.module.scss";

const useStyles = makeStyles((theme) => ({
  table_tab: {
    "& td:last-child": {
      whiteSpace: "nowrap",
      padding: "0",
    },
    "& td:nth-of-type(5)": {
      whiteSpace: "nowrap",
    },
    "& td:nth-of-type(6)": {
      whiteSpace: "nowrap",
    },
  },

  rightmenu: {
    "& Button": {
      marginLeft: "5px",
    },

    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
    },
    [theme.breakpoints.down("xs")]: {
      justifyContent: "flex-start",
    },
  },

  liveplaylists: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    lineHeight: "30px",

    "& p": {
      borderRadius: "50%",
      width: "12px",
      alignItems: "center",
      marginBottom: "6px",
    },
    "& h2": {
      color: "var(--tal_primary)",
      fontSize: "22px",
      fontWaite: "bold",
      marginRight: "5px",
    },
    "& h4": {
      fontSize: "18px",
      color: "#575757",
      margin: "0",
      lineHeight: "35px",
    },

    "&  Button:nth-of-type(1)": {
      backgroundColor: "var(--tal_primary)",
      color: "white",
      borderRadius: "5px",
      padding: "5px",
    },

    "& Button:nth-of-type(2)": {
      backgroundColor: "var(--tal_primary)",
      color: "white",
    },
  },
}));

export default function StreamingForm(props) {
  const {
    setIsDisplayStreamings,
    isEnableEdit,
    setIsEnableEdit,
    selectedId,
    rows,
    setRows,
    rowsPerPages,
    setPages,
  } = props;
  const { playlists, channel, setChannel, authUser } = useContext(appContext);

  const [editorPick, setEditorPick] = useState(false);
  const handleEditorPick = () => {
    setEditorPick(!editorPick);
  };
  const [dateConflictError, setDateConflictError] = useState("");
  const [channelPlaylist, setChannelPlaylist] = useState([]);
  const [playlist, setPlaylist] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [extraErrors, setExtraErrors] = useState({});
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isOpenDate, setIsOpenDate] = useState(false);
  const [name, setName] = useState("");
  const [isEditDisabled, setIsEditDisabled] = useState(false);
  const [playlistTitle, setPlaylistTitle] = useState("");
  const [playlistDuration, setPlaylistDuration] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [keyword, setKeyword] = useState("");
  const [isSearchMode, setIsSearchMode] = useState(false);
  const [isPlayingAudio, setIsPlayingAudio] = useState(false);
  const [playlistData, setPlaylistData] = useState([]);
  const [totalCountOfRecords, setTotalCountOfRecords] = useState(0);
  const classes = useStyles();

  const loadPlaylistData = (page, playlistData) => {
    setIsLoading(true);
    getPlaylists("", rowsPerPage, page * rowsPerPage, channel)
      .then((response) => {
        setIsLoading(false);
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setTotalCountOfRecords(response.totalCountOfRecords);

          const playListArray = [];
          playlistData.map((data) => {
            const obj = data;
            playListArray.push(obj);
            return null;
          });
          const responseArray = [];
          response.data.map((data) => {
            const obj = data;
            responseArray.push(obj);
            return null;
          });
          setPlaylistData([...playListArray, ...responseArray]);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Playlist:" + error.message);
      });
  };

  const loadStreamings = (page, rowsStreaming) => {
    setIsLoading(true);
    getStreamings("", rowsStreaming, page * rowsStreaming, channel)
      .then((response) => {
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setRows([...response.data]);

          setTotalCountOfRecords(response.totalCountOfRecords);
        } else {
          setTotalCountOfRecords(response.totalCountOfRecords);
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Streamings:" + error);
      });
  };

  useEffect(() => {
    if (!isEnableEdit && props.LoadingStreaming) {
      loadStreamings(0, rowsPerPages - 1);
      setRows([]);
    }
  }, [channel]);

  useEffect(() => {
    if (keyword) {
      setIsSearchMode(true);
      setPage(0);
      setPlaylistData([]);
      loadPlaylistDataBasedOnKeyword(keyword, 0, []);
    } else {
      setIsSearchMode(false);
      setPage(0);
      setPlaylistData([]);
      loadPlaylistData(0, []);
    }
  }, [authUser, channel, rowsPerPage, keyword]);

  const loadPlaylistDataBasedOnKeyword = (keyword, page, playlistData) => {
    setIsLoading(true);
    getPlaylists("", rowsPerPage, page * rowsPerPage, channel, keyword)
      .then((response) => {
        setIsLoading(false);
        if (
          response &&
          response.data instanceof Array &&
          response.data.length > 0
        ) {
          setPlaylistData([...playlistData, ...response.data]);
          setTotalCountOfRecords(response.totalCountOfRecords);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Playlist:" + error.message);
      });
  };

  const toggleEditorPick = (event, index, recordId) => {
    const checkedStatus = event.target.checked;
    updatePlaylist(recordId, { editorPick: checkedStatus })
      .then((updateResp) => {
        if (updateResp.statusCode === 200) {
          var filterRows = playlistData;
          filterRows[index].editorPick = checkedStatus;
          setPlaylistData([...filterRows]);

          toastr.success(updateResp.message);
        } else {
          toastr.error("Error while updating the playliist");
        }
      })
      .catch(() => {
        toastr.error("Editor Pick: Failed to update the playlist");
      });
  };

  const onPlayAudio = (row) => {
    setSelectedRow(row);
    setIsPlayingAudio(true);
  };
  const handlePlayerClose = () => {
    setSelectedRow({});
    setIsPlayingAudio(false);
  };
  const handleChangePage = (event, newPage) => {
    if (isSearchMode) {
      loadPlaylistDataBasedOnKeyword(keyword, newPage, playlistData);
    } else {
      loadPlaylistData(newPage, playlistData);
    }
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setPlaylistData([]);
  };

  const handleEnterClick = (event) => {
    var code = event.keyCode || event.which;
    if (code === 13) {
      if (event.target.value) {
        event.target.blur();
        setKeyword(event.target.value);
      }
    }
  };

  const clearSearchResultsHandler = () => {
    setPlaylistData([]);
    setIsSearchMode(false);
    setKeyword("");
    setPage(0);
  };

  function handleName(event) {
    setName(event.target.value);
  }
  function handleChangeChannel(event) {
    if (channel !== event.target.value) setPlaylist("");
    setChannel(event.target.value);
    setChannelPlaylist(
      playlists.filter((play) => play.channel === event.target.value)
    );
  }
  const handleChangePlaylist = (row) => {
    setPlaylist(row._id);
    setPlaylistTitle(row.title);
    setPlaylistDuration(row.duration);
  };
  function handleChangeDateOK() {
    setIsOpenDate(false);
  }
  function handleChangeFromDateCancel() {
    setIsOpenDate(false);
    setFromDate(null);
  }
  function handleChangeEndDateCancel() {
    setIsOpenDate(false);
    setEndDate(null);
  }

  const handleChangeFromDate = (date) => {
    const roundedDate = moment(date.getTime()).startOf("minute").toDate();
    setFromDate(roundedDate);
    setDateConflictError("");
    let obj = {};
    obj.channel = channel;
    obj.startTime = roundedDate.getTime();
    obj.endTime = endDate && endDate.getTime();
    if (selectedId) {
      obj.streamingId = selectedId;
    }
    if (date && endDate) {
      validateScheduleDates(obj)
        .then(() => {})
        .catch((error) => {
          setDateConflictError(
            "The schedule of this streaming conflicts with one of the streamings already scheduled"
          );
        });
    }
  };

  const handleChangeEndDate = (date) => {
    const roundedDate = moment(date.getTime()).startOf("minute").toDate();
    setEndDate(roundedDate);
    setDateConflictError("");
    let obj = {};
    obj.channel = channel;
    obj.startTime = fromDate && fromDate.getTime();
    obj.endTime = roundedDate.getTime();
    if (selectedId) {
      obj.streamingId = selectedId;
    }
    if (fromDate && date) {
      validateScheduleDates(obj)
        .then(() => {})
        .catch(() => {
          setDateConflictError(
            "The schedule of this streaming conflicts with one of the streamings already scheduled"
          );
        });
    }
  };

  const handleBack = () => {
    setIsEnableEdit(false);
    setIsDisplayStreamings(true);
    if (!isEnableEdit) {
      setPages(0);
      loadStreamings(0, rowsPerPages);
    }
  };

  const loadStreaming = (id) => {
    setIsLoading(true);
    getStreamings(id)
      .then((response) => {
        if (response) {
          setName(response.data.title);
          setEditorPick(response.data.editorPick);
          setChannel(response.data.channel);
          setFromDate(new Date(response.data.startTime));
          setEndDate(new Date(response.data.endTime));
          setPlaylist(response.data.playlist._id);
          setPlaylistTitle(response.data.playlist.title);
          setPlaylistDuration(response.data.playlist.duration);
          getCurrentTime().then((currentTime) => {
            if (
              currentTime > response.data.startTime &&
              currentTime < response.data.endTime
            ) {
              toastr.error(
                "You can not update this Streaming because it is currently being played."
              );
              setIsEditDisabled(true);
            }
          });
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching Stream:" + error.message);
      });
  };

  useEffect(() => {
    if (selectedId && isEnableEdit) {
      loadStreaming(selectedId);
    }
  }, [selectedId, isEnableEdit]);

  const saveStreamingData = (finalValues) => {
    saveStreaming(finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          setRows([
            {
              _id: resp.data._id,
              playlist: finalValues.playlist,
              title: finalValues.title,
              startTime: finalValues.startTime,
              endTime: finalValues.endTime,
              editorPick: editorPick,
              channel: channel,
            },
            ...rows,
          ]);
          toastr.success(resp.message);
          props.callback();
          setIsEnableEdit(false);
        } else {
          toastr.error(resp.message);
        }
        setIsDisplayStreamings(true);
        setIsWaitingForResponse(false);
        setIsEnableEdit(false);
      })
      .catch((error) => {
        setIsWaitingForResponse(false);
        toastr.error(error.message);
      });
  };

  const updateStreamingData = (finalValues) => {
    updateStreaming(selectedId, finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          const oldRows = rows.map((item) => {
            if (item._id === selectedId) {
              return {
                _id: resp.data._id,
                playlist: finalValues.playlist,
                title: finalValues.title,
                startTime: finalValues.startTime,
                endTime: finalValues.endTime,
                editorPick: editorPick,
                channel: channel,
              };
            } else {
              return item;
            }
          });
          setRows(oldRows);
          toastr.success(resp.message);
        } else {
          toastr.error(resp.message);
        }
        setIsEnableEdit(false);
        setIsDisplayStreamings(true);
        setIsWaitingForResponse(false);
      })
      .catch((error) => {
        setIsWaitingForResponse(false);
        toastr.error(error.response.data.message);
      });
  };

  const handleNext = async () => {
    const extraErrors = {};
    if (dateConflictError) extraErrors.dateConflictError = dateConflictError;
    if (name === "") {
      extraErrors.name = "Please enter name";
    }
    if (fromDate === null) {
      extraErrors.fDate = "Select From Date";
    }
    if (endDate === null) {
      extraErrors.eDate = "Select End Date";
    }

    if (!playlist) {
      extraErrors.playlist = "Please select a Playlist";
    }

    if (
      endDate &&
      endDate.getTime() - fromDate &&
      fromDate.getTime() < 300000
    ) {
      extraErrors.fDate =
        "Atleast a 5 min difference between start time and end time";
    }
    setExtraErrors(extraErrors);
    if (extraErrors && Object.keys(extraErrors).length !== 0) {
      toastr.error("Please enter the mandatory fields ");
      return;
    }

    const finalValues = {};
    finalValues.channel = channel;
    finalValues.startTime = fromDate.getTime();
    finalValues.endTime = endDate.getTime();
    finalValues.playlist = playlist;
    finalValues.title = name;
    finalValues.editorPick = editorPick;
    setIsWaitingForResponse(true);
    if (isEnableEdit) {
      finalValues.selectedId = selectedId;
      updateStreamingData(finalValues);
    } else {
      saveStreamingData(finalValues);
    }
  };

  return (
    <Box>
      <Box className="drop-box schedule-streaming" p={2}>
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <Box fontWeight="400" fontSize="20px" color="#f35a57">
              {" "}
              Schedule Streaming{" "}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box textAlign="right" display="flex" justifyContent="flex-end">
              <Box component="Typography" lineHeight="36px">
                Editors Pick
              </Box>

              <Switch
                checked={editorPick}
                onChange={handleEditorPick}
                name="editorPick"
                size="medium"
                inputProps={{ "aria-label": "secondary checkbox" }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <TextField
              variant="outlined"
              className="input-field"
              label="Title"
              placeholder="Give a title"
              name="name"
              value={name}
              error={
                extraErrors && extraErrors.name && extraErrors.name.length > 0
              }
              helperText={extraErrors.name}
              onChange={handleName}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl variant="outlined" fullWidth>
              <ChannelSelectionDDBox />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6} md={6}>
            <FormControl
              fullWidth
              error={
                (extraErrors &&
                  extraErrors.fDate &&
                  extraErrors.fDate.length > 0) ||
                dateConflictError.length > 0
              }
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  okLabel={<Button onClick={handleChangeDateOK}>OK</Button>}
                  cancelLabel={
                    <Button onClick={handleChangeFromDateCancel}>CANCEL</Button>
                  }
                  // style={{ width: "231px" }}
                  variant="dialog"
                  value={fromDate}
                  inputVariant="outlined"
                  label="Select Start Date"
                  format={DATE_TIME_FORMAT_FULL_PICKER}
                  placeholder={DATE_TIME_FORMAT_FULL_PICKER}
                  onChange={(newDate) => {
                    handleChangeFromDate(newDate);
                  }}
                  disablePast
                  className={
                    styles.plasma_input +
                    " " +
                    styles.date_b +
                    " " +
                    `input-field ${
                      extraErrors && extraErrors.fDate
                        ? styles.bg_red
                        : fromDate
                        ? styles.bg_green
                        : styles.bg_normal
                    }`
                  }
                />
              </MuiPickersUtilsProvider>
              {extraErrors && extraErrors.fDate && (
                <div className={styles.custom_error}>{extraErrors.fDate}</div>
              )}
              {dateConflictError && (
                <div className={styles.custom_error}>{dateConflictError}</div>
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={6}>
            <FormControl
              fullWidth
              error={
                extraErrors && extraErrors.eDate && extraErrors.eDate.length > 0
              }
            >
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DateTimePicker
                  okLabel={<Button onClick={handleChangeDateOK}>OK</Button>}
                  cancelLabel={
                    <Button onClick={handleChangeEndDateCancel}>CANCEL</Button>
                  }
                  // style={{ width: "231px" }}
                  variant="dialog"
                  value={endDate}
                  inputVariant="outlined"
                  label="Select End Date"
                  format={DATE_TIME_FORMAT_FULL_PICKER}
                  placeholder={DATE_TIME_FORMAT_FULL_PICKER}
                  onChange={(newDate) => {
                    handleChangeEndDate(newDate);
                  }}
                  className={
                    styles.plasma_input +
                    " " +
                    styles.date_b +
                    " " +
                    `input-field ${
                      extraErrors && extraErrors.eDate
                        ? styles.bg_red
                        : endDate
                        ? styles.bg_green
                        : styles.bg_normal
                    }`
                  }
                />
              </MuiPickersUtilsProvider>
              {extraErrors && extraErrors.eDate && (
                <div className={styles.custom_error}>{extraErrors.eDate}</div>
              )}
            </FormControl>
          </Grid>
          <div style={{ margin: "15px 10px", display: "flex" }}>
            <p>
              <b>Selected playlist : </b>
            </p>
            {playlist && isEnableEdit ? (
              <div>
                {playlistTitle}(
                {playlistDuration &&
                  playlistDuration > 0 &&
                  new Date(playlistDuration * 1000).toISOString().substr(11, 8)}
                )
              </div>
            ) : (
              ""
            )}
            {playlist && !isEnableEdit ? (
              <div>
                {playlistTitle}(
                {playlistDuration &&
                  playlistDuration > 0 &&
                  new Date(playlistDuration * 1000).toISOString().substr(11, 8)}
                )
              </div>
            ) : (
              ""
            )}
          </div>
        </Grid>
        <Box>
          <Box className="drop-box" p={1} mb={1}>
            <Box
              classes="manage_playlist"
              display="flex"
              justifyContent="space-between"
              flexWrap="wrap"
              minWidth="50%"
            >
              <Grid container className="search-stream">
                <Grid item sm={4} xs={12}>
                  <Box className={classes.livestreamings}>
                    <Box>
                      <h4>Select Playlist</h4>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={8} xs={12} className="search-play-filter">
                  <Box>
                    <div
                      className="bar-main"
                      style={{ alignItems: "center", gap: "10px" }}
                    >
                      {isSearchMode && (
                        <p className="result" style={{ marginLeft: "20px" }}>
                          Search Results for
                          <span
                            style={{
                              // boxShadow: "rgb(239 239 239) 0px 0px 7px",
                              // borderRadius: "5px",
                              padding: "3px 10px 5px 10px",
                              marginLeft: "10px",
                              fontWeight: " bold",
                              color: "#686868",
                              background: "#f8f8f8",
                            }}
                          >
                            {keyword}
                          </span>
                          <IconButton
                            edge="start"
                            aria-label="close"
                            style={{
                              padding: "3px",
                              color: "var(--tal_primary)",
                              position: "relative",
                              top: "-2px",
                              margin: "0 0 0 10px",
                              border: "1px solid #ededed",
                            }}
                          >
                            <Tooltip title="Clear Search Results">
                              <CloseIcon onClick={clearSearchResultsHandler} />
                            </Tooltip>
                          </IconButton>
                        </p>
                      )}
                      <Box className="searchbar-dr" whiteSpace="noWrap">
                        <SearchRounded
                          style={{ margin: "0 4px", height: "46px" }}
                        />
                        <InputBase
                          placeholder="Search"
                          classes={{
                            root: classes.inputRoot,
                            input: classes.inputInput,
                          }}
                          id="stream-form-search"
                          inputProps={{ "aria-label": "search" }}
                          onKeyPress={(e) => handleEnterClick(e)}
                        />
                      </Box>
                    </div>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
          <Box className={classes.table_tab}>
            <TableContainer component={Paper} style={{ marginBottom: "8px" }}>
              <Table aria-label="all playlists table">
                <TableHead>
                  <TableRow>
                    <TableCell>Thumbnail</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Producer</TableCell>
                    <TableCell>RJ</TableCell>
                    <TableCell>Category</TableCell>
                    <TableCell>Audio Length</TableCell>
                    <TableCell>Editors Pick</TableCell>
                    <TableCell>Select Playlist</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {playlistData &&
                    playlistData.length > 0 &&
                    playlistData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <TableRow key={row._id}>
                          <TableCell>
                            <Button onClick={() => onPlayAudio(row)}>
                              <img
                                src={
                                  row.thumbnailImageUrl
                                    ? row.thumbnailImageUrl
                                    : "/images/radio_thumbnail.png"
                                }
                                width="40px"
                                height="40px"
                                title="play this audio"
                                alt="playlist"
                              />
                            </Button>
                          </TableCell>
                          <TableCell>{row.title}</TableCell>
                          <TableCell>{row.producer}</TableCell>
                          <TableCell>{row.rjUserId}</TableCell>
                          <TableCell>
                            {row.categories &&
                              row.categories.length > 0 &&
                              row.categories.map((cat) => cat.name).join(",")}
                          </TableCell>
                          <TableCell>
                            {row.duration &&
                              row.duration > 0 &&
                              new Date(row.duration * 1000)
                                .toISOString()
                                .substr(11, 8)}
                          </TableCell>
                          <TableCell>
                            <Switch
                              checked={row.editorPick}
                              name="editorPick"
                              inputProps={{
                                "aria-label": "secondary checkbox",
                              }}
                              onClick={(event) =>
                                toggleEditorPick(event, index, row._id)
                              }
                            />
                          </TableCell>
                          <TableCell>
                            <Radio
                              checked={playlist === row._id}
                              onChange={() => handleChangePlaylist(row)}
                              value={row._id}
                              name="select playlist"
                            />
                          </TableCell>
                        </TableRow>
                      ))}
                  {isLoading && (
                    <TableRow align="center">
                      <TableCell colSpan="10">
                        <Typography
                          style={{
                            textAlign: "center",
                            fontSize: "20px",
                            padding: "10px 0",
                          }}
                        >
                          Loading...
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                  {playlistData && playlistData.length === 0 && !isLoading && (
                    <TableRow align="center">
                      <TableCell colSpan="10">
                        <Typography
                          style={{
                            textAlign: "center",
                          }}
                        >
                          No records found
                        </Typography>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50, 100, 500, 1000]}
                component="div"
                count={totalCountOfRecords}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </TableContainer>
            {extraErrors && extraErrors.playlist && (
              <Typography className="custom-error">
                {extraErrors.playlist}
              </Typography>
            )}
            <VideoPlayer
              isOpen={isPlayingAudio}
              onClose={handlePlayerClose}
              name={selectedRow.title}
              url={selectedRow.downloadUrl}
              note={selectedRow.description}
            />
          </Box>
        </Box>
        <Box>
          <Button
            variant="contained"
            onClick={handleBack}
            style={{ marginTop: "20px" }}
          >
            Back
          </Button>
          <span style={{ cursor: isEditDisabled ? "not-allowed" : "default" }}>
            <Button
              style={{
                backgroundColor: "var(--tal_primary)",
                marginLeft: "5px",
                color: "white",
                marginTop: "20px",
              }}
              disabled={isEditDisabled}
              variant="contained"
              onClick={handleNext}
            >
              {isEnableEdit ? "Save" : "Schedule"}
            </Button>
          </span>
        </Box>
        <Loader
          isOpen={isWaitingForResponse}
          onClose={() => setIsWaitingForResponse(false)}
        />
      </Box>
    </Box>
  );
}
