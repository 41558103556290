import React, { useContext, useState, useEffect } from "react";
import moment from "moment";
import toastr from "toastr";
import Button from "@material-ui/core/Button";
import { appContext } from "../../App";
import {
  FormControl,
  FormHelperText,
  TextField,
  Grid,
  Box,
  InputAdornment,
} from "@material-ui/core";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { DATE_TIME_FORMAT_FULL_PICKER } from "../../utils/utils";
import DateFnsUtils from "@date-io/date-fns";
import { getChatRooms, saveChatRooms, updateChatRooms } from "../../utils/api";
import Loader from "../common/Loader";
import { withRouter } from "react-router";
import Container from "@material-ui/core/Container";
import MenuIcon from "@material-ui/icons/Menu";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import Sidemenu from "../SideMenu/Sidemenu";
import { useStyles } from "@material-ui/pickers/views/Calendar/SlideTransition";
import styles from "./ChatRoomForm.module.scss";

function ChatRoomForm(props) {
  const { authUser, chats, setChats } = useContext(appContext);

  const [dateConflictError, setDateConflictError] = useState("");
  const [loading, setIsLoading] = useState(false);
  const [isWaitingForResponse, setIsWaitingForResponse] = useState(false);
  const [extraErrors, setExtraErrors] = useState({});
  const [description, setDescription] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isOpenDate, setIsOpenDate] = useState(false);
  const [name, setName] = useState("");
  const [selectedId] = useState(
    props && props.location && props.location.state
  );
  function handleName(event) {
    setName(event.target.value);
  }
  const handleCloseSideMenu = () => {
    setCloseSideMenu(!closeSideMenu);
  };
  const [closeSideMenu, setCloseSideMenu] = useState(false);

  function handleDescription(event) {
    setDescription(event.target.value);
  }

  function handleChangeDateOK() {
    setIsOpenDate(false);
  }

  function handleChangeFromDateCancel() {
    setIsOpenDate(false);
    setFromDate(null);
  }

  function handleChangeEndDateCancel() {
    setIsOpenDate(false);
    setEndDate(null);
  }

  const handleChangeFromDate = (date) => {
    const roundedDate = moment(date.getTime()).startOf("minute").toDate();
    setFromDate(roundedDate);
    setDateConflictError("");
    let obj = {};
    obj.description = description;
    obj.startTime = roundedDate.getTime();
    obj.endTime = endDate && endDate.getTime();
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.selectedId
    ) {
      obj.streamingId = props.location.state.selectedId;
    }
    // if (date && endDate) {
    //   validateScheduleDates(obj)
    //     .then(() => {})
    //     .catch((error) => {
    //       setDateConflictError(
    //         "The schedule of this chatroom conflicts with one of the  already scheduled"
    //       );
    //     });
    // }
  };

  const handleChangeEndDate = (date) => {
    const roundedDate = moment(date.getTime()).startOf("minute").toDate();
    setEndDate(roundedDate);
    setDateConflictError("");
    let obj = {};
    obj.description = description;
    obj.startTime = fromDate && fromDate.getTime();
    obj.endTime = roundedDate.getTime();
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.selectedId
    ) {
      obj.streamingId = props.location.state.selectedId;
    }
    // if (fromDate && date) {
    //   validateScheduleDates(obj)
    //     .then(() => {})
    //     .catch(() => {
    //       setDateConflictError(
    //         "The schedule of this chatroom conflicts with one of the chats already scheduled"
    //       );
    //     });
    // }
  };

  const handleBack = () => {
    props.history.push("/chatTabs");
  };

  const loadChatRoom = (id) => {
    setIsLoading(true);
    getChatRooms({
      ownerId: authUser && authUser.unique_id,
      uniqueId: props.location.state.selectedId,
    })
      .then((response) => {
        if (response) {
          setName(response.data.title);
          setDescription(response.data.description);
          setFromDate(new Date(response.data.startTime));
          setEndDate(new Date(response.data.endTime));
        }
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        toastr.error("Problem in fetching chat room: " + error.message);
      });
  };

  useEffect(() => {
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.selectedId
    ) {
      loadChatRoom(props.location.state.selectedId);
    }
  }, [
    props &&
      props.location &&
      props.location.state &&
      props.location.state.selectedId,
  ]);

  const saveChatRoomData = (finalValues) => {
    const saveData = { ...finalValues, ownerId: authUser.unique_id };

    saveChatRooms(saveData)
      .then((resp) => {
        if (resp.statusCode === 200) {
          toastr.success(resp.message);
          props.history.push("/chatTabs");
        } else {
          toastr.error(resp.message);
        }
        setIsWaitingForResponse(false);
      })
      .catch((error) => {
        setIsWaitingForResponse(false);
        toastr.error(error.message);
      });
  };

  const updateChatRoomData = (finalValues) => {
    updateChatRooms(props.location.state.selectedId, finalValues)
      .then((resp) => {
        if (resp.statusCode === 200) {
          const oldRows = chats.map((item) => {
            if (item.id === props.location.state.selectedId) {
              return {
                id: selectedId,
                title: finalValues.title,
                startTime: finalValues.startTime,
                endTime: finalValues.endTime,
                description: description,
                ownerId: {
                  _id: authUser.unique_id,
                  name: authUser.name,
                },
              };
            } else {
              return item;
            }
          });
          setChats(oldRows);
          toastr.success(resp.message);
          props.history.push("/chatTabs");
        } else {
          toastr.error(resp.message);
        }
        setIsWaitingForResponse(false);
      })
      .catch((error) => {
        setIsWaitingForResponse(false);
        toastr.error(error);
      });
  };

  const handleNext = async () => {
    const extraErrors = {};
    if (dateConflictError) extraErrors.dateConflictError = dateConflictError;
    if (name === "") {
      extraErrors.name = "Please enter name";
    }
    if (description === "") {
      extraErrors.description = "Please enter description";
    }
    if (fromDate === null) {
      extraErrors.fDate = "Select From Date";
    }
    if (endDate === null) {
      extraErrors.eDate = "Select End Date";
    }
    if (
      endDate &&
      (endDate.getTime() - fromDate.getTime())< 300000
    ) {
      extraErrors.fDate =
        "Atleast a 5 min difference between start time and end time";
    }
    if (endDate && endDate.getTime() < fromDate && fromDate.getTime()) {
      extraErrors.fDate = "Selected start time is not less than end time";
    }
    setExtraErrors(extraErrors);
    if (extraErrors && Object.keys(extraErrors).length !== 0) return;
    const finalValues = {};
    finalValues.description = description;
    finalValues.startTime = fromDate.getTime();
    finalValues.endTime = endDate.getTime();

    finalValues.title = name;

    setIsWaitingForResponse(true);
    if (
      props &&
      props.location &&
      props.location.state &&
      props.location.state.selectedId
    ) {
      finalValues.selectedId = props.location.state.selectedId;
      updateChatRoomData(finalValues);
    } else {
      saveChatRoomData(finalValues);
    }
  };
  const classes = useStyles();

  return (
    <div style={{ position: "relative", zIndex: "1" }}>
      <Box
        component="div"
        p={1}
        mt={-3}
        mb={2}
        zIndex="2"
        position="sticky"
        top="86px"
        className="drop-box"
      >
        <Container maxWidth="lg">
          <Box className={classes.livestreamings}>
            <Grid container>
              <Grid item xs={12} sm={12} md={9}>
                <Box display="flex" alignItems="center" gridGap={10}>
                  <Button
                    onClick={handleCloseSideMenu}
                    style={{
                      color: "rgb(243,90,87)",
                      border: "2px solid  rgb(243,90,87)",
                      borderRadius: "100%",
                      minWidth: "50px",
                      height: "50px",
                    }}
                  >
                    {!closeSideMenu && <MenuIcon />}
                    {closeSideMenu && <ArrowBackIcon />}
                  </Button>
                  <h2>Create Audio Rooms</h2>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Box>
      <Container>
        <Box component="div" display="flex" overflow="hidden">
          {closeSideMenu && (
            <Box marginRight="10px">
              <Sidemenu />
            </Box>
          )}
          <Box className="drop-box schedule-streaming" p={2} width="100%">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  className={
                    styles.plasma_input +
                    " " +
                    `input-field request-amount ${
                      extraErrors && extraErrors.name 
                        ? styles.bg_red
                        : name
                        ? styles.bg_green
                        : styles.bg_normal
                    }`
                  }
                  label="Title"
                  placeholder="Give a title"
                  name="name"
                  value={name}
                  inputProps={{
                    maxLength: 50,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {name ? name.length : 0}/50
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleName}
                />
                {extraErrors && extraErrors.name && (
                   <div className={styles.custom_error}>{extraErrors.name}</div>
                  
                )}
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  variant="outlined"
                  className={
                    styles.plasma_input +
                    " " +
                    `input-field request-amount ${
                      extraErrors && extraErrors.description 
                        ? styles.bg_red
                        : description
                        ? styles.bg_green
                        : styles.bg_normal
                    }`
                  }
                  label="Description"
                  placeholder="Give a description"
                  name="description"
                  value={description}
                  inputProps={{
                    maxLength: 100,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        {description ? description.length : 0}/100
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleDescription}
                />
                {extraErrors && extraErrors.description && (
                  <div className={styles.custom_error}>{extraErrors.description}</div>
                )}
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  fullWidth
                  error={
                    (extraErrors &&
                      extraErrors.fDate &&
                      extraErrors.fDate.length > 0) ||
                    dateConflictError.length > 0
                  }
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      okLabel={<Button onClick={handleChangeDateOK}>OK</Button>}
                      cancelLabel={
                        <Button onClick={handleChangeFromDateCancel}>
                          CANCEL
                        </Button>
                      }
                      // style={{ width: "231px" }}
                      variant="dialog"
                      value={fromDate}
                      inputVariant="outlined"
                      label="Select Start Date"
                      format={DATE_TIME_FORMAT_FULL_PICKER}
                      placeholder={DATE_TIME_FORMAT_FULL_PICKER}
                      onChange={(newDate) => {
                        handleChangeFromDate(newDate);
                      }}
                      disablePast
                      className={
                        styles.plasma_input +
                        " " +
                        styles.date_b +
                        " " +
                        `input-field ${
                          extraErrors && extraErrors.fDate
                            ? styles.bg_red
                            : fromDate
                            ? styles.bg_green
                            : styles.bg_normal
                        }`
                      }
                    />
                  </MuiPickersUtilsProvider>
                  {extraErrors && extraErrors.fDate && (
                    <div className={styles.custom_error}>{extraErrors.fDate}</div>
                  )}
                  {dateConflictError && (
                    <div className={styles.custom_error}>{dateConflictError}</div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  fullWidth
                  error={
                    extraErrors &&
                    extraErrors.eDate &&
                    extraErrors.eDate.length > 0
                  }
                >
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      okLabel={<Button onClick={handleChangeDateOK}>OK</Button>}
                      cancelLabel={
                        <Button onClick={handleChangeEndDateCancel}>
                          CANCEL
                        </Button>
                      }
                      // style={{ width: "231px" }}
                      variant="dialog"
                      value={endDate}
                      inputVariant="outlined"
                      label="Select End Date"
                      format={DATE_TIME_FORMAT_FULL_PICKER}
                      placeholder={DATE_TIME_FORMAT_FULL_PICKER}
                      onChange={(newDate) => {
                        handleChangeEndDate(newDate);
                      }}
                      disablePast
                      className={
                        styles.plasma_input +
                        " " +
                        styles.date_b +
                        " " +
                        `input-field ${
                          extraErrors && extraErrors.eDate
                            ? styles.bg_red
                            : endDate
                            ? styles.bg_green
                            : styles.bg_normal
                        }`
                      }
                    />
                  </MuiPickersUtilsProvider>
                  {extraErrors && extraErrors.eDate && (
                    <div className={styles.custom_error}>{extraErrors.eDate}</div>
                  )}
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}></Grid>
            </Grid>
            <Box>
              <Button variant="contained" onClick={handleBack}>
                Back
              </Button>
              <span style={{ cursor: selectedId ? "not-allowed" : "default" }}>
                <Button
                  style={{
                    backgroundColor: "var(--tal_primary)",
                    marginLeft: "5px",
                    color: "white",
                  }}
                  //  disabled={selectedId}
                  variant="contained"
                  onClick={handleNext}
                >
                  {selectedId ? "Update" : "Schedule"}
                </Button>
              </span>
            </Box>
          </Box>
          <Loader
            isOpen={isWaitingForResponse}
            onClose={() => setIsWaitingForResponse(false)}
          />
        </Box>
      </Container>
    </div>
  );
}
export default withRouter(ChatRoomForm);
