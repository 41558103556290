import React, { useContext, useEffect } from "react";
import { Button, MenuItem, Select } from "@material-ui/core";
import { appContext } from "../../App";
import { checkDefaultChannel } from "../../utils/utils";

function ChannelSelectionDDBox(props) {
  const { alignDown } = props;
  const {
    setPlaylists,
    channel,
    setChannel,
    channels,
    setTalmediaLanguage,
    currentChannel,
    talmediaLanguage,
  } = useContext(appContext);

  function handleChangeChannel(newChannel) {
    const currentChannel = newChannel || null;
    if (props.onChannelChange) {
      props.onChannelChange(channel, currentChannel);
    }
    setTalmediaLanguage(checkDefaultChannel(currentChannel));
    setChannel(currentChannel);
    if (props.setOffset) props.setOffset(0);
    if (props.setRows) props.setRows([]);
    if (props.setPlaylists) setPlaylists([]);
  }
  console.log("suman", alignDown);

  return (
    <>
      <div
        className={alignDown ? "tal_radio_gl tal_radio_al " : "tal_radio_gl"}
      >
        <p style={{ marginBottom: "0" }}>TALRadio</p>
        <div>
          <div style={{ color: "#777" }}>Select channel</div>
          <div>
            <Button
              key={"talradio"}
              onClick={() => handleChangeChannel("talradio")}
              className={talmediaLanguage === "english" ? "selected_bg" : ""}
            >
              English
            </Button>
            <Button
              key={"hindi"}
              onClick={() => handleChangeChannel("hindi")}
              className={talmediaLanguage === "hindi" ? "selected_bg" : ""}
            >
              Hindi
            </Button>
            <Button
              key={"telugu"}
              onClick={() => handleChangeChannel("telugu")}
              className={talmediaLanguage === "telugu" ? "selected_bg" : ""}
            >
              Telugu
            </Button>
          </div>
        </div>
      </div>
    </>
  );
}
export default ChannelSelectionDDBox;
