import React, { useEffect, useState, useContext, useRef } from "react";
import toastr from "toastr";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import TextField from "@material-ui/core/TextField";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import { appContext } from "../../App";
import useFormValidation from "../../hooks/useFormValidation";
import "../Organization/Organization.scss";
import CloseIcon from "@material-ui/icons/Close";
import validateContact from "./validateContact";
import Input from "@material-ui/core/Input";
import Checkbox from "@material-ui/core/Checkbox";
import ListItemText from "@material-ui/core/ListItemText";
import cssstyle from "./Contact.module.scss";
import {
  addContact,
  getMyContactGroups,
  updateContact,
  assignContactToGroup,
  getGroupsByContactId,
  unAssignGroupToContact,
} from "../../utils/api";
import { Box, Typography } from "@material-ui/core";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import PhoneNumber from "../Phone/PhoneNumber";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    // sdisplay: "flex",
    backgroundColor: theme.palette.background.paper,
    // border: "2px solid #000",
    overflowX: "auto",
    heigth: "auto",
    maxHeight: "80vh",
    boxShadow: theme.shadows[5],
    paddingBottom: "20px",
    borderRadius: "4px",

    "&:focus": {
      border: "none",
      outline: "none",
    },
  },
  plasma: {
    width: "100%",
    paddingTop: 20,
    display: "inline-block",
    padding: "25px",
  },

  title: {
    color: "var(--tal_primary)",
    fontSize: "18px",
    fontWeight: "500",
    textTransform: "uppercase",
    textAlign: "start",
  },
  formControl: {
    margin: theme.spacing(1),
    width: "100%",
  },
  saveBtn: {
    background: "var(--tal_primary) ",
    color: "#fff",
    "&:hover": {
      background: "#F44234",
    },
    marginTop: "40px",
  },
  deleteBtn: {
    marginTop: "40px",
    float: "right",
  },
  assignContact: {
    width: "100%",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
const ContactModal = (props) => {
  const {
    authUser,
    contacts,
    setContacts,
    contactGroups,
    setContactGroups,
    userLocaleInfo,
  } = useContext(appContext);
  const classes = useStyles();

  const initialState = {
    firstName: "",
    email: "",
  };

  const [phone, setPhone] = useState("");
  const [phoneVerified, setPhoneVerified] = useState(false);
  const phoneRef = useRef(phone);

  const [lastName, setLastName] = useState("");
  const [selectedContactGroups, setSelectedContactGroups] = useState([]);
  const [prevContactGroups, setPrevContactGroups] = useState([]);
  const [extraErrors, setExtraErrors] = useState({});

  const getContactFormData = () => {
    return {
      lastName,
      firstName: values.firstName,
      email: values.email,
      phoneNumber: phone,
      userId: authUser.unique_id,
    };
  };

  const handleAddContact = async () => {
    const errors = {};
    if (!phone) {
      errors.phone = "Please enter mobile number";
    }
    // if (phone && !isValidPhoneNumber(phone)) {
    //   errors.phone = "Please enter valid Mobile Number";
    // }
    setExtraErrors(errors);
    if (Object.keys(errors).length > 0) {
      return;
    } else {
      setExtraErrors({});
      try {
        const formData = getContactFormData();
        let data = null;
        let contactCopy = [...contacts];
        if (props.currentContact) {
          data = await updateContact(
            authUser.unique_id,
            props.currentContact._id,
            formData
          );
          const index = contactCopy.findIndex(
            (item) => item._id === props.currentContact._id
          );
          contactCopy[index] = data.data;
        } else {
          data = await addContact(authUser.unique_id, formData);
          contactCopy.push(data.data);
        }

        setContacts(contactCopy);

        if (selectedContactGroups.length || prevContactGroups.length) {
          let newGroups = [];
          let deleteGroups = [];
          if (prevContactGroups.length) {
            prevContactGroups.forEach((item) => {
              if (
                selectedContactGroups.findIndex(
                  (sl) => sl._id === item.groupId._id
                ) === -1
              ) {
                deleteGroups.push(item.groupId._id);
              }
            });
            newGroups = selectedContactGroups.filter(
              (item) =>
                prevContactGroups.findIndex(
                  (p) => p.groupId._id === item._id
                ) === -1
            );
          } else {
            newGroups = selectedContactGroups;
          }
          const newGroupPromise = newGroups.map((item) =>
            assignContactToGroup(authUser.unique_id, data.data._id, item._id)
          );
          const deletGroupPromise = deleteGroups.map((item) =>
            unAssignGroupToContact(authUser.unique_id, data.data._id, item)
          );

          if (
            (newGroupPromise && newGroupPromise.length) ||
            (deletGroupPromise && deletGroupPromise.length)
          ) {
            await Promise.all(newGroupPromise.concat(deletGroupPromise));
          }
        }
        const groups = await getMyContactGroups(authUser.unique_id);
        setContactGroups(groups);
        toastr.success(data.message);
        props.onContactSave();
      } catch (error) {
        if (error.response && error.response.data) {
          toastr.error(error.response.data.message);
        } else {
          toastr.error(error.message);
        }
      }
    }
  };
  const { values, setValues, errors, setErrors, changeHandler, submitHandler } =
    useFormValidation(initialState, validateContact, handleAddContact);

  useEffect(() => {
    if (props.currentContact && !props.formData) {
      setValues({
        firstName: props.currentContact.firstName,
        email: props.currentContact.email,
      });
      // setPhoneVerified(props.currentContact.phone_verified);
      setPhone(props.currentContact.phoneNumber);
      setLastName(props.currentContact.lastName);

      getGroupsByContactId(authUser.unique_id, props.currentContact._id)
        .then((data) => {
          if (data.length) {
            setPrevContactGroups(data);
            const groups = [];
            data.forEach((item) => {
              const group = contactGroups.find(
                (g) => g._id === item.groupId._id
              );
              groups.push(group);
            });
            setSelectedContactGroups(groups);
          }
        })
        .catch((error) => {
          console.log(error.message);
        });
    } else {
      setValues({
        firstName: "",
        email: "",
      });
      setPhone("");
      setLastName("");
      setSelectedContactGroups([]);
      setPrevContactGroups([]);
    }
  }, [props.openModal]);

  const handleCloseModal = () => {
    props.onModalClose();
    setErrors("");
  };

  return (
    <div className="org-container">
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={props.openModal}
        onClose={handleCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openModal}>
          <div className={classes.paper + " " + cssstyle.modal_pop}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                // borderBottom: "1px solid #ccc",
                position: "sticky",
                top: "0",
                background: "white",
                zIndex: "2",
                padding: "15px 20px 10px",
                boxShadow: "0 0 9px #e8eaf5",
              }}
            >
              <h1 className={classes.title}>
                {" "}
                {`${props.currentContact ? "Edit" : "Add"} `}Contact
              </h1>

              <span
                style={{
                  textAlign: "end",
                  cursor: "pointer",
                  color: "#000",
                  borderRadius: "50px",
                  padding: "4px",
                }}
                className="drop-box close-organizatin "
              >
                <CloseIcon onClick={handleCloseModal} />
              </span>
            </div>
            <div className={classes.plasma}>
              <form noValidate autoComplete="off">
                <TextField
                  type="text"
                  required
                  className={
                    cssstyle.plasma_input +
                    " " +
                    `input-field request-title ${
                      errors && errors.firstName
                        ? cssstyle.bg_red
                        : values.firstName
                        ? cssstyle.bg_green
                        : cssstyle.bg_normal
                    }`
                  }
                  variant="outlined"
                  label="First Name"
                  name="firstName"
                  value={values.firstName}
                  onChange={changeHandler}
                />
                {errors && errors.firstName && (
                  <div className={cssstyle.custom_error}>
                    {errors.firstName}
                  </div>
                )}

                <TextField
                  type="text"
                  className={
                    cssstyle.plasma_input +
                    " " +
                    cssstyle.bg_normal +
                    " " +
                    `input-field request-title`
                  }
                  variant="outlined"
                  label="Last Name"
                  name="lastName"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />

                <TextField
                  type="email"
                  className={
                    cssstyle.plasma_input +
                    " " +
                    `input-field request-title ${
                      errors && errors.email
                        ? cssstyle.bg_red
                        : values.email
                        ? cssstyle.bg_green
                        : cssstyle.bg_normal
                    }`
                  }
                  variant="outlined"
                  label="Email *"
                  name="email"
                  value={values.email}
                  onChange={changeHandler}
                />
                {errors && errors.email && (
                  <div className={cssstyle.custom_error}>{errors.email}</div>
                )}
                {/* <PhoneNumber
                  phone={phone}
                  isPhoneVerified={phoneVerified}
                  ref={phoneRef}
                />
                {extraErrors && extraErrors.phone && (
                  <Typography className={cssstyle.custom_error}>
                    {extraErrors.phone}
                  </Typography>
                )} */}
                <Box
                  component="div"
                  border="1px solid #ccc"
                  borderRadius="5px"
                  p={2}
                  className="phoneTxt"
                >
                  <PhoneInput
                    variant="outlined"
                    fullWidth
                    placeholder="Phone Number"
                    name="phoneNumber"
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry={
                      userLocaleInfo && userLocaleInfo.country_code
                    }
                    value={phone}
                    onChange={setPhone}
                    // onKeyPress={(e) => handleEnterClick(e)}
                  />
                </Box>
                {extraErrors && extraErrors.phone && (
                  <div className="custom-error">{extraErrors.phone}</div>
                )}
                {contactGroups && contactGroups.length > 1 && (
                  <FormControl
                    className={cssstyle.group_ass}
                    variant="outlined"
                  >
                    <InputLabel id="demo-mutiple-checkbox-label">
                      Assign to Groups
                    </InputLabel>
                    <Select
                      labelId="demo-mutiple-checkbox-label"
                      id="demo-mutiple-checkbox"
                      multiple
                      value={selectedContactGroups}
                      onChange={(e) => {
                        setSelectedContactGroups(e.target.value);
                      }}
                      input={<Input />}
                      renderValue={(selected) =>
                        selected.map((item) => item.name).join(", ")
                      }
                      MenuProps={MenuProps}
                    >
                      {contactGroups.map(
                        (group) =>
                          group._id.toLowerCase() !== "none" && (
                            <MenuItem key={group._id} value={group}>
                              <Checkbox
                                checked={
                                  selectedContactGroups.findIndex(
                                    (item) => item._id === group._id
                                  ) > -1
                                }
                              />
                              <ListItemText primary={group.name} />
                            </MenuItem>
                          )
                      )}
                    </Select>
                  </FormControl>
                )}
                {contactGroups && contactGroups.length === 1 && (
                  <p>No Groups</p>
                )}
              </form>
              <Button className={classes.saveBtn} onClick={submitHandler}>
                {props.currentContact ? "Update" : "Save"}
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default ContactModal;
