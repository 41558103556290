import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import "./Snackbar.scss";
import { appContext } from "../../App";
import Grid from "@material-ui/core/Grid";

const useStyles = makeStyles((theme) => ({
  formControl: {
    // margin: theme.spacing(1),
    // minWidth: 120,
  },
}));

const TopSnackbar = (props) => {
  const classes = useStyles();
  const { regions, region, setRegion } = useContext(appContext);

  const handleClose = (event, reason) => {
    localStorage.setItem("isRegionSelected", "true");
    props.setIsRegionSelected(true);
  };

  const regionChangeHandler = (event) => {
    setRegion(regions.find((region) => event.target.value === region._id));
  };

  return (
    <div className="snackbar-main-cntnr">
      <div className="snapfixed">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7} className="snap-left">
            <p style={{ color: "#fff", margin: "0", lineHeight: "40px", }}>
              Choose another country or region to see content specific to
              selected location
            </p>
          </Grid>

          <Grid item xs={12} sm={5} className="snap-right">
            <React.Fragment>
              {region && region._id && (
                <FormControl className={classes.formControl}>
                  <Select
                    className="select-region"
                    labelId="demo-simple-select-outlined-label"
                    id="demo-simple-select-outlined"
                    value={region && region._id}
                    onChange={regionChangeHandler}
                  >
                    {regions &&
                      regions.length > 0 &&
                      regions.map((region, index) => {
                        return (
                          // <Menu className="region-change">
                          <MenuItem value={region._id}>
                            <img
                              src={`/images/${region._id}.svg`}
                              width="25px"
                              alt={region.regionCodeIso3}
                              style={{ marginRight: 15 }}
                            />{" "}
                            <span style={{ marginLeft: 10 }}>
                              {region && region.regionCodeIso3}
                            </span>
                          </MenuItem>
                          // </Menu>
                        );
                      })}
                  </Select>
                </FormControl>
              )}
              <Button
                color="secondary"
                className="snack-btn"
                size="small"
                onClick={handleClose}
              >
                Continue
              </Button>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                style={{
                  maxHeight: "40px",
                  position: "absolute",
                  top: "15px",
                  margin: "10px",
                  background: "#494a4b",
                }}
                onClick={handleClose}
              >
                <CloseIcon className="snack-close" fontSize="small" />
              </IconButton>
            </React.Fragment>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default TopSnackbar;
