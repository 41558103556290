import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import Paypal from "../../paypal";
import Container from "@material-ui/core/Container";
import {
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import useFormValidation from "../../../../hooks/useFormValidation";
import { saveDonation, getRegions } from "../../../../utils/api";
import toastr from "toastr";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import LockIcon from "@material-ui/icons/Lock";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import "./CheckoutForm.scss";
import { appContext } from "../../../../App";
import api from "../api";
import {
  CURRENCY_USD,
  formatNumber,
  roundToTwoDecimalPlaces,
} from "../../../../utils/utils";
import { TextField, InputLabel } from "@material-ui/core";
import DonationRequestDetailsCard from "../../../donationRequest/DonationRequestDetailsCard";
import Box from "@material-ui/core/Box";
import BankDetails from "../../BankDetails";
import cssstyle from "./checkoutForm.module.scss";
const CheckoutForm = (props) => {
  const [paymentData, setPaymentData] = useState(null);
  const [amount, setAmount] = useState(0);
  const [settlementCurrency, setSettlementCurrency] = useState("");
  const [currency, setCurrency] = useState("");
  const [userCustomerId, setUserCustomerId] = useState("");
  const [error, setError] = useState(null);
  const [metadata, setMetadata] = useState(null);
  const [userId, setUserId] = useState("");
  const [donationRequestId, setDonationRequestId] = useState("");
  const [donationFor, setDonationFor] = useState("");
  const [toUserInfo, setToUserInfo] = useState("");
  const [externalUserInfo, setexternalUserInfo] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [isAnonymous, setAnonymous] = useState(false);
  const [remember, setRemember] = useState(true);
  const [countries, setCountries] = useState([]);
  const stripe = useStripe();
  const elements = useElements();
  const [selectedPayment, setSelectedPayment] = useState("");

  const initialState = {
    fullName: "",
    firstName: "",
    lastName: "",
    RoutingNumber: "",
    AccountNumber: "",
    AccountHolderType: "individual",
    country: "",
    bankName: "",
    Amount1: "",
    Amount2: "",
  };

  const { authUser, donationDetails, setDonationDetails } =
    useContext(appContext);

  const validateProfile = (values) => {
    if (donationDetails && donationDetails.userId) {
      let errors = {};
      if (!values.firstName) {
        errors.firstName = "Please enter First Name";
      }
      if (!values.lastName) {
        errors.lastName = "Please enter Last Name";
      }
      return errors;
    } else {
      return {};
    }
  };
  useEffect(() => {
    getRegions().then((result) => {
      setCountries(result.data.data);
    });
  }, []);

  const updateDonationDetails = (processingFeesPercentage, paymentMethod) => {
    processingFeesPercentage = Number(processingFeesPercentage);
    const donationDetailsTemp = {};

    if (donationDetails.shouldChargeProcessingFees) {
      donationDetailsTemp.donatedAmount = Number(donationDetails.amount);
      donationDetailsTemp.processingFees = roundToTwoDecimalPlaces(
        (processingFeesPercentage / 100.0) * donationDetailsTemp.donatedAmount
      );
      donationDetailsTemp.totalAmount =
        roundToTwoDecimalPlaces(donationDetailsTemp.donatedAmount) +
        roundToTwoDecimalPlaces(donationDetailsTemp.processingFees);
    } else {
      donationDetailsTemp.totalAmount = Number(donationDetails.amount);
      donationDetailsTemp.donatedAmount = roundToTwoDecimalPlaces(
        donationDetailsTemp.totalAmount / (1 + processingFeesPercentage / 100.0)
      );

      donationDetailsTemp.processingFees = roundToTwoDecimalPlaces(
        donationDetailsTemp.totalAmount - donationDetailsTemp.donatedAmount
      );
    }

    if (paymentMethod === "stripe") {
      donationDetailsTemp.paymentMode = "card";
    } else if (paymentMethod == "stripeach") {
      donationDetailsTemp.paymentMode = "ACH";
    }
    donationDetailsTemp.paymentMethod = paymentMethod;

    setDonationDetails((currentValues) => {
      return { ...currentValues, ...donationDetailsTemp };
    });
  };

  const handleStripePayment = () => {
    updateDonationDetails(
      donationDetails &&
        donationDetails.region &&
        donationDetails.region.processingFees,
      "stripe"
    );
    setSelectedPayment("stripe");
  };

  const handlePaypalPayment = () => {
    updateDonationDetails(
      donationDetails &&
        donationDetails.region &&
        donationDetails.region.processingFees,
      "paypal"
    );
    setSelectedPayment("paypal");
  };

  const handleStripeAchPayment = () => {
    updateDonationDetails(
      donationDetails &&
        donationDetails.region &&
        donationDetails.region.processingFeeACH,
      "stripeach"
    );
    setSelectedPayment("stripeach");
  };

  useEffect(() => {
    if (donationDetails) {
      setPaymentData(donationDetails);
      setAmount(donationDetails.totalAmount);
      setCurrency(donationDetails.currency);
      setSettlementCurrency(donationDetails.currency);
      setUserCustomerId(donationDetails.customer_id);
      setUserId(donationDetails.userId);
      setDonationFor(donationDetails.donationFor);
      if (donationDetails.donationFor === "donationRequest") {
        setDonationRequestId(donationDetails.donationRequestId);
      } else if (donationDetails.donationFor === "individual") {
        setToUserInfo(donationDetails.toUserInfo);
      }
      if (donationDetails.hasOwnProperty("isAnonymous")) {
        setAnonymous(donationDetails.isAnonymous);
      }
      if (donationDetails.hasOwnProperty("externalUserInfo")) {
        setexternalUserInfo(donationDetails.externalUserInfo);
      }
      if (donationDetails.userId === "") {
        setRemember(false);
      }
    }
  }, []);

  const handleError = (err) => {
    setError(false);
  };

  const { values, setValues, errors, changeHandler, submitHandler } =
    useFormValidation(initialState, validateProfile);
  const handleSubmit = async (ev) => {
    ev.preventDefault();
    setProcessing(true);

    var customer_id;
    function paymentComplete(clientSecret, cardData = null) {
      stripe.retrievePaymentIntent(clientSecret).then(function (result) {
        var paymentIntent = result.paymentIntent;
        paymentIntent.customer_id = customer_id;
        if (paymentIntent.last_payment_error != null) {
          var paymentIntentJson = JSON.stringify(paymentIntent, null, 2);
          setError(
            "Some problem while processing the card. Please check the card information again."
          );
          setSucceeded(false);
          setProcessing(false);
          setMetadata(paymentIntent);
          return console.log("[PaymentIntent]", paymentIntentJson);
        } else {
          paymentIntentJson = JSON.stringify(paymentIntent, null, 2);
          setError(null);
          setSucceeded(true);
          setProcessing(false);
          setMetadata(paymentIntent);
          var payload = {};
          payload.customerId = paymentIntent.customer_id;
          payload.clientSecret = paymentIntent.client_secret;
          payload.paymentMethod = "stripe";
          payload.paymentMode = donationDetails.paymentMode;
          payload.units = paymentIntent.currency;
          payload.user_id = userId;
          payload.donationFor = donationFor;
          if (donationFor === "donationRequest") {
            payload.donation_request_id = donationRequestId;
          } else if (donationFor === "individual") {
            payload.toUserInfo = toUserInfo;
          }
          payload.externalUserInfo = externalUserInfo;
          payload.quantity = donationDetails.donatedAmount;
          payload.transactionId = paymentIntent.id;
          payload.type = "cash";
          payload.anonymous = isAnonymous;
          payload.cardInfo = cardData.paymentMethod.card;
          payload.billingAddress = paymentData.billingAddress;
          payload.processingFees = roundToTwoDecimalPlaces(
            donationDetails.processingFees
          );
          saveDonation(payload)
            .then((response) => {
              if (customer_id !== "") {
                localStorage.setItem("stripeCustomerId", customer_id);
              }
              response.data.currency = payload.units;
              var thankyouResponse = response.data;
              props.history.push("/thankyou", { thankyouResponse });
            })
            .catch((error) => {
              toastr.error(error.message);
            });
        }
      });
    }
    var cardholderName = ev.target.name.value;
    var data = {
      billing_details: {},
    };

    if (cardholderName) {
      data["billing_details"]["name"] = cardholderName;
      data.billing_details.address = {
        line1: paymentData.billingAddress.line1,
        postal_code: paymentData.billingAddress.zip_code,
        city: paymentData.billingAddress.city,
        state: paymentData.billingAddress.state,
        //country: paymentData.billingAddress.country,
      };
    }

    stripe
      .createPaymentMethod(
        "card",
        elements.getElement(
          CardNumberElement,
          CardExpiryElement,
          CardCvcElement
        ),
        data
      )
      .then(function (card_response) {
        if (card_response.error) {
          setProcessing(false);
          setError(card_response.error.message);
          return null;
        }

        //process if no errors
        api
          .createPaymentIntent({
            payment_method_types: ["card"],
            paymentMethodId: card_response.paymentMethod.id,
            amount: Math.ceil(
              Number(donationDetails.totalAmount).toFixed(2) * 100
            ),
            currency: currency,
            settlementCurrency: settlementCurrency,
            customer_id: userCustomerId === "null" ? "" : userCustomerId,
            saveCard: false,
          })
          .then(function (paymentData) {
            if (paymentData.requiresAction) {
              customer_id = paymentData.customer_id;
              stripe
                .handleCardAction(paymentData.clientSecret)
                .then(function (actionData) {
                  if (actionData.error) {
                    setProcessing(false);
                    setError(
                      "Your card was not authenticated, please try again"
                    );
                  } else if (
                    actionData.paymentIntent.status === "requires_confirmation"
                  ) {
                    // Card was properly authenticated, we can attempt to confirm the payment again with the same PaymentIntent and adding currency
                    api
                      .createPaymentIntent({
                        paymentIntentId: actionData.paymentIntent.id,
                        currency: currency,
                        settlementCurrency: settlementCurrency,
                      })
                      .then(function (paymentSuccess) {
                        if (paymentSuccess.error) {
                          setProcessing(false);
                          setError(paymentSuccess.error);
                        } else {
                          paymentComplete(
                            paymentData.clientSecret,
                            card_response
                          );
                        }
                      });
                  }
                });
            } else if (paymentData.error) {
              setProcessing(false);
              setError("Your card was not authenticated, please try again");
            } else if (paymentData.statusCode === 400) {
              setProcessing(false);
              setError(paymentData.raw.message);
            } else {
              customer_id = paymentData.customer_id;
              paymentComplete(paymentData.clientSecret, card_response);
            }
          })
          .catch((err) => {
            setProcessing(false);
            setError(
              "Some problem while processing the card. Please check the card information again."
            );
          });
      })
      .catch((err) => {
        setProcessing(false);
        setError(
          "Some problem while processing the card. Please check the card information again."
        );
      });
  };

  const renderSuccess = () => {
    return <div className="sr-field-success message"></div>;
  };

  const renderForm = () => {
    const options = {
      style: {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a",
        },
      },
    };
    let gridStyle = {};
    if (userCustomerId === "null") {
      gridStyle = {
        margin: "auto",
      };
    }

    return (
      <Container maxWidth="lg">
        <div
          className={cssstyle.warning_bill}
          // style={{ display: "flex", justifyContent: "center" }}
        >
          <div className={cssstyle.icon_bill}>
            {" "}
            <ErrorOutlineIcon />
          </div>
          <p className={cssstyle.text_bill}>
            Please do not refresh the page or click the "Back"or "Close" button
            of your browser
          </p>{" "}
        </div>
        <Grid container spacing={2}>
          <Grid item md={8} sm={12} xs={12}>
            <div className="donation-payment-container drop-box">
              <h4>Payment Method</h4>

              <div className="payment-btns">
                <p>Choose your best payment method</p>
                <div className="stripe-style">
                  {paymentData && (
                    <Button
                      className="stripe-btn"
                      variant="contained"
                      color="#fff"
                      onClick={handleStripePayment}
                    >
                      <img src="/images/stripe.png" alt="stripe" />
                      {selectedPayment === "stripe" && (
                        <CheckCircleIcon className="check-icon" />
                      )}
                    </Button>
                  )}
                  {donationDetails &&
                    donationDetails.currency === CURRENCY_USD && (
                      <Button
                        className="stripe-btn"
                        variant="contained"
                        color="#fff"
                        onClick={handlePaypalPayment}
                      >
                        <img
                          src="/images/paypal.png"
                          height="50px"
                          alt="paypal"
                        />
                        {selectedPayment === "paypal" && (
                          <CheckCircleIcon className="check-icon" />
                        )}
                      </Button>
                    )}
                  {((authUser && authUser.unique_id) ||
                    (donationDetails && donationDetails.userId)) &&
                    donationDetails &&
                    donationDetails.currency === CURRENCY_USD &&
                    donationDetails.donationFor !== "tal" && (
                      <Button
                        className="stripe-btn"
                        variant="contained"
                        color="#fff"
                        onClick={handleStripeAchPayment}
                      >
                        <img
                          src="/images/stripe-ach.png"
                          height="50px"
                          alt="stripe-ach"
                        />
                        {selectedPayment === "stripeach" && (
                          <CheckCircleIcon className="check-icon" />
                        )}
                      </Button>
                    )}
                </div>
              </div>

              <div className="container-center ">
                {paymentData && selectedPayment === "stripe" ? (
                  <div style={gridStyle}>
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <InputLabel>
                            Donating Amount
                            <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            placeholder="Donating Amount"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="DonatingAmount"
                            options={options}
                            value={
                              donationDetails && donationDetails.donatedAmount
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel>
                            Transaction Charges
                            <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            placeholder="Transaction Charges"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="TransactionCharges"
                            options={options}
                            value={
                              donationDetails && donationDetails.processingFees
                            }
                            disabled
                          />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <div className="card-grid">
                            <CardNumberElement
                              className="sr-input sr-card-element"
                              options={options}
                              onChange={handleError}
                            />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="card-grid">
                            <CardExpiryElement
                              className="sr-input sr-card-element"
                              options={options}
                              onChange={handleError}
                            />
                          </div>
                        </Grid>

                        <Grid item xs={12} sm={6}>
                          <div className="card-cvv card-grid">
                            <CardCvcElement
                              className="sr-input sr-card-element "
                              options={options}
                              onChange={handleError}
                            />
                            <img src="/images/cvc.png" alt="card-cvv" />
                          </div>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <div className="card-grid last-name">
                            <input
                              type="text"
                              id="name"
                              name="name"
                              placeholder="Name on card"
                              autoComplete="cardholder"
                              className="sr-input sr-card-element StripeElement StripeElement--empty"
                              style={{ "z-index": "1" }}
                            />
                          </div>
                        </Grid>

                        {error && (
                          <div className="message sr-field-error">{error}</div>
                        )}

                        <Grid item xs={12}>
                          <button
                            className="pay-mian"
                            variant="contained"
                            color="#fff"
                            disabled={processing || !stripe}
                          >
                            <LockIcon style={{ marginRight: 6 }} />{" "}
                            {processing
                              ? "Processing... " +
                                currency +
                                " " +
                                formatNumber(donationDetails.totalAmount)
                              : "Pay " +
                                currency +
                                " " +
                                formatNumber(donationDetails.totalAmount)}
                          </button>
                        </Grid>
                        <Grid item xs={12}>
                          <div className="card-adv">
                            <img src="/images/amex.png" alt="amex" />
                            <img src="/images/vvisa.png" alt="visa" />
                            <img
                              src="/images/mastercard.png"
                              alt="mastercard"
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </form>
                  </div>
                ) : selectedPayment === "paypal" ? (
                  <div style={{ margin: 33, width: "37%" }}>
                    <Paypal donationDetails={paymentData} />
                  </div>
                ) : (
                  paymentData &&
                  selectedPayment === "stripeach" && (
                    <div style={gridStyle}>
                      <Grid container spacing={3}>
                        <Grid item xs={12} sm={6}>
                          <InputLabel>
                            Donating Amount
                            <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            placeholder="Donating Amount"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="DonatingAmount"
                            options={options}
                            value={
                              donationDetails && donationDetails.donatedAmount
                            }
                            disabled
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <InputLabel>
                            Transaction Charges
                            <span style={{ color: "red" }}>*</span>
                          </InputLabel>
                          <TextField
                            type="text"
                            placeholder="Transaction Charges"
                            variant="outlined"
                            style={{ width: "100%" }}
                            name="TransactionCharges"
                            options={options}
                            value={
                              donationDetails && donationDetails.processingFees
                            }
                            disabled
                          />
                        </Grid>
                      </Grid>
                      <BankDetails userId={userId} />
                    </div>
                  )
                )}
                {!selectedPayment && (
                  <div style={gridStyle}>
                    <br />
                    <br />
                    <br />
                    <h6>Please select a payment method</h6>
                    <br />
                    <br />
                  </div>
                )}
              </div>
            </div>
          </Grid>
          <Grid item sm={12} md={4}>
            <Box className="drop-box" style={{ background: "unset" }}>
              <DonationRequestDetailsCard selectedPayment={selectedPayment} />
            </Box>
          </Grid>
        </Grid>
      </Container>
    );
  };

  return (
    <div className="checkout-form">
      <div>
        <div className="sr-form-row" />
        {succeeded ? renderSuccess() : renderForm()}
      </div>
    </div>
  );
};

export default withRouter(CheckoutForm);
