import React from "react";
import Hidden from '@material-ui/core/Hidden';
import { Grid, Box,Button, Container,TextField} from "@material-ui/core";
import csstyle from "./Nonprofits.module.scss";
import { ArrowForward, PlayCircleOutlineRounded,SearchRounded} from "@material-ui/icons";
import NewTopbar from "../../Topbar/NewTopbar";
const Nonprofits = (props) => {
    return (
    <>
    
    <NewTopbar/>
     <Grid style={{background:"#fff"}}>
        
     <Grid className={csstyle.solutions_ui}>
    
    <Container>

   <Grid container>
  <Grid item sm={6} xs={12} className={csstyle.banner_head}>
  <h1>Raise More, Impact More. For your nonprofit Nonprofits</h1>
   <p>Simplifies nonprofit crowdfunding campaigns for organizations of all sizes.</p>
  <Grid className="position-relative" style={{marginBottom:"60px"}}>
   <Button className={csstyle.create_campaign}>Create Campaign <ArrowForward/> </Button>
   <Button className={csstyle.watch_video}> <PlayCircleOutlineRounded/> Watch Video </Button>
    
    </Grid>
    
</Grid>
<Grid item sm={6} xs={12} className={csstyle.hero_banner}>
<img src="./images/hand.svg" />
    </Grid>

</Grid>

</Container>
</Grid>
<Grid className={csstyle.empower_box}>

<container>

    <Grid container className="text-center">

  <Grid xs={12} className={csstyle.empower_title}>
          <h3>Search for nonprofits</h3>
          <p>Discover the Advantages of Connecting with Impactful Charitable Causes on <br/>Our Trusted Platform</p>
        </Grid>
      <Grid xs={12}>
            <Box className={csstyle.letter_sub}>
            <TextField variant="outlined" className={csstyle.letter_sub_input} placeholder="Search for Nonprofits" />  
            <SearchRounded />
            </Box>
            </Grid>
  <Grid xs={12} className={csstyle.join_100 +" "+ "mt-5"}> 
   Join over 100+ nonprofits/charities already using TALGiving
   </Grid>
   <Grid  xs={12} className={csstyle.logo_line +" " + "mt-3"}>
   <div>
   <img src="./images/c_logo.svg" alt=""/>
   </div>
   <div>
   <img src="./images/c_logo.svg" alt=""/>
   </div>
   <div>
   <img src="./images/c_logo.svg" alt=""/>
   </div>
   <div>
   <img src="./images/c_logo.svg" alt=""/>
   </div>
</Grid>
<Grid xs={12} className={csstyle.add_button  +" " + "mt-3"}>
<Button className={csstyle.add_org}>Add your organization </Button>
</Grid>

</Grid>

</container>
</Grid>
<div className={csstyle.slide_1}>
<Container>
<Grid container className="align-items-center">
<Grid item xs={12}>
<Box className={csstyle.categories_slide +" " + "mt-6"}>
<h2>Categories interest you</h2>

</Box>
<Box className={csstyle.project_slide}>
    <p>Discover projects just for you and get great recommendations <br/>when you select your interests.</p>
</Box>
</Grid>
<Grid  xs ={12}className={csstyle.img_row}>
<Box className={csstyle.card_line}>
<img src="images/blood-donation.svg"/>
    <p>Blood<br/> Donation</p>
</Box>
<Box className={csstyle.card_line}>

<img src="/images/scholarship-new.svg"/>
    <p>Scholarship </p>
</Box><Box className={csstyle.card_line}>
<img src="images/internship-icon.svg" />
    <p>Internship </p>
</Box>
<Box className={csstyle.card_line}>
<img src="images/voluteering-icon.svg" />
    <p>Voluteering </p>
</Box>
<Box className={csstyle.card_line}>
<img src="/images/career-help-icon.svg"/>
    <p>Career-help</p>
</Box>
<Box className={csstyle.card_line}>
<img src="images/fundraiser_icon.svg" />
    <p>Fundraiser</p>
</Box>
<Box className={csstyle.card_line}>
<img src="images/Board-member.svg" />
    <p>Board <br/>Member</p>
</Box>

</Grid>
<Grid xs={12} className={csstyle.create_btn  +" " + "mt-3"}>
<Button className={csstyle.custom_create}>Create Campaign </Button>
</Grid>

</Grid>

</Container>
    </div>
    <container>  
    <Grid container  className={csstyle.profit_work} >
     <Grid item xs={5} className={csstyle.work_line + " "+ "mt-6"}>
        <div>
        <h3>How it works</h3>
        <p>Discover projects just for you and get great<br/> recommendations when you select your <br/>interests.</p>
        </div>

       </Grid>
       <Grid item xs={2}>


       </Grid>
       <Grid item xs={5}>
        <div>
        <img src=""></img>
        <h2>Add you nonprofit</h2>
        <p>Discover projects just for you and get great <br/>recommendations when you select your<br/> interests.</p>
        </div>


       </Grid>

        </Grid>

    </container>





</Grid>


    
    </>
    );
};
export default Nonprofits;