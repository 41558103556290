import React from "react";
import { withRouter } from "react-router-dom";
import { Button, Grid, IconButton, TextField, Typography } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tab from '@material-ui/core/Tab';
import TabContext from '@material-ui/lab/TabContext';
import TabList from '@material-ui/lab/TabList';
import TabPanel from '@material-ui/lab/TabPanel';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import csstyle from "./NewLogin.module.scss";
import Hidden from '@material-ui/core/Hidden';
import "./NewLogin.scss";
import { VisibilityOffOutlined } from "@material-ui/icons";
const NewLogIn = (props) => {
  const [value, setValue] = React.useState('1');
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
 return (
    <>
    
        <Grid container style={{backgroundColor:"#fff"}}>
        <Hidden smDown>
            <Grid item md={4} sm={12} xs={12}  >
                <Grid className={value=="2" ? csstyle.login_signup :  csstyle.login_left }>
                    <Grid>
                    <Grid className={csstyle.login_logo}
                    onClick={() => {
                      props.history.push("/NewHome");
                    }}
                    >
                    <img src="./images/talgiving-logo-black.svg" alt=""/>
                    </Grid>
                    <Grid className={csstyle.login_head}>
                        <h3>
                            Start your Kind<br/>
                            journey with us
                        </h3>
                        <p>We're here to guide you every setp <br/> of the way.</p>
                    </Grid>
                    
                    </Grid>
                    <Grid className={csstyle.login_footer}>
                        <img src="./images/get_started_2.svg" alt=""/>
                    </Grid>
                </Grid>
            </Grid>
            </Hidden>
            <Grid item md={8} sm={12} xs={12} style={{background:"#fff"}}>
                <Grid >
            <Grid className={csstyle.login_right }>
            <Hidden mdUp>
            <Grid className={csstyle.login_smlogo}
                    onClick={() => {
                      props.history.push("/NewHome");
                    }}
                    >
                    <img src="./images/talgiving-logo-black.svg" alt=""/>
                    </Grid>
                    </Hidden>
      <TabContext value={value}>
        <Grid className="log_sign_tab">
        <AppBar position="static">
          <TabList onChange={handleChange} aria-label="simple tabs example">
            <Tab label="Log in" value="1" />
            <Tab label="Sign up" value="2" />
            
          </TabList>
        </AppBar>
        </Grid>
        <TabPanel value="1" className={csstyle.login_tab}>
            
                <Grid className={csstyle.log_text+" " + "mt-5 mb-4"}>
                    <h4><span>Login </span> to TALGiving</h4>
                </Grid>
                <Button className={csstyle.log_g + " " + "w-100"}>
                    <img src="./images/google_icon.svg"/>
                    <span>Continue with Google</span>
                </Button>
                <Grid className={csstyle.log_stxt}>
                    <p>or use your email account:</p>
                </Grid>
                <Grid className={csstyle.log_form}>
                  <TextField placeholder="Enter email address" variant="outlined" className="w-100 mb-3"/>
                  <TextField placeholder="Password" type="password" variant="outlined" className="w-100"/>
                  <Grid className="d-flex justify-content-between align-items-center  mt-3">
                  <FormControlLabel
                      value="Rememberme"
                      control={<Checkbox/>}
                      label="Remember me"
                      labelPlacement="Remember me"
                      className={csstyle.log_rer + " " + "check_checked"}
                    />
                    <span className={csstyle.log_ft + " " + "light_text"}>Forgot Password?</span>
                  </Grid>
                  <Button className={csstyle.login_btn + " " + "red_btn white_text w-100 font-size-m"}>Login</Button>
                </Grid>
           
        </TabPanel>
        <TabPanel value="2" className={csstyle.login_tab}>
        <Grid className={csstyle.log_text+" " + "mt-5 mb-4"}>
                    <h4><span>Sign up </span> to TALGiving</h4>
                </Grid>
                <Button className={csstyle.log_g + " " + "w-100"}>
                    <img src="./images/google_icon.svg"/>
                    <span>Continue with Google</span>
                </Button>
                <Grid className={csstyle.log_stxt}>
                    <p>or use your email account:</p>
                </Grid>
                <Grid className={csstyle.log_sign_up}>
                  <Grid className={csstyle.log_names + " " + "d-flex"}>
                  <TextField placeholder="First name" variant="outlined"/>
                  <TextField placeholder="Last name"  variant="outlined" />
                  </Grid>
                  <Grid>
                  <TextField placeholder="Email address" variant="outlined" className="w-100"/>
                  </Grid>
                  <Grid >
                  <TextField placeholder="Password" type="password" variant="outlined" className="w-100"/>
                  <IconButton className={csstyle.visible_pass}>
                    <VisibilityOffOutlined/>
                  </IconButton>
                  </Grid>
                  <Grid className="check_box">
                  <Checkbox id="check1"/>
                  <label for="check1">Send me a weekly mix of handpicked campaigns, plus occasional TALGiving news</label>
                  </Grid>
                  <Grid className="check_box">
                  <Checkbox id="check2"/>
                  <label for="check2">Contact me about participating in TALGiving research.
                  </label>
                  </Grid>
                  <p className="light_text pl-1 font-size-xs">By signing up, I agree that I have read and accepted the <a href="https://talgiving.org/users/Terms" target="_blank" className="light_text">Terms</a> .</p>
                  
                  
                  <Button className="red_btn white_text w-100 font-size-m mt-3 mb-5">Sign up</Button>
                </Grid>
        </TabPanel>
        
      </TabContext>
      </Grid>
      </Grid>
            </Grid>
        </Grid>
    
    </>   
  );
};
export default withRouter(NewLogIn);
